import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import api from '../../../api'
import { BudgetItem, BudgetItemBalance, BudgetLabel } from '../../../api/domain'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { NotificationService } from '../../../service/notificationService'
import { RootState } from '../../../store'

const NOTIFICATION_SOURCE = 'budget-balance-monthly-year-load'

export type BudgetItemMonthlyYearBalances = Map<number, Array<BudgetItemBalance>>

type BudgetMonthlyYearBalanceReturn = [BudgetItemMonthlyYearBalances, boolean, () => Promise<void>]

export const useBudgetMonthlyYearBalance = <E extends BudgetLabel, T extends BudgetItem<E>>(items: Array<T>, allowLoading: boolean): BudgetMonthlyYearBalanceReturn => {
  const [busy, setBusy] = useState<boolean>(false)
  const [result, setResult] = useState<BudgetItemMonthlyYearBalances>(new Map())
  const dateSelection = useSelector((state: RootState) => state.dateSelection)
  const profileState = useSelector((state: RootState) => state.activeProfile)
  const isMounted = useIsMounted()
  const itemIds = items.map(item => item.id)

  const load = async () => {
    NotificationService.clear(NOTIFICATION_SOURCE)
    setBusy(true)
    try {
      const result = itemIds.length === 0 ? [] : await api.budgetService.getMonthlyBalances(dateSelection.year, itemIds)
      const itemMap = new Map(result.map(res => [res.itemId, res.balances]))
      if (isMounted.current) {
        setResult(itemMap)
      }
    } catch (e) {
      NotificationService.raiseError(NOTIFICATION_SOURCE, 'Loading Budget Monthly-Year balance failed. Please try again later.')
    } finally {
      if (isMounted.current) {
        setBusy(false)
      }
    }
  }

  useEffect(() => {
    if (profileState.active && !profileState.isLoading && allowLoading) {
      load()
    }
  }, [profileState.active, profileState.isLoading, dateSelection, items, allowLoading])

  return [result, busy, load]
}
