import { Icon, Icons } from '@benjaminpetry/bepe-design'
import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import './Submenu.scss'

export interface SubMenuProps {
  children: ReactNode | Array<ReactNode>,
  onClick: () => void
}

export const SubMenu = ({ children, onClick } : SubMenuProps) => {
  const profileStore = useSelector((state: RootState) => state.activeProfile)

  return <div className='c-sub-menu'>
    {profileStore.active && <p className="c-sub-menu__profile">Profile: {profileStore.active.name}</p>}
    <button className="c-sub-menu__close-button" onClick={onClick}><Icon icon={Icons.Xmark}></Icon></button>
      {children}
    </div>
}
