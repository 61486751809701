import { ProfileService } from './profile/'
import { AccountService } from './account/'
import { BudgetService } from './budget'
import { TransferService } from './transfer'
import { BudgetPlanService } from './budgetplan'

export const apiUrl = process.env.REACT_APP_API_URL

export interface Api {
    status(): Promise<string>
    profileService: ProfileService
    accountService: AccountService
    budgetService: BudgetService
    transferService: TransferService
    budgetPlanService: BudgetPlanService
  // TODO add Services here
}
