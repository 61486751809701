import { HTMLProps } from 'react'
import { BudgetItemViewElement } from '../../BudgetDisplay'
import { ColorScheme, ProgressDisplay } from '../../../../components/display/ProgressDisplay'
import { BudgetTypeIcon } from '../../../../domain/budget/BudgetTypeIcon'
import './BudgetItemModule.scss'
import { CurrencyDisplay } from '../../../currency/CurrencyDisplay'
import { BudgetCategoryType } from '../../../../api/domain'

export interface BudgetItemModuleProps extends HTMLProps<HTMLDivElement> {
  item: BudgetItemViewElement,
  className?: string,
  selected: boolean
}

export const BudgetItemModule = ({ item, selected, className = '', ...props }: BudgetItemModuleProps) => {
  const balance = item.balance
  return (<div {...props} className={`c-budget-item-module interactive c-budget-item-module--${selected ? 'selected' : 'unselected'} ${className}`}>
    <div className='c-budget-item-module__core'>
      <BudgetTypeIcon icon={item.icon} type={item.type}></BudgetTypeIcon>
      <div className='c-budget-item-module__content'>
        <div>
          <h5 className='c-budget-item-module__name'>{item.name}</h5>
          <div className='c-budget-item-module__balance'>
            <CurrencyDisplay highlightNegative={true} highlightPositive={true} value={balance ? balance.actual : undefined}></CurrencyDisplay>
          </div>
        </div>
        <div className='c-budget-item-module__progress'>
          <ProgressDisplay progress={balance?.progress.actual} expectedProgress={balance?.progress.target} colorScheme={item.type === BudgetCategoryType.Income ? ColorScheme.INCOME : ColorScheme.EXPENSES}></ProgressDisplay>
          <CurrencyDisplay className="textNormal-75 c-budget-item-module__target-actual" highlightNegative={true} highlightPositive={true} value={balance ? balance.targetActualComparison : undefined}></CurrencyDisplay>
        </div>
      </div>
    </div>
  </div>)
}
