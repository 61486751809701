import { Outlet } from 'react-router-dom'
import './AppLayout.scss'
import { Menu } from './menu/Menu'

function AppLayout () {
  return (
    <div className="c-layout">
      <Outlet></Outlet>
      <Menu></Menu>
    </div>
  )
}

export default AppLayout
