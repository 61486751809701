import { Signal, Textfield } from '@benjaminpetry/bepe-design'
import { useEffect, useState, FocusEventHandler, ChangeEvent } from 'react'
import { CurrencyFormatter } from './CurrencyFormatter'
import './CurrencyTextfield.scss'

export interface CurrencyTextfieldProps {
  className?: string,
  label?: string,
  initialValue: number,
  error: string | undefined,
  disabled?: boolean,
  autofocus?: boolean,
  onChange: (value: number) => void,
  onErrorChange: (error: string | undefined) => void
}

const invalidRegex = /[^-0-9+*/,.]/gi
const numberRegex = /^[-+]?([0-9]+([.,][0-9]*)?)$/gi
const equationRegex = /^[-+]?([0-9]+([.,][0-9]*)?)([-+*/]([0-9]+([.,][0-9]*)?))*$/gi

export const CurrencyTextfield = ({ label, className, initialValue, error, disabled, autofocus, onChange, onErrorChange }: CurrencyTextfieldProps) => {
  const [value, setValue] = useState<string>(CurrencyFormatter.formatNumber(initialValue))

  useEffect(() => {
    const formatedValue = CurrencyFormatter.formatNumber(initialValue)
    if (formatedValue !== value) {
      setValue(formatedValue)
    }
  }, [initialValue])

  const onEvaluateInput = (input: string): number => {
    const isNumber = new RegExp(numberRegex)
    if (isNumber.exec(input)) {
      return CurrencyFormatter.parseString(input)
    }
    const isEquation = new RegExp(equationRegex)
    if (isEquation.exec(input)) {
      /* eslint-disable no-eval */
      const number = eval(input) as number
      return CurrencyFormatter.parseString(number + '')
    }
    throw Error(`'${input}' is not a number or equation.`)
  }

  const onUpdateValue = () => {
    try {
      const parsedValue = value === '' ? 0 : onEvaluateInput(value ?? '0')
      const formatedValue = CurrencyFormatter.formatNumber(parsedValue)
      setValue(formatedValue)
      onErrorChange(undefined)
      onChange(parsedValue)
    } catch (e) {
      onErrorChange('Not a number or equation.')
    }
  }

  const onValueBlur = () => {
    onUpdateValue()
  }

  const onFocus: FocusEventHandler<HTMLInputElement> = (evt) => {
    const parsedValue = CurrencyFormatter.parseString(value ?? '0')
    if (parsedValue === 0 && !disabled) {
      evt.target.select()
    }
  }

  const onValueChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.target.value.replaceAll(new RegExp(invalidRegex), '')
    onErrorChange(undefined)
    setValue(newValue)
  }

  return (<Textfield
    className={`c-currency-textfield ${className ?? ''}`}
    type='text'
    label={label}
    value={value}
    autoFocus={autofocus}
    disabled={disabled}
    enableEmptyButton={false}
    onContextMenu={(evt) => { evt.preventDefault(); evt.stopPropagation(); return false }}
    signal={error === undefined ? undefined : Signal.DANGER}
    hint={error}
    onChange={onValueChange}
    onBlur={onValueBlur}
    onFocus={onFocus}
    onEnter={onUpdateValue}
    ></Textfield>)
}
