import { Profile } from '../../domain'
import http, { createHeaders } from '../../http/'
import { apiUrl } from '../interface'
import Authorization from '../../auth'

const serviceUrl = `${apiUrl}/profiles`

const ProfileServiceInstance = {
  async get (profileId: number): Promise<Profile> {
    return http.get(`${serviceUrl}/${profileId}`, await createHeaders())
  },
  async login (profileKey: string): Promise<Profile> {
    const { profile, token } = await http.post<{profile: Profile, token: string}>(`${serviceUrl}/login`, await createHeaders(), { profileKey })
    Authorization.setAuthtoken(token)
    return profile
  },
  async logout (): Promise<void> {
    Authorization.setAuthtoken(null)
  }
}

export default ProfileServiceInstance
