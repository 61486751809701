
import './BudgetSelectionList.scss'
import { useSelector } from 'react-redux'
import { BudgetCategoryType, BudgetItemApi, TransferBudgetEntry, TransferType } from '../../../api/domain'
import { RootState } from '../../../store'
import { SelectionList } from '../../../components/input/selection/SelectionList'
import { BudgetSelectionListEntry } from './BudgetSelectionListEntry'
import { useAutoOpen } from '../../../hooks/useAutoOpen'

export interface BudgetSelectionListProps {
  className?: string,
  transferItems: Array<TransferBudgetEntry>,
  transferType: TransferType,
  transferDate: Date,
  disabled?: boolean,
  currentValue: number,
  currentErrors: Array<string | undefined>,
  onChange: (transferItems: Array<TransferBudgetEntry>) => void,
  onErrorChange: (errors: Array<string | undefined>) => void
}

const isValidBudgetItem = (transferType: TransferType, categoryType: BudgetCategoryType) => (transferType === TransferType.Transfer) === (categoryType === BudgetCategoryType.Transfer)
const getCompareFun = (type: BudgetCategoryType) => {
  return (a: BudgetItemApi, b: BudgetItemApi): number => {
    if (a.type !== b.type) {
      return a.type === type ? -1 : 1
    }
    return a.name.localeCompare(b.name)
  }
}
const transferTypeToBudgetCategoryType = (type: TransferType) => type as unknown as BudgetCategoryType

export const BudgetSelectionList = ({ className = '', disabled = false, transferItems, transferType, transferDate, currentValue, currentErrors, onChange, onErrorChange }: BudgetSelectionListProps) => {
  const [isItemDropdownOpen, openItemDropdown] = useAutoOpen()
  const budgetStore = useSelector((state: RootState) => state.budget)
  const dateYear = transferDate.getFullYear()
  const filteredItems = budgetStore.items.filter(item => isValidBudgetItem(transferType, item.type) && dateYear >= item.relevantFromYear && (item.relevantToYear === null || dateYear <= item.relevantToYear))
  filteredItems.sort(getCompareFun(transferTypeToBudgetCategoryType(transferType)))

  const onAddBudgetItem = () => {
    const newItems = [...transferItems,
      {
        id: 0,
        itemId: filteredItems[0].id,
        labelId: null,
        value: transferItems.length === 0 ? currentValue : 0
      }]
    const errors = [...currentErrors, undefined]
    onChange(newItems)
    onErrorChange(errors)
    openItemDropdown()
  }

  const onDelete = (index: number) => {
    const newItems = transferItems.filter((_, i) => i !== index)
    const errors = currentErrors.filter((_, i) => i !== index)
    onChange(newItems.length === 1 ? [{ ...newItems[0], value: currentValue }] : newItems)
    onErrorChange(errors)
  }

  const onChanged = (index: number, entry: TransferBudgetEntry) => {
    const newItems = transferItems.map((item, i) => (i === index) ? entry : item)
    onChange(newItems)
  }

  const onErrorChanged = (index: number, err: string | undefined) => {
    currentErrors[index] = err
  }

  const renderBudgetEntry = (entry: TransferBudgetEntry, index: number) => {
    return <BudgetSelectionListEntry
    budgetEntry={entry}
    currentYear={dateYear}
    disableValue={index === 0}
    autoOpen={index === transferItems.length - 1 && isItemDropdownOpen}
    error={currentErrors[index]}
    onChange={item => onChanged(index, item)}
    onErrorChange={err => onErrorChanged(index, err)}
    selectableItems={filteredItems}
    showValue={transferItems.length > 1}></BudgetSelectionListEntry>
  }

  return <SelectionList
  className={`c-budget-selection-list ${className}`}
  addButtonLabel='Add Budget Item'
  items={transferItems}
  onAdd={onAddBudgetItem}
  disabled={disabled}
  onDelete={(_, index) => onDelete(index)}
  renderItem={renderBudgetEntry}
  ></SelectionList>
}
