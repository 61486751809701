import http, { createHeaders } from '../http/'
import { Api, apiUrl } from './interface'
import { ProfileServiceInstance } from './profile/'
import { AccountServiceInstance } from './account/'
import { BudgetServiceInstance } from './budget'
import { TransferServiceInstance } from './transfer'
import { BudgetPlanServiceInstance } from './budgetplan'

const api: Api = {
  async status (): Promise<string> {
    return http.get(`${apiUrl}`, await createHeaders())
  },
  profileService: ProfileServiceInstance,
  accountService: AccountServiceInstance,
  budgetService: BudgetServiceInstance,
  transferService: TransferServiceInstance,
  budgetPlanService: BudgetPlanServiceInstance
}

export default api
