import { Icon, Status } from '@benjaminpetry/bepe-design'
import { HTMLProps } from 'react'
import './TypeIcon.scss'

export interface TypeIconBasisProps extends HTMLProps<HTMLDivElement> {
    className?: string,
    icon: string,
    iconSize?: 'normal' | 'small'
  }

export interface TypeIconProps extends TypeIconBasisProps {
  status: Status,
  statusKind?: 'text' | 'filled'
}

export const TypeIcon = ({ className = '', icon, iconSize = 'normal', status, statusKind = 'filled', ...props } : TypeIconProps) => {
  return <Icon {...props} icon={icon} className={`c-type-icon status${statusKind === 'text' ? '-text' : ''}--${status} c-type-icon--${iconSize} ${className}`}></Icon>
}
