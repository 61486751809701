import { Spinner } from '@benjaminpetry/bepe-design'
import { BudgetCategoryApi, BudgetCategoryType, BudgetItemApi } from '../../../api/domain'
import { BudgetPlan, BudgetPlanField } from '../../../api/domain/budgetplan'
import { NoContent } from '../../../components/display/NoContent'
import { getAllCategoryTypes } from '../../budget/BudgetDisplay'
import { useBudgetMonthlyYearBalance } from '../../budget/hooks/useBudgetMonthlyYearBalance'
import { useBudgetPlan } from '../hooks/useBudgetPlan'
import { BudgetPlanCategoryList } from './BudgetPlanCategoryList'
import './BudgetPlanList.scss'
import { BudgetPlanSummary } from './BudgetPlanSummary'

export interface BudgetPlanListProps {
    categories: Array<BudgetCategoryApi>;
    items: Array<BudgetItemApi>;
    filterAccountId: number | undefined;
    editMode: boolean;
    showActual: boolean;
}

export const BudgetPlanList = ({ categories, items, filterAccountId, editMode, showActual } : BudgetPlanListProps) => {
  const { budgetPlans, isLoading, isSaving, save, isAnySaving } = useBudgetPlan({ items })
  const isSavingSomething = isAnySaving()
  const [actualBalances] = useBudgetMonthlyYearBalance(items, showActual && !isLoading && !isSavingSomething)

  const onSavePlan = async (plan: BudgetPlan, field: BudgetPlanField | null, month: number | null) => {
    return await save(plan, field, month)
  }

  const renderCategoriesByType = (type: BudgetCategoryType) => {
    return categories.filter(cat => cat.type === type).map(category => <BudgetPlanCategoryList
      key={category.id}
      budgetPlans={budgetPlans}
      actualBalances={actualBalances}
      showActual={showActual}
      editMode={editMode}
      isSaving={isSaving}
      filterAccountId={filterAccountId}
      onSavePlan={onSavePlan}
      category={category}></BudgetPlanCategoryList>)
  }

  return (<div className="c-budget-plan-list">
      <div className="c-budget-plan-list__content">
        {isLoading && <Spinner className='c-budget-plan-list__loading'></Spinner>}
        {!isLoading && <BudgetPlanSummary budgetPlans={budgetPlans} categories={categories} filterAccountId={filterAccountId}></BudgetPlanSummary>}
        {!isLoading && categories.length === 0 && <NoContent>You have no budget categories to display here.</NoContent>}
        {!isLoading && getAllCategoryTypes().flatMap(renderCategoriesByType)}
      </div>
    </div>)
}
