import { Button, Icon, Icons } from '@benjaminpetry/bepe-design'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { compareAccounts } from '../domain/account/AccountLogic'
import { AccountSelection } from '../domain/account/components/AccountSelection'
import { useFilteredBudgetElements } from '../domain/budget/hooks/useFilteredBudgetElements'
import { BudgetPlanList } from '../domain/budgetplan/components/BudgetPlanList'
import { RootState } from '../store'
import './BudgetingView.scss'
import { MainView } from './components/MainView'

export const BudgetingView = () => {
  const accountStore = useSelector((state: RootState) => state.activeAccounts)
  const selectableAccounts = [...accountStore.activeAccounts].sort(compareAccounts)
  const [filteredCategories, filteredItems] = useFilteredBudgetElements()
  const [editMode, setEditMode] = useState<boolean>(false)
  const [selectedAccountId, setSelectedAccountId] = useState<number | undefined>(undefined)
  const [showActual, setShowActual] = useState<boolean>(false)

  const renderContextMenu = () => {
    return <div className='c-budgeting__context'>
      <Button size={'small'} classtype={editMode ? 'accent' : 'secondary'} onClick={() => setEditMode(state => !state)}><Icon icon={Icons.Pen}></Icon></Button>
      <Button size={'small'} classtype={showActual ? 'accent' : 'secondary'} onClick={() => setShowActual(state => !state)}><Icon icon={showActual ? Icons.EyeSlash : Icons.Eye}></Icon>{showActual ? 'Actual' : 'Actual'}</Button>
      <AccountSelection accounts={selectableAccounts} selectedAccountId={selectedAccountId ?? null} onSelect={account => setSelectedAccountId(account?.id)} allowDeselect={true}></AccountSelection>
    </div>
  }

  return (<MainView
    title="Budgeting"
    className={'c-budgeting'}
    contextMenu={renderContextMenu()}
    detailViews={[]}
    modals={[]}
    >
    <BudgetPlanList categories={filteredCategories} items={filteredItems} showActual={showActual} editMode={editMode} filterAccountId={selectedAccountId}></BudgetPlanList>
  </MainView>)
}
