import { DateFormat, ParseSettings } from './ImportLogic'

export const DKB_PARSE_SETTINGS: ParseSettings = {
  firstLineIdentifier: 'Buchungstag',
  columns: {
    accountNumber: 5,
    bic: 6,
    date: [{ column: 4, patterns: [DateFormat.YYYY_MM_DD] }, { column: 1, patterns: [DateFormat.DD_MM_YYYY] }, { column: 0, patterns: [DateFormat.DD_MM_YYYY] }],
    description: 4,
    receiver: 3,
    reference: 10,
    type: 2,
    value: 7
  }
}
