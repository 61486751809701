import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { TransferSearchResult } from '../../../api/services/transfer/interface'
import { RootState } from '../../../store'
import { getFromDate, getToDate } from '../../../components/display/DateDisplay'
import { useIsMounted } from '../../../hooks/useIsMounted'
import api from '../../../api'
import { NotificationService } from '../../../service/notificationService'
import { parseApiTransfer } from '../TransferLogic'

const NOTIFICATION_SOURCE = 'transfer-search'
const CURRENT_PAGESIZE = 20

export type SearchOptions = {
  query: string,
  page: number,
  pageSize: number
}

interface TransferSearchProps {
  accountId?: number,
  budgetItemId?: number,
}

type TransferSearchReturn = [TransferSearchResult, boolean, SearchOptions, (options: SearchOptions) => Promise<void>, () => Promise<void>]

export const useTransferSearch = (readyToLoad: boolean, { accountId, budgetItemId } : TransferSearchProps = {}): TransferSearchReturn => {
  const [busy, setBusy] = useState<boolean>(false)
  const [result, setResult] = useState<TransferSearchResult>({ count: 0, rows: [] })
  const [options, setSearchOptions] = useState<SearchOptions>({ query: '', page: 1, pageSize: CURRENT_PAGESIZE })
  const dateSelection = useSelector((state: RootState) => state.dateSelection)
  const profileState = useSelector((state: RootState) => state.activeProfile)
  const isMounted = useIsMounted()

  const parseDate = (result: TransferSearchResult): TransferSearchResult => ({ ...result, rows: result.rows.map(parseApiTransfer) })

  const searchByRequester = async (accountId: number | undefined, budgetItemId: number | undefined, from: Date, to: Date, page: number, pageSize: number, query: string | null) => {
    if (accountId !== undefined) {
      return api.accountService.findAllTransfers(accountId, from, to, page, pageSize, query)
    } else if (budgetItemId !== undefined) {
      return api.budgetService.findAllTransfers(budgetItemId, from, to, page, pageSize, query)
    }
    return api.transferService.findAll(from, to, page, pageSize, query)
  }

  const searchInternal = async (options: SearchOptions) => {
    NotificationService.clear(NOTIFICATION_SOURCE)
    setBusy(true)
    try {
      const searchResult = await searchByRequester(accountId, budgetItemId, getFromDate(dateSelection), getToDate(dateSelection), options.page - 1, options.pageSize, options.query)
      const parsedResult = parseDate(searchResult)
      if (isMounted.current) {
        setResult(parsedResult)
      }
    } catch (e) {
      NotificationService.raiseError(NOTIFICATION_SOURCE, 'Loading Transfers failed. Please try again later.')
    } finally {
      if (isMounted.current) {
        setBusy(false)
      }
    }
  }

  const search = async () => {
    return readyToLoad ? searchInternal(options) : Promise.resolve()
  }

  const setOptions = async (options: SearchOptions) => {
    setSearchOptions(options)
    searchInternal(options)
  }

  useEffect(() => {
    setSearchOptions(newOptions => ({
      ...newOptions,
      query: '',
      page: 1
    }))
  }, [accountId, budgetItemId])

  useEffect(() => {
    if (profileState.active && !profileState.isLoading) {
      search()
    }
  }, [profileState.active, profileState.isLoading, dateSelection])

  return [result, busy, options, setOptions, search]
}
