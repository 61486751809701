import { MouseEventHandler, ReactNode, useState } from 'react'
import { Icons } from '@benjaminpetry/bepe-design'
import { IconButton } from '../../input/IconButton'
import './ContextMenu.scss'

export interface ContextMenuProps {
  className?: string,
  children: ReactNode | Array<ReactNode>
}

export const ContextMenu = ({ className, children } : ContextMenuProps) => {
  const [isOpen, setOpen] = useState<boolean>(false)

  const onOutsideClick: MouseEventHandler<HTMLDivElement> = evt => {
    setOpen(false)
    evt.preventDefault()
    evt.stopPropagation()
  }

  return <div className={`c-contextmenu c-contextmenu--${isOpen ? 'open' : 'closed'} ${className ?? ''}`}>
    <IconButton icon={Icons.Ellipsis} onClick={(evt) => { setOpen(value => !value); evt.stopPropagation() }}></IconButton>
    <div className='c-contextmenu__container' onClick={onOutsideClick}>
        {children}
    </div>
    <div className='c-contextmenu__close-layer' onClick={onOutsideClick}></div>
  </div>
}
