import { ReactNode } from 'react'
import { TransferBankDetails as BankDetails } from '../../../api/domain/transfer'
import './TransferBankDetails.scss'

export interface TransferBankDetailsProps {
  className?: string,
  bankDetails: BankDetails,
}
export const TransferBankDetails = ({ className = '', bankDetails }: TransferBankDetailsProps) => {
  const renderBankDetailsRow = (header:string, content: ReactNode | undefined) => {
    return <tr><th>{header}</th><td>{content}</td></tr>
  }

  return (<table className={`c-transfer-bank-details textNormal-75 ${className}`}>
  <tbody>
      {renderBankDetailsRow('Description', bankDetails.description)}
      {renderBankDetailsRow('Receiver', bankDetails.receiver)}
      {renderBankDetailsRow('Type', bankDetails.type)}
      {renderBankDetailsRow('Dest. Account', bankDetails.accountNumber)}
      {renderBankDetailsRow('BIC', bankDetails.bic)}
      {renderBankDetailsRow('Reference', bankDetails.reference)}
  </tbody>
</table>)
}
