
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import './NotificationBar.scss'
import { NotificationTile } from './NotificationTile'

export const NotificationBar = () => {
  const notificationStore = useSelector((state: RootState) => state.notifications)
  return <div className={`c-notification-bar c-notification-bar--${notificationStore.notifications.length > 0 ? 'visible' : 'hidden'}`}>
    <div className='c-notification-bar__container'>
        {notificationStore.notifications.map(notification => <NotificationTile key={notification.id} notification={notification}></NotificationTile>)}
    </div>
  </div>
}
