import { readFile } from '@benjaminpetry/bepe-design'
import * as Papa from 'papaparse'
import { CurrencyFormatter } from '../currency/CurrencyFormatter'

export enum DateFormat {
    DD_MM_YYYY = 'dd.mmm.yyyy',
    YYYY_MM_DD = 'yyyy-mm-dd',
    YYYYMMDD = 'yyyymmdd'
}

export const DATE_CONVERT_FUNCTIONS = {
  [DateFormat.DD_MM_YYYY]: (date: string) => {
    const matches = date.match(/^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.([12][90][0-9][0-9])/i)
    return (matches) ? new Date(Number.parseInt(matches[3]), Number.parseInt(matches[2]) - 1, Number.parseInt(matches[1]), 1) : null
  },
  [DateFormat.YYYY_MM_DD]: (date: string) => {
    const matches = date.match(/^([12][90][0-9][0-9])-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])/i)
    return (matches) ? new Date(Number.parseInt(matches[1]), Number.parseInt(matches[2]) - 1, Number.parseInt(matches[3]), 1) : null
  },
  [DateFormat.YYYYMMDD]: (date: string) => {
    const matches = date.match(/^([12][90][0-9][0-9])(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])\s/i)
    return (matches) ? new Date(Number.parseInt(matches[1]), Number.parseInt(matches[2]) - 1, Number.parseInt(matches[3]), 1) : null
  }
}
export interface DateParser {
    column: number,
    patterns: Array<DateFormat>
}

export interface ParseSettings {
    firstLineIdentifier: string,
    columns: {
        date: Array<DateParser>,
        value: number,
        description: number,
        type: number | null,
        receiver: number | null,
        accountNumber: number | null,
        bic: number | null,
        reference: number | null
    }
}

export type BankStatementRow = {
    readonly date: Date,
    readonly value: number,
    readonly description: string
    readonly type: string,
    readonly receiver: string,
    readonly accountNumber: string,
    readonly bic: string,
    readonly reference: string
}

const getColumn = (rowIndex: number, colIndex: number, row: Array<string>) => {
  if (colIndex >= row.length) {
    throw new Error(`Index ${colIndex} out of range ${row.length}! Row ${rowIndex}.`)
  }
  return row[colIndex]
}

const parseDateColumnContent = (content: string, patterns: Array<DateFormat>) => {
  return patterns.reduce((result: null | Date, pattern: DateFormat) => {
    if (result !== null) {
      return result
    }
    return DATE_CONVERT_FUNCTIONS[pattern](content)
  }, null)
}

const parseDateColumn = (rowIndex: number, row: Array<string>, dateSettings: Array<DateParser>) => {
  return dateSettings.reduce((result: null | Date, setting: DateParser) => {
    if (result !== null) {
      return result
    }
    const content = getColumn(rowIndex, setting.column, row)
    return parseDateColumnContent(content, setting.patterns)
  }, null)
}

const parseBankStatementRow = (rowIndex: number, row: Array<string>, settings: ParseSettings): BankStatementRow => {
  const date = parseDateColumn(rowIndex, row, settings.columns.date)
  const valueStr = getColumn(rowIndex, settings.columns.value, row)
  const description = getColumn(rowIndex, settings.columns.description, row)
  const accountNumber = settings.columns.accountNumber === null ? '' : getColumn(rowIndex, settings.columns.accountNumber, row)
  const bic = settings.columns.bic === null ? '' : getColumn(rowIndex, settings.columns.bic, row)
  const receiver = settings.columns.receiver === null ? '' : getColumn(rowIndex, settings.columns.receiver, row)
  const reference = settings.columns.reference === null ? '' : getColumn(rowIndex, settings.columns.reference, row)
  const type = settings.columns.type === null ? '' : getColumn(rowIndex, settings.columns.type, row)
  if (date === null) {
    throw new Error(`No date in row ${rowIndex}.`)
  } else if (description === '') {
    throw new Error(`No description in row ${rowIndex}.`)
  }
  const value = CurrencyFormatter.parseString(valueStr)

  return {
    accountNumber,
    bic,
    date,
    description,
    receiver,
    reference,
    type,
    value
  }
}

export const parseBankStatement = async (file: File, settings: ParseSettings): Promise<Array<BankStatementRow>> => {
  const fileContent = await readFile(file)
  const parsedData: Array<Array<string>> = Papa.parse(fileContent).data as Array<Array<string>>
  const headerIndex = parsedData.findIndex((value) => value[0] === settings.firstLineIdentifier)
  if (headerIndex === -1) {
    throw new Error(`Could not find a valid header. Failed to parse ${file.name}.`)
  }

  const data: Array<Array<string>> = parsedData.splice(headerIndex + 1).filter(row => row[1] !== '')
  return data.map((row, index) => parseBankStatementRow(index, row, settings))
}
