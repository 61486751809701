import { useState } from 'react'
import api from '../../../api'
import { Transfer } from '../../../api/domain'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { NotificationService } from '../../../service/notificationService'

const NOTIFICATION_SOURCE = 'transfer-delete'

interface useTransferRemoveProps {
  onAfterDeleting?: () => Promise<void>,
  onAfterDeleted?: () => void,
  }

export const useTransferRemove = ({ onAfterDeleting, onAfterDeleted }: useTransferRemoveProps = {}): [boolean, (transfer: Transfer) => Promise<boolean>] => {
  const [busy, setBusy] = useState<boolean>(false)
  const isMounted = useIsMounted()

  const remove = async (transfer: Transfer): Promise<boolean> => {
    NotificationService.clear(NOTIFICATION_SOURCE)
    setBusy(true)
    try {
      await api.transferService.delete(transfer)
      if (onAfterDeleting) { await onAfterDeleting() }
      NotificationService.raiseSuccess(NOTIFICATION_SOURCE, 'Transfer has been deleted')
      if (onAfterDeleted) { await onAfterDeleted() }
      if (isMounted.current) {
        setBusy(false)
      }
      return true
    } catch (e) {
      if (isMounted.current) {
        setBusy(false)
      }
      NotificationService.raiseError(NOTIFICATION_SOURCE, 'Transfer could not been deleted. Please check your internet connection.')
    }
    return false
  }

  return [busy, remove]
}
