import { Icons } from '@benjaminpetry/bepe-design'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Account, AccountBalance, Transfer } from '../../api/domain'
import { ContextMenu } from '../../components/navigation/contextmenu/ContextMenu'
import { ContextMenuItem } from '../../components/navigation/contextmenu/ContextMenuItem'
import { createNewAccount } from '../../domain/account/AccountLogic'
import { AccountDetails } from '../../domain/account/components/AccountDetails'
import { ImportModal } from '../../domain/import/components/ImportModal'
import { useTransferSearch } from '../../domain/transfer/hooks/useTransferSearch'
import { appRoutes, Route } from '../../router'
import { DetailView } from '../components/DetailView'
import { TransferDetailsView } from '../Transfers/TransferDetailsView'
import { TransferFormModal } from '../Transfers/TransferFormModal'
import './AccountDetailsView.scss'

export interface AccountDetailsViewProps {
  className?: string,
  originTitle: string,
  account?: Account,
  balance?: AccountBalance | undefined,
  onEdit: () => void,
  onClose: () => Promise<void>,
  onLoadBalances: () => Promise<void>
}

export const AccountDetailsView = ({ className, account, balance, originTitle, onEdit, onClose, onLoadBalances }: AccountDetailsViewProps) => {
  const { transferId } = useParams()
  const navigate = useNavigate()
  const [lastAccount, setLastAccount] = useState<Account>(account ?? createNewAccount(0))
  const [lastBalance, setLastBalance] = useState<AccountBalance | undefined>(balance)
  const [transferToEdit, setTransferToEdit] = useState<Transfer | undefined>(undefined)
  const [importOpen, setImportOpen] = useState<boolean>(false)
  const [searchResult, busy, searchOptions, setSearchOptions, search] = useTransferSearch(account !== undefined, { accountId: account?.id })

  const selectedTransferId = Number.parseInt(transferId || '0')
  const selectedTransfer = transferId === undefined ? undefined : searchResult.rows.find(a => a.id === selectedTransferId)

  useEffect(() => {
    if (account) {
      setLastAccount(account)
      search()
    }
  }, [account])

  useEffect(() => {
    if (balance) {
      setLastBalance(balance)
    }
  }, [balance])

  const onDelete = () => {
    alert(`Deleting Account ${account?.name}`)
  }

  const onImport = () => {
    setImportOpen(true)
  }

  const selectTransfer = (transfer: Transfer | null) => {
    if (account) {
      if (transfer === null || selectedTransferId === transfer.id) {
        navigate(appRoutes[Route.AccountDetail](lastAccount.id))
      } else {
        navigate(appRoutes[Route.AccountTransferDetail](lastAccount.id, transfer.id))
      }
    }
    return Promise.resolve()
  }

  const renderContextMenu = () => {
    return <ContextMenu>
      {account && <div className='c-desktop-only'><ContextMenuItem icon={Icons.FileImport} onClick={onImport}>Import Transfers</ContextMenuItem></div>}
      <ContextMenuItem icon={Icons.ArrowsRotate} onClick={search}>Reload Transfers</ContextMenuItem>
      <ContextMenuItem icon={Icons.PenToSquare} onClick={onEdit}>Edit Account</ContextMenuItem>
      <ContextMenuItem icon={Icons.Trash} onClick={onDelete} isDangerousAction={true}>Delete Account</ContextMenuItem>
    </ContextMenu>
  }

  const onAfterSaving = async () => {
    await Promise.all([search(), onLoadBalances()])
  }

  const onAfterSaved = () => {
    setTransferToEdit(undefined)
  }

  return (<DetailView
    isOpen={!!account}
    className={`c-account-details-view ${className}`}
    title={lastAccount.name}
    onClose={onClose}
    contextMenu={renderContextMenu()}
    backTitle={originTitle}
    detailViews={[<TransferDetailsView onDeleting={onAfterSaving} key='account-transfer-details' originTitle={account?.name ?? 'Account'} className="c-account-transfer-details-view" transfer={selectedTransfer} onClose={() => selectTransfer(null)} onEdit={() => setTransferToEdit(selectedTransfer)}></TransferDetailsView>]}
    modals={[
      transferToEdit && <TransferFormModal key='transfer-form-modal' transfer={transferToEdit} onClose={() => setTransferToEdit(undefined)} onAfterSaving={onAfterSaving} onAfterSaved={onAfterSaved}></TransferFormModal>,
      importOpen && account && <ImportModal key='import-modal' account={account} onClose={() => setImportOpen(false)} onAfterSaving={onAfterSaving}></ImportModal>
    ]}
    >
      <AccountDetails
        account={lastAccount}
        balance={lastBalance}
        busy={busy}
        onTransferSelectionChanged={selectTransfer}
        onUpdateSearchOptions={setSearchOptions}
        searchOptions={searchOptions}
        searchResult={searchResult}
        selectedTransferId={selectedTransferId}
        ></AccountDetails>
    </DetailView>)
}
