import { ButtonHTMLAttributes } from 'react'
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom'
import { MenuItem } from './MenuItem'

export interface MenuLinkProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    icon: string,
    url: string,
    exactUrl?: boolean,
    onClick?: () => void
}

export const MenuLink = ({ url, exactUrl = false, icon, children, onClick, ...props }: MenuLinkProps) => {
  const resolved = useResolvedPath(url)
  const navigate = useNavigate()
  const match = useMatch({ path: resolved.pathname, end: exactUrl })
  const onClickHandler = () => {
    if (onClick) {
      onClick()
    }
    navigate(url)
  }
  return <MenuItem isSelected={!!match} onClick={onClickHandler} icon={icon} {...props}>{children}</MenuItem>
}
