import { Icon, Icons, Status } from '@benjaminpetry/bepe-design'
import { ReactNode, useState } from 'react'
import { BudgetCategoryApi, BudgetCategoryType, TransferType } from '../../../api/domain'
import { BudgetPlan } from '../../../api/domain/budgetplan'
import { formatMonth } from '../../../components/display/DateDisplay'
import { useFilteredBudgetElements } from '../../budget/hooks/useFilteredBudgetElements'
import { CurrencyDisplay } from '../../currency/CurrencyDisplay'
import { getTransferTypeIcon } from '../../transfer/TransferDisplay'
import { ArrayUtil } from '../ArrayUtil'
import './BudgetPlanSummary.scss'

export interface BudgetPlanSummaryProps {
  categories: Array<BudgetCategoryApi>;
  budgetPlans: Map<number, BudgetPlan>,
  filterAccountId: number | undefined
}

export const BudgetPlanSummary = ({ categories, budgetPlans, filterAccountId } : BudgetPlanSummaryProps) => {
  const [excludeReserve, setExcludeReserve] = useState<boolean>()
  const [, allItems] = useFilteredBudgetElements()
  const getSumByType = (type: BudgetCategoryType, onlyTransfer: boolean = false): Array<number> => {
    const isTransferAccount = type === BudgetCategoryType.Transfer && onlyTransfer
    const items = categories.filter(cat => cat.type === type).flatMap(cat => allItems.filter(item => item.categoryId === cat.id && (filterAccountId === undefined || (isTransferAccount ? filterAccountId === item.transferAccountId : filterAccountId === item.defaultAccountId))))
    const plans = items.map(item => budgetPlans.get(item.id)).filter(plan => plan !== undefined) as Array<BudgetPlan>
    return plans.length === 0 ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : ArrayUtil.sumOfArrays(plans.map(plan => isTransferAccount ? plan.planned.map(value => value * -1) : plan.planned))
  }

  const getAllLeftSavings = () => {
    const items = categories.filter(cat => cat.type === BudgetCategoryType.Expense || cat.type === BudgetCategoryType.Transfer).flatMap(cat => allItems.filter(item => item.categoryId === cat.id && (filterAccountId === undefined || filterAccountId === item.defaultAccountId)))
    const plans = items.map(item => budgetPlans.get(item.id)).filter(plan => plan !== undefined) as Array<BudgetPlan>
    return plans.length === 0 ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : ArrayUtil.sumOfArrays(plans.map(plan => ArrayUtil.add(plan.savings, plan.planned).map(v => Math.max(0, v))))
  }

  const incomeSum = getSumByType(BudgetCategoryType.Income)
  const expenseSum = getSumByType(BudgetCategoryType.Expense)
  const incomeTransferSum = getSumByType(BudgetCategoryType.Transfer, true)
  const expenseTransferSum = getSumByType(BudgetCategoryType.Transfer, false)
  const leftSavingsSum = ArrayUtil.negate(getAllLeftSavings())
  const totalSum = ArrayUtil.sumOfArrays([incomeSum, expenseSum, incomeTransferSum, expenseTransferSum, excludeReserve ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : leftSavingsSum])

  const renderHeader = (icon: string, title: string, color: Status, onClick: undefined | (() => void) = undefined) => {
    return <h4 className={`c-budget-plan-summary__header c-budget-plan-summary__header--${color}`} onClick={onClick}>
      <Icon className='c-budget-plan-summary__icon' icon={icon}></Icon>
      <p className='c-budget-plan-summary__header__name'>{title}</p>
    </h4>
  }

  const renderBody = (children: ReactNode | ReactNode[]) => {
    return <div className='c-budget-plan-summary__body'>
      {children}
    </div>
  }

  const renderRow = (icon: string, title: string, color: Status, sums: Array<number>, className: string = '', onClick: undefined | (() => void) = undefined) => {
    const sum = ArrayUtil.sum(sums)
    return <div className={`c-budget-plan-summary__row ${className}`}>
      {renderHeader(icon, title, color, onClick)}
      {renderBody(
        [...sums.map((value, i) => <div key={i} className='c-budget-plan-column textNormal-75'><CurrencyDisplay highlightNegative={true} highlightPositive={true} value={value}></CurrencyDisplay></div>),
        <div key={12} className='c-budget-plan-column textBold-75'><CurrencyDisplay highlightNegative={true} highlightPositive={true} value={sum}></CurrencyDisplay></div>]
      )}
    </div>
  }

  return (<div className='c-budget-plan-summary'>
    <div className='c-budget-plan-summary__row c-budget-plan-summary__header-row'>
      {renderHeader(Icons.ChartLine, 'Target Summary', Status.NEUTRAL)}
      {renderBody(
        [...[...Array(12).keys()].map((_, i) => <div key={i} className='c-budget-plan-column'>{formatMonth(i, true)}</div>),
        <div key={12} className='c-budget-plan-column textBold-100'>Sum</div>]
      )}
    </div>
    {renderRow(getTransferTypeIcon(TransferType.Income), 'Income', Status.OK, incomeSum)}
    {renderRow(getTransferTypeIcon(TransferType.Transfer), 'Income (Transfers)', Status.OK, incomeTransferSum)}
    {renderRow(getTransferTypeIcon(TransferType.Expense), 'Expense', Status.CAUTION, expenseSum)}
    {renderRow(getTransferTypeIcon(TransferType.Transfer), 'Expense (Transfers)', Status.CAUTION, expenseTransferSum)}
    {renderRow(Icons.PiggyBank, 'Reserve', Status.INFO, leftSavingsSum, `c-budget-plan-summary__reserve-row c-budget-plan-summary__reserve-row--${excludeReserve ? 'excluded' : 'integrated'}`, () => { setExcludeReserve(reserve => !reserve) })}
    {renderRow(Icons.Plus, 'Total', Status.NEUTRAL, totalSum, 'c-budget-plan-summary__total-row')}
</div>)
}
