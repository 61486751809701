import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const SECRET_STATE_KEY = 'secret-state-key'

export interface SecretState {
    secretMode: boolean
}

const startState: SecretState = {
  secretMode: false
}
const initialState: SecretState = JSON.parse(localStorage.getItem(SECRET_STATE_KEY) || 'null') || startState

const updateLocalStore = (state: SecretState): SecretState => {
  localStorage.setItem(SECRET_STATE_KEY, JSON.stringify(state))
  return state
}

export const secretSlice = createSlice({
  name: 'secret',
  initialState,
  reducers: {
    setSecretMode: (state, action: PayloadAction<boolean>) => {
      return updateLocalStore({ ...state, secretMode: action.payload })
    }
  }
})

export const { setSecretMode } = secretSlice.actions

export const SecretRuducer = secretSlice.reducer
