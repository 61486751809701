import { Icon } from '@benjaminpetry/bepe-design'
import { ReactNode } from 'react'
import { BudgetCategory, BudgetItem, BudgetLabel } from '../../../../api/domain'
import { getBudgetClassName } from '../../BudgetDisplay'
import './BudgetCategoryHeader.scss'

export interface BudgetCategoryHeaderProps<E extends BudgetLabel, T extends BudgetItem<E>> {
    category: BudgetCategory<E, T>;
    className?: string;
    children?: ReactNode | Array<ReactNode>
}

export const BudgetCategoryHeader = <E extends BudgetLabel, T extends BudgetItem<E>>({ category, children, className = '' } : BudgetCategoryHeaderProps<E, T>) => {
  return <div className={`c-budget-category-header c-budget-category-header--${getBudgetClassName(category.type)} ${className}`}>
      <h4 className='c-budget-category-header__title'>
        <Icon className='c-budget-category-header__icon' icon={category.icon}></Icon>
        <p className='c-budget-category-header__name'>{category.name}</p>
      </h4>
      <div className='c-budget-category-header__columns'>
        {children}
      </div>
  </div>
}
