import { useSelector } from 'react-redux'
import { RootState, store } from '../store'
import { setSecretMode } from '../store/secretStore'

type SecretModeReturn = [boolean, (mode: boolean) => Promise<void>]

export const useSecretMode = (): SecretModeReturn => {
  const secretStore = useSelector((state: RootState) => state.secret)

  const setMode = async (mode: boolean) => {
    store.dispatch(setSecretMode(mode))
  }

  return [secretStore.secretMode, setMode]
}
