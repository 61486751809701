import { Status, Icons } from '@benjaminpetry/bepe-design'
import { AccountType } from '../../api/domain/account'

export const getAllAccountTypesSorted = (): Array<AccountType> => ([AccountType.Cash, AccountType.Bank, AccountType.Credit, AccountType.Savings, AccountType.Assets])

export const getAccountIcon = (accountType: AccountType): Icons => {
  switch (accountType) {
    case AccountType.Bank:
      return Icons.CreditCard
    case AccountType.Cash:
      return Icons.Wallet
    case AccountType.Credit:
      return Icons.BuildingColumns
    case AccountType.Savings:
      return Icons.DollarSign
    case AccountType.Assets:
      return Icons.HouseChimneyWindow
  }
}

export const getAccountClassName = (accountType: AccountType): Status => {
  switch (accountType) {
    case AccountType.Bank:
      return Status.OK
    case AccountType.Cash:
      return Status.OK
    case AccountType.Credit:
      return Status.WARNING
    case AccountType.Savings:
      return Status.INFO
    case AccountType.Assets:
      return Status.INFO
  }
}
