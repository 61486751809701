import { Button, Signal, Textfield } from '@benjaminpetry/bepe-design'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { HttpError } from '../api'
import { appRoutes, Route } from '../router'
import { NotificationService } from '../service/notificationService'
import { ProfileService } from '../service/profileService'
import { RootState } from '../store'
import './LoginLayout.scss'

const NOTIFICATION_SOURCE = 'login'

function LoginLayout () {
  const [loadingProfile, setLoadingProfile] = useState<string>('')
  const [profileKey, setProfileKey] = useState('')
  const [profileError, setProfileError] = useState<string | undefined>(undefined)

  const profileStore = useSelector((state: RootState) => state.activeProfile)
  const navigate = useNavigate()

  const loadProfile = async (profileKey: string) => {
    if (profileKey === '') {
      setProfileError('Please enter a profile key')
      return
    }
    NotificationService.clear(NOTIFICATION_SOURCE)
    setProfileError(undefined)
    setLoadingProfile(profileKey)
    try {
      const profile = await ProfileService.login(profileKey)
      NotificationService.raiseSuccess(NOTIFICATION_SOURCE, `Welcome ${profile.name}!`, false)
      setLoadingProfile('')
      navigate(appRoutes[Route.Dashboard]())
    } catch (e) {
      if (e instanceof HttpError && e.statusCode === 401) {
        setLoadingProfile('')
        setProfileError('Invalid profile key.')
        NotificationService.raiseError(NOTIFICATION_SOURCE, 'Invalid profile key.')
      } else {
        const errorMessage = 'Can\'t connect to the server. Please check your internet connection.'
        NotificationService.raiseError(NOTIFICATION_SOURCE, errorMessage)
        setLoadingProfile('')
      }
    }
  }

  return (
    <div className="c-login-layout">
      <header>
        <h1>Login</h1>
      </header>
      <main>
        <section className="c-login-layout__form">
            <Textfield
              label="Profile Key"
              hint={profileError}
              signal={profileError ? Signal.DANGER : undefined}
              value={profileKey}
              disabled={!!loadingProfile}
              onChange={evt => { setProfileKey(evt.target.value); setProfileError(undefined) }}
              onSubmit={() => loadProfile(profileKey)}
              onEnter={() => loadProfile(profileKey)}>
            </Textfield>
            <Button classtype='primary' disabled={!!loadingProfile} busy={loadingProfile !== '' && loadingProfile === profileKey} onClick={() => loadProfile(profileKey)}>Login</Button>
        </section>
        <section className='c-login-layout__last-profiles'>
          <h2>Last used Profiles</h2>
          <ul>
          {profileStore.lastProfiles.map(({ profile, profileKey }) => <li key={profile.id}>
            <p>{profile.name}</p>
            <div className={'c-login-layout__last-profiles__button-container'}>
            {<Button busy={loadingProfile === profileKey} disabled={!!loadingProfile} size='small' classtype='secondary' onClick={() => loadProfile(profileKey)}>Load</Button>}
            </div>
            </li>)}
          </ul>
        </section>
      </main>
    </div>
  )
}

export default LoginLayout
