import { HTMLProps } from 'react'
import { BudgetItem, BudgetLabel } from '../../../../api/domain'
import { BudgetTypeIcon } from '../../../../domain/budget/BudgetTypeIcon'
import { CurrencyDisplay } from '../../../currency/CurrencyDisplay'
import './BudgetItemModuleMini.scss'

export interface BudgetItemModuleMiniProps extends HTMLProps<HTMLDivElement> {
  item: BudgetItem<BudgetLabel>,
  budgetLabel?: BudgetLabel | null,
  className?: string,
  value?: number
}

export const BudgetItemModuleMini = ({ item, budgetLabel, value, className = '', ...props }: BudgetItemModuleMiniProps) => {
  return (<div {...props} className={`textNormal-75 c-budget-item-tile-mini c-budget-item-tile-mini--${value !== undefined ? 'show-value' : 'no-value'} ${className}`}>
    <div className='c-budget-item-tile-mini__icon-name-container'>
      <BudgetTypeIcon iconSize='small' className='c-budget-item-tile-mini__icon' icon={item.icon} type={item.type}></BudgetTypeIcon>
      <div className='c-budget-item-tile-mini__name'>{item.name}{budgetLabel && ` (${budgetLabel.name})`}</div>
    </div>
    {value && <CurrencyDisplay value={value} highlightNegative={true} highlightPositive={true}></CurrencyDisplay>}
  </div>)
}
