
import { Content } from './Content'
import './ProgressDisplay.scss'

export interface ProgressDisplayProps {
  className?: string,
  progress: number | undefined,
  colorScheme: ColorScheme,
  expectedProgress?: number | undefined
}

const enum ProgressState {
  CAUTION = 'caution',
  WARNING = 'warning',
  OK = 'ok',
  NEUTRAL = 'neutral',
}

export const enum ColorScheme {
  EXPENSES = 'expenses',
  INCOME = 'income',
  SAVINGS = 'savings',
}

const ColorSchemeDefinition = {
  [ColorScheme.EXPENSES]: (progress: number, expectedProgress: number) => (progress > 1 ? ProgressState.CAUTION : (progress > expectedProgress ? ProgressState.WARNING : ProgressState.OK)),
  [ColorScheme.INCOME]: (progress: number, expectedProgress: number) => (progress < 0 ? ProgressState.CAUTION : (progress < expectedProgress ? ProgressState.WARNING : ProgressState.OK)),
  [ColorScheme.SAVINGS]: (progress: number, expectedProgress: number) => (progress < expectedProgress ? ProgressState.CAUTION : (progress < 1 ? ProgressState.WARNING : ProgressState.OK))
}

export const ProgressDisplay = ({ className, progress, expectedProgress, colorScheme } : ProgressDisplayProps) => {
  const percentMax = Math.max(progress ?? 0, 1, expectedProgress ?? 0)
  const percentMin = Math.min(progress ?? 0, 0, expectedProgress ?? 0)
  const totalPercent = percentMax - percentMin
  const display = (percent: number) => Math.round((percent - percentMin) / totalPercent * 1000) / 10
  const state = progress === undefined || expectedProgress === undefined ? ProgressState.NEUTRAL : ColorSchemeDefinition[colorScheme](progress, expectedProgress)
  return <Content className={`c-progress-display c-progress-display--${state} ${className ?? ''}`}>
    {progress !== undefined && expectedProgress !== undefined && <div className='c-progress-display__container'>
      <div className='c-progress-display__expect-container'>
        <div className='c-progress-display__expect-marker' style={{ left: `${display(expectedProgress)}%` }}></div>
        { percentMax > 1 && <div className='c-progress-display__hundred-marker' style={{ left: `${display(1)}%` }}></div>}
        { percentMin < 0 && <div className='c-progress-display__zero-marker' style={{ left: `${display(0)}%` }}></div>}
      </div>
      <div className='c-progress-display__progress-container'>
        <div className='c-progress-display__background-bar'></div>
        <div className='c-progress-display__progress-bar' style={{ width: `${display(Math.max(0, progress))}%` }}></div>
      </div>
      <div className='c-progress-display__center-container'>
        { percentMin < 0 && <div className='c-progress-display__zero-label' style={{ left: `${display(0)}%` }}>0%</div>}
        { percentMax > 1 && <div className='c-progress-display__hundred-label' style={{ left: `${display(1)}%` }}>100%</div>}
      </div>
    </div>}
    </Content>
}
