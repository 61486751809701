import { HTMLProps } from 'react'
import { CurrencyDisplay } from '../../../currency/CurrencyDisplay'
import { BudgetLabelViewElement } from '../../BudgetDisplay'
import './BudgetLabelModule.scss'
import { BudgetLabelModuleMini } from './BudgetLabelModuleMini'

export interface BudgetLabelModuleProps extends HTMLProps<HTMLDivElement> {
  budgetLabel: BudgetLabelViewElement,
  forceShowValue?: boolean,
  hideValue?: boolean,
  className?: string
}

export const BudgetLabelModule = ({ budgetLabel, hideValue, forceShowValue = false, className = '', ...props }: BudgetLabelModuleProps) => {
  return (<div {...props} className={`c-budget-label-module ${className}`}>
      <BudgetLabelModuleMini budgetLabel={budgetLabel}></BudgetLabelModuleMini>
      {!hideValue && <div className={`c-budget-label-module__balance ${forceShowValue ? '' : 'c-desktop-only'}`}>
        <CurrencyDisplay highlightNegative={true} highlightPositive={true} value={budgetLabel.balance?.total}></CurrencyDisplay>
      </div>}
  </div>)
}
