import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import api from '../../../api'
import { BudgetItem, BudgetLabel } from '../../../api/domain'
import { BudgetPlan, BudgetPlanField } from '../../../api/domain/budgetplan'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { NotificationService } from '../../../service/notificationService'
import { RootState } from '../../../store'

const NOTIFICATION_SOURCE = 'budget-plan'

interface UseBudgetPlanProps <E extends BudgetLabel, T extends BudgetItem<E>> {
  items: Array<T>,
  onAfterSaving?: () => Promise<void>,
  onAfterSaved?: () => void,
}

type BudgetPlanReturn = {
  isLoading: boolean,
  isSaving: (id: number, field: BudgetPlanField | null, month: number | null) => boolean,
  isAnySaving: () => boolean,
  load: () => Promise<void>,
  save: (plan: BudgetPlan, field: BudgetPlanField | null, month: number | null) => Promise<boolean>,
  budgetPlans: Map<number, BudgetPlan>
}

export const useBudgetPlan = <E extends BudgetLabel, T extends BudgetItem<E>>({ items, onAfterSaved, onAfterSaving }: UseBudgetPlanProps<E, T>): BudgetPlanReturn => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [savingState, setSavingState] = useState<Set<string>>(new Set())
  const [budgetPlans, setBudgetPlans] = useState<Map<number, BudgetPlan>>(new Map())
  const profileState = useSelector((state: RootState) => state.activeProfile)
  const dateSelection = useSelector((state: RootState) => state.dateSelection)
  const isMounted = useIsMounted()
  const itemIds = items.map(item => item.id)

  const updatePlanMap = (updatedPlans: Array<BudgetPlan>) => {
    if (isMounted.current) {
      setBudgetPlans(map => {
        const updatedMap = new Map(map)
        updatedPlans.forEach(plan => {
          updatedMap.set(plan.itemId, plan)
        })
        return updatedMap
      })
    }
  }

  const load = async () => {
    NotificationService.clear(NOTIFICATION_SOURCE)
    setLoading(true)
    try {
      const result = itemIds.length === 0 ? [] : await api.budgetPlanService.findAll(dateSelection.year, itemIds)
      const itemMap = new Map(result.map(res => [res.itemId, res]))
      if (isMounted.current) {
        setBudgetPlans(itemMap)
      }
    } catch (e) {
      NotificationService.raiseError(NOTIFICATION_SOURCE, 'Loading Budget Plans failed. Please try again later.')
    } finally {
      if (isMounted.current) {
        setLoading(false)
      }
    }
  }

  const isSaving = (id: number, field: BudgetPlanField | null, month: number | null) => {
    return savingState.has(`${id}|${field}|${month}`)
  }

  const isAnySaving = () => {
    return savingState.size > 0
  }

  const updateSaving = (id: number, field: BudgetPlanField | null, month: number | null, isSaving: boolean) => {
    const key = `${id}|${field}|${month}`
    if (isSaving) {
      setSavingState(state => new Set(state).add(key))
    } else {
      setSavingState(state => {
        const s = new Set(state)
        s.delete(key)
        return s
      })
    }
  }

  const save = async (plan: BudgetPlan, field: BudgetPlanField | null, month: number | null): Promise<boolean> => {
    NotificationService.clear(NOTIFICATION_SOURCE)
    updateSaving(plan.id, field, month, true)
    updatePlanMap([plan])
    try {
      const result = await api.budgetService.updatePlan(plan, dateSelection.year)
      if (onAfterSaving) { await onAfterSaving() }
      NotificationService.raiseSuccess(NOTIFICATION_SOURCE, 'Budget Plan has been saved')
      if (onAfterSaved) { await onAfterSaved() }
      if (isMounted.current) {
        updateSaving(plan.id, field, month, false)
        updatePlanMap([result.updatedPlan, ...result.otherUpdatedPlans])
      }
      return true
    } catch (e) {
      if (isMounted.current) {
        updateSaving(plan.id, field, month, false)
      }
      NotificationService.raiseError(NOTIFICATION_SOURCE, 'Budget Plan could not been saved. Please check your internet connection.')
    }
    return false
  }

  useEffect(() => {
    if (profileState.active && !profileState.isLoading) {
      load()
    }
  }, [profileState.active, profileState.isLoading, dateSelection, items])

  return { isLoading, isSaving, load, save, budgetPlans, isAnySaving }
}
