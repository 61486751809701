import { Spinner, UploadField } from '@benjaminpetry/bepe-design'
import { useState } from 'react'
import { Account } from '../../../api/domain'
import { ImportTransfer } from '../../../api/domain/import'
import { NoContent } from '../../../components/display/NoContent'
import { Modal } from '../../../layout/modal/Modal'
import { NotificationService } from '../../../service/notificationService'
import { createTransferError, TransferError, updateTransferError, validateTransfer } from '../../transfer/TransferLogic'
import { useImport } from '../hooks/useImport'
import { DKB_PARSE_SETTINGS } from '../ImportMock'
import './ImportModal.scss'
import { ImportTransferModule } from './ImportTransferModule'

const NOTIFICATION_SOURCE = 'import-modal'

export interface ImportModalProps {
  className?: string,
  account: Account,
  onAfterSaving?: () => Promise<void>,
  onAfterSaved?: () => void,
  onClose: () => void
}

export const ImportModal = ({ className, account, onClose, onAfterSaving, onAfterSaved }: ImportModalProps) => {
  const [preparedTransfers, busyLoading, busySaving, prepare, save] = useImport({ accountId: account.id, onAfterSaving, onAfterSaved })
  const [editableTransfers, setEditableTransfers] = useState<Array<ImportTransfer>>([])
  const [error, setError] = useState<Array<TransferError>>([])
  const busy = busyLoading || busySaving

  const onRemove = (index: number) => {
    const copy = editableTransfers.filter((t, i) => i !== index)
    const errorCopy = error.filter((t, i) => i !== index)
    setEditableTransfers(copy)
    setError(errorCopy)
  }

  const updateError = (index: number, partialError: Partial<TransferError>) => {
    setError(e => e.map((t, i) => i === index ? updateTransferError(t, partialError) : t))
  }

  const onSaveAll = async () => {
    const validity = editableTransfers.reduce((all, t, i) => {
      const newError = validateTransfer(t.transfer)
      updateError(i, newError.errors)
      return all && newError.valid
    }, true)
    if (!validity) {
      NotificationService.raiseError(NOTIFICATION_SOURCE, 'Not all transfers are valid yet. Please check all transfers.')
      return
    }
    await save(editableTransfers.map(t => t.transfer))
    onClose()
  }

  const onSaveSingle = async (index: number) => {
    const transfer = editableTransfers[index]
    const newError = validateTransfer(transfer.transfer)
    updateError(index, newError.errors)
    if (!newError.valid) {
      return
    }
    await save([transfer.transfer])
    onRemove(index)
  }

  const onCancel = () => {
    if (!busy) {
      onClose()
    }
  }

  const onFile = async (file: File) => {
    NotificationService.clear(NOTIFICATION_SOURCE)
    try {
      const result = await prepare(file, DKB_PARSE_SETTINGS)
      if (result) {
        setError(result.map(transfer => createTransferError(transfer.transfer.budgetItems)))
        setEditableTransfers(result)
      }
    } catch (e) {
      NotificationService.raiseError(NOTIFICATION_SOURCE, (e as Error).message)
    }
  }

  const onTransferChange = (index: number, transfer: ImportTransfer) => {
    const copy = editableTransfers.map((item, i) => i === index ? transfer : item)
    setEditableTransfers(copy)
  }

  return (<Modal className={`c-import-modal ${className ?? ''}`} busy={busySaving} title={`Import Transfers to ${account.name}`} confirmButtonTitle='Add Transfers' confirmButtonDisabled={preparedTransfers === null || preparedTransfers.length === 0} onCancel={onCancel} onConfirm={onSaveAll}>
    {!busyLoading && preparedTransfers === null && <UploadField onFile={onFile} label='Drag your CSV bank statement here.' hoverLabel='Drop your CSV bank statement here.'></UploadField>}
    {busyLoading && <Spinner className='c-import-modal__spinner'></Spinner>}
    {!busyLoading && preparedTransfers !== null && preparedTransfers.length > 0 && (<div className={'c-import-modal__table-header c-import-table__header'}>
    <div className='c-import-table__icon'></div>
      <div className='c-import-table__date'>Date</div>
      <div className='c-import-table__description'>Description</div>
     <div className='c-import-table__value'>Value</div>
      <div className='c-import-table__transfer-account'>Transfer Account</div>
      <div className='c-import-table__budgetItems'>Budget Items</div>
      <div className='c-import-table__comment'>Comment</div>
      <div className='c-import-table__actions'>Actions</div>
    </div>)}
    {!busyLoading && preparedTransfers !== null && preparedTransfers.length > 0 && editableTransfers.map((t, i) => {
      return <ImportTransferModule busy={busy} error={error[i]} onErrorChanged={e => updateError(i, e)} key={i} importTransfer={t} onChange={item => onTransferChange(i, item)} onSave={() => onSaveSingle(i)} onRemove={() => onRemove(i)}></ImportTransferModule>
    })}
    {!busyLoading && preparedTransfers !== null && preparedTransfers.length === 0 && <NoContent>No new transfers found.</NoContent>}
  </Modal>)
}
