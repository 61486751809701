import { useEffect, useState } from 'react'
import { Icons } from '@benjaminpetry/bepe-design'
import './TransferDetailsView.scss'
import { Transfer } from '../../api/domain'
import { ContextMenu } from '../../components/navigation/contextmenu/ContextMenu'
import { ContextMenuItem } from '../../components/navigation/contextmenu/ContextMenuItem'
import { DetailView } from '../components/DetailView'
import { TransferDetails } from '../../domain/transfer/components/TransferDetails'
import { useTransferRemove } from '../../domain/transfer/hooks/useTransferRemove'

export interface TransferDetailsViewProps {
  className?: string,
  originTitle: string,
  transfer?: Transfer,
  onClose: () => Promise<void>,
  onEdit: () => void,
  onDeleting: () => Promise<void>,
  onDeleted?: () => void
}
// accountId
export const TransferDetailsView = ({ className, transfer, originTitle, onClose, onEdit, onDeleted, onDeleting }: TransferDetailsViewProps) => {
  const [lastTransfer, setLastTransfer] = useState<Transfer | undefined>(transfer)
  const [, remove] = useTransferRemove({ onAfterDeleted: onDeleted, onAfterDeleting: onDeleting })

  useEffect(() => {
    if (transfer) {
      setLastTransfer(transfer)
    }
  }, [transfer])

  const onDelete = async () => {
    if (transfer && confirm(`Do you want to delete ${transfer?.description}`)) {
      if (await remove(transfer)) {
        onClose()
      }
    }
  }

  const renderContextMenu = () => {
    return <ContextMenu>
      <ContextMenuItem icon={Icons.PenToSquare} onClick={onEdit}>Edit Transfer</ContextMenuItem>
      <ContextMenuItem icon={Icons.Trash} onClick={onDelete} isDangerousAction={true}>Delete Transfer</ContextMenuItem>
    </ContextMenu>
  }

  return (<DetailView
    isOpen={!!transfer}
    className={`c-transfer-details-view ${className}`}
    title={lastTransfer?.description || 'Loading...'}
    onClose={onClose}
    contextMenu={renderContextMenu()}
    backTitle={originTitle}
    detailViews={[]}>
        <TransferDetails transfer={lastTransfer}></TransferDetails>
    </DetailView>)
}

export {}
