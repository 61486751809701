export enum TransferType {
  Income = 'income',
  Expense = 'expense',
  Transfer = 'transfer'
}

export type TransferBankDetails = {
  readonly date: string,
  readonly value: string,
  readonly description: string,
  readonly type: string,
  readonly receiver: string,
  readonly accountNumber: string,
  readonly bic: string,
  readonly reference: string
}

export type TransferBudgetEntry = {
  readonly id: number,
  readonly itemId: number,
  readonly labelId: number | null,
  readonly value: number
}

export type Transfer = {
  readonly id: number,
  readonly profileId: number,
  readonly type: TransferType,
  readonly date: Date,
  readonly description: string,
  readonly value: number,
  readonly accountId: number,
  readonly transferAccountId: number | null,
  readonly comment: string,
  readonly inverted: boolean,
  readonly dateHash: string,
  readonly bankHash: string | null,
  readonly transferBankHash: string | null,
  readonly bankDetails: TransferBankDetails | null,
  readonly transferBankDetails: TransferBankDetails | null,
  readonly budgetItems: Array<TransferBudgetEntry>
}
