import { Icons } from '@benjaminpetry/bepe-design'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Transfer } from '../api/domain'
import { ContextMenu } from '../components/navigation/contextmenu/ContextMenu'
import { ContextMenuItem } from '../components/navigation/contextmenu/ContextMenuItem'
import { useTransferSearch } from '../domain/transfer/hooks/useTransferSearch'
import { createNewTransfer } from '../domain/transfer/TransferLogic'
import { appRoutes, Route } from '../router'
import { AccountService } from '../service/accountService'
import { RootState } from '../store'
import { MainView } from './components/MainView'
import { TransferDetailsView } from './Transfers/TransferDetailsView'
import { TransferFormModal } from './Transfers/TransferFormModal'
import { TransferListViewModule } from './Transfers/TransferListViewModule'

export const TransfersView = () => {
  const { transferId } = useParams()
  const navigate = useNavigate()
  const profileState = useSelector((state: RootState) => state.activeProfile)
  const [transferToEdit, setTransferToEdit] = useState<Transfer | undefined>(undefined)
  const [searchResult, busy, searchOptions, setSearchOptions, search] = useTransferSearch(true)

  const onAfterSaving = async () => {
    return search()
  }

  const onAfterSaved = () => {
    setTransferToEdit(undefined)
  }

  const onCreateNewTransfer = () => {
    setTransferToEdit(createNewTransfer(profileState.active!.id, AccountService.getDefaultAccountId() ?? undefined, undefined))
  }

  const renderContextMenu = () => {
    return <ContextMenu>
      <ContextMenuItem icon={Icons.Plus} onClick={onCreateNewTransfer}>Add New Transfer</ContextMenuItem>
      <ContextMenuItem icon={Icons.ArrowsRotate} onClick={search}>Reload Data</ContextMenuItem>
    </ContextMenu>
  }

  const onSelectionChanged = (transfer: Transfer | null): Promise<void> => {
    if (transfer === null) {
      navigate(appRoutes[Route.Transfers]())
    } else {
      navigate(appRoutes[Route.TransferDetail](transfer.id))
    }
    return Promise.resolve()
  }

  const selectedTransferId = Number.parseInt(transferId || '0')
  const selectedTransfer = transferId === undefined ? undefined : searchResult.rows.find(a => a.id === selectedTransferId)

  return <MainView
  className={`c-transfers c-transfers--${selectedTransfer ? 'details-open' : 'details-closed'}`}
  title="Transfers"
  contextMenu={renderContextMenu()}
  detailViews={[<TransferDetailsView onDeleting={onAfterSaving} key='transfer-details' originTitle="Transfer" className="c-transfer__details" transfer={selectedTransfer} onClose={() => onSelectionChanged(null)} onEdit={() => setTransferToEdit(selectedTransfer)}></TransferDetailsView>]}
  modals={transferToEdit && <TransferFormModal transfer={transferToEdit} onClose={() => setTransferToEdit(undefined)} onAfterSaving={onAfterSaving} onAfterSaved={onAfterSaved}></TransferFormModal>}
  >
    <TransferListViewModule
    onSelectionChanged={onSelectionChanged}
    onUpdateSearchOptions={setSearchOptions}
    searchOptions={searchOptions}
    busy={busy}
    searchResult={searchResult}
    selectedTransferId={selectedTransferId}
    ></TransferListViewModule>
</MainView>
}
