import api from '../api'
import { Profile } from '../api/domain'
import { store } from '../store'
import { clearActiveProfile, setActiveProfile, setLoading } from '../store/profileStore'
import { AccountService } from './accountService'
import { BudgetService } from './budgetService'

const login = async (profileKey: string): Promise<Profile> => {
  store.dispatch(setLoading(true))
  try {
    const profile = await api.profileService.login(profileKey)
    store.dispatch(setActiveProfile({ profile, profileKey }))
    await AccountService.load()
    await BudgetService.load()
    return profile
  } finally {
    store.dispatch(setLoading(false))
  }
}

const logout = async (): Promise<void> => {
  await api.profileService.logout()
  store.dispatch(clearActiveProfile())
  await AccountService.clear()
  await AccountService.setDefaultAccountId(null)
  await BudgetService.clear()
}

export const ProfileService = {
  login,
  logout
}
