
import { Account, BudgetItem, BudgetLabel, Transfer } from '../../../api/domain'
import { AccountModuleMini } from '../../../domain/account/components/AccountModuleMini'
import { BudgetItemModuleMini } from '../../../domain/budget/components/item/BudgetItemModuleMini'
import { getTransferTypeIcon } from '../../../domain/transfer/TransferDisplay'
import { TransferTypeIcon } from '../../../domain/transfer/TransferTypeIcon'
import { CurrencyDisplay } from '../../currency/CurrencyDisplay'
import './TransferModule.scss'

export interface TransferModuleProps {
  transfer: Transfer,
  filteredAccountId?: number | undefined,
  accountMap: Map<number, Account>,
  budgetItemMap: Map<number, BudgetItem<BudgetLabel>>,
  tailerToBudgetItemId?: number,
  className?: string,
  selected: boolean,
  onClick?: () => void
}

const getBudgetItemAndLabel = (budgetItemId: number, budgetLabelId: number | null, budgetItemMap: Map<number, BudgetItem<BudgetLabel>>) : {budgetItem: BudgetItem<BudgetLabel> | null, budgetLabel: BudgetLabel | null} => {
  const budgetItem = budgetItemMap.get(budgetItemId)
  if (budgetItem) {
    const budgetLabel = budgetLabelId ? budgetItem.labels.find(label => label.id === budgetLabelId) || null : null
    return { budgetItem, budgetLabel }
  }
  return { budgetItem: null, budgetLabel: null }
}

export const TransferModule = ({ transfer, accountMap, budgetItemMap, selected, tailerToBudgetItemId, className, onClick, filteredAccountId }: TransferModuleProps) => {
  const account = accountMap.get(transfer.accountId)
  const transferAccount = transfer.transferAccountId ? accountMap.get(transfer.transferAccountId) : undefined
  const budgetItems = transfer.budgetItems.map(item => ({ ...item, ...getBudgetItemAndLabel(item.itemId, item.labelId, budgetItemMap) }))
  const showAccounts = (filteredAccountId === undefined || transferAccount !== undefined || filteredAccountId !== account?.id)
  const showBudgetItems = budgetItems.length > 0
  const totalValue = transfer.value
  const tailoredValue = tailerToBudgetItemId === undefined ? totalValue : transfer.budgetItems.filter(i => i.itemId === tailerToBudgetItemId).reduce((sum, i) => sum + i.value, 0)

  return (<div className={`c-transfer-tile interactive c-transfer-tile--${selected ? 'selected' : 'unselected'} ${className ?? ''}`} onClick={onClick}>
    <div className='c-transfer-tile__core'>
      <TransferTypeIcon icon={getTransferTypeIcon(transfer.type)} type={transfer.type}></TransferTypeIcon>
      <div className='c-transfer-tile__content'>
        <div className='c-transfer-tile__row-1'>
          <h5 className='c-transfer-tile__description'>{transfer.description}</h5>
          <div className='c-transfer-tile__value-container'>
            {totalValue !== tailoredValue && <CurrencyDisplay prefix='(' suffix=')' className='c-transfer-tile__value-total textNormal-75' value={totalValue}></CurrencyDisplay>}
            <CurrencyDisplay className='c-transfer-tile__value' highlightNegative={true} highlightPositive={true} value={tailoredValue}></CurrencyDisplay>
          </div>
        </div>
        <div className='c-transfer-tile__row-2'>
            <div className='c-transfer-tile__date textNormal-75'>{transfer.date.toLocaleDateString()}</div>
            <div className='c-transfer-tile__additional-info'>
              {showAccounts && <div className='c-transfer-tile__accounts'>
                {account && <AccountModuleMini account={account}></AccountModuleMini>}
                {transferAccount && <AccountModuleMini account={transferAccount}></AccountModuleMini>}
              </div>
              }
              {showBudgetItems && showAccounts && <div className="c-transfer-tile__seperator"></div>}
              {showBudgetItems && <div className='c-transfer-tile__budget-items'>
                {budgetItems.map(item => {
                  return item.budgetItem && <BudgetItemModuleMini key={item.id} className='c-transfer-tile__budgetItem' item={item.budgetItem} budgetLabel={item.budgetLabel} value={item.value}></BudgetItemModuleMini>
                })}
            </div>}
          </div>
        </div>
      </div>
    </div>
  </div>)
}
