export enum ProgressGranularity {
    DAY = 'day',
    MONTH = 'month',
    NONE = 'none'
}

export enum BudgetPlanSavingMode {
    Auto = 'auto',
    Manual = 'manual',
    NoSaving = 'none'
}

export enum BudgetPlanSavingType {
    CONTINUOUS = 'continuous',
    YEARLY = 'yearly'
}

export enum BudgetPlanField {
    TARGETS = 'targets',
    SAVINGS = 'savings',
    SAVINGSSTART = 'savingsStart'
}

export type BudgetPlan = {
    readonly id: number,
    readonly profileId: number,
    readonly itemId: number,
    readonly year: number,
    readonly savingMode: BudgetPlanSavingMode,
    readonly savingType: BudgetPlanSavingType,
    readonly progressGranularity: ProgressGranularity,
    readonly planned: Array<number>,
    readonly savingsStart: number,
    readonly savings: Array<number>
  }
