import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createNewTransfer } from '../domain/transfer/TransferLogic'
import { appRoutes, Route } from '../router'
import { AccountService } from '../service/accountService'
import { RootState } from '../store'
import { MainView } from './components/MainView'
import { TransferFormModal } from './Transfers/TransferFormModal'

export const AddTransferView = () => {
  const navigate = useNavigate()
  const profileStore = useSelector((state: RootState) => state.activeProfile)
  const newTransfer = createNewTransfer(profileStore.active!.id, AccountService.getDefaultAccountId() ?? undefined, undefined)

  const onCancel = () => {
    navigate(-1)
  }

  const onAfterSaved = async () => {
    navigate(appRoutes[Route.Transfers]())
  }

  return (<MainView title="New Transfer">
    <TransferFormModal transfer={newTransfer} onClose={onCancel} onAfterSaved={onAfterSaved}></TransferFormModal>
    {/* <Modal onCancel={onCancel} busy={busy} confirmButtonTitle='Add' onConfirm={() => onSave()} title={'Add Transfer'}>
        <TransferDetails transfer={newTransfer} isInEditMode={true} onChange={t => setNewTransfer(t)}></TransferDetails>
      </Modal> */}
  </MainView>)
}
