import { ReactNode } from 'react'
import './NoContent.scss'

export interface NoContentProps {
  className?: string,
  children: ReactNode | Array<ReactNode>
}

export const NoContent = ({ className = '', children } : NoContentProps) => {
  return <p className={`c-no-content ${className}`}>{children}</p>
}
