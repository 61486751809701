
import { BudgetItem, BudgetItemApi, BudgetLabel } from '../../../../api/domain'
import { NoContent } from '../../../../components/display/NoContent'
import { SelectionDropdown } from '../../../../components/input/selection/SelectionDropdown'
import { BudgetItemModuleMini } from './BudgetItemModuleMini'
import './BudgetItemSelection.scss'

export interface BudgetItemSelectionProps {
  className?: string,
  items: Array<BudgetItemApi>,
  autoOpen: boolean,
  selectedItemId: number,
  onSelect: (item: BudgetItemApi) => void,
  onClosed?: () => void
}

export const BudgetItemSelection = ({ className, items, autoOpen = false, onClosed, selectedItemId, onSelect } : BudgetItemSelectionProps) => {
  const onFilter = (item: BudgetItem<BudgetLabel>, query: string) => item.name.toLowerCase().indexOf(query) >= 0
  const renderDisplayItem = (item: BudgetItem<BudgetLabel>) => <BudgetItemModuleMini item={item}></BudgetItemModuleMini>
  const renderListItem = renderDisplayItem
  const renderNoSelection = () => <NoContent className='textNormal-75'>No budget item selected</NoContent>
  return <SelectionDropdown
    selectedId={selectedItemId}
    className={`.c-budget-item-selection ${className ?? ''}`}
    list={items}
    autoOpen={autoOpen}
    filterFun={onFilter}
    onSelect={item => onSelect(item!)}
    renderDisplayItem={renderDisplayItem}
    renderListItem={renderListItem}
    onClosed={onClosed}
    renderNoItemSelected={renderNoSelection}></SelectionDropdown>
}
