import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BudgetCategoryApi, BudgetItemApi, BudgetLabelApi } from '../api/domain'

export interface BudgetState {
    isLoading: boolean,
    categories: Array<BudgetCategoryApi>,
    items: Array<BudgetItemApi>,
    labels: Array<BudgetLabelApi>
}

const emptyState: BudgetState = {
  isLoading: false,
  categories: [],
  items: [],
  labels: []
}

const initialState: BudgetState = emptyState

export const budgetSlice = createSlice({
  name: 'budget',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, isLoading: action.payload }
    },
    setData: (state, action: PayloadAction<{categories: Array<BudgetCategoryApi>, items: Array<BudgetItemApi>, labels: Array<BudgetLabelApi>}>) => {
      return { ...state, categories: action.payload.categories, items: action.payload.items, labels: action.payload.labels }
    },
    clearData: (state) => {
      return { ...state, activeAccounts: [], allBudgetItems: [] }
    }
  }
})

export const { setData, clearData, setLoading } = budgetSlice.actions

export default budgetSlice.reducer
