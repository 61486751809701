import { FormElement } from '@benjaminpetry/bepe-design'
import { useEffect, useState } from 'react'
import { Transfer } from '../../../api/domain'
import { getTransferTypeIcon } from '../../../domain/transfer/TransferDisplay'
import { AccountService } from '../../../service/accountService'
import { BudgetService } from '../../../service/budgetService'
import './TransferDetails.scss'
import { Content } from '../../../components/display/Content'
import { TransferBankDetails } from './TransferBankDetails'
import { TransferTypeIcon } from '../../../domain/transfer/TransferTypeIcon'
import { AccountModuleMini } from '../../../domain/account/components/AccountModuleMini'
import { BudgetItemModuleMini } from '../../../domain/budget/components/item/BudgetItemModuleMini'
import { CurrencyDisplay } from '../../currency/CurrencyDisplay'
import { TabBar } from '../../../components/navigation/TabBar'

export interface TransferDetailsProps {
  className?: string,
  transfer: Transfer | undefined,
}

enum BankDetailState {
    NO_STATE = 'NoState',
    BANK = 'Bank Details',
    TRANSFER_BANK = 'Transfer Bank Details'
  }

const getDefaultBankDetailState = (t: Transfer | undefined) => {
  if (t !== undefined) {
    return t.bankDetails !== null ? BankDetailState.BANK : (t.transferBankDetails !== null ? BankDetailState.TRANSFER_BANK : BankDetailState.NO_STATE)
  }
  return BankDetailState.NO_STATE
}

const getBankDetailEntries = (t: Transfer | undefined) => {
  if (t !== undefined) {
    if (t.bankDetails !== null && t.transferBankDetails !== null) {
      return [BankDetailState.BANK, BankDetailState.TRANSFER_BANK]
    } else if (t.bankDetails !== null) {
      return [BankDetailState.BANK]
    } else if (t.transferBankDetails !== null) {
      return [BankDetailState.TRANSFER_BANK]
    }
  }
  return []
}

export const TransferDetails = ({ className = '', transfer }: TransferDetailsProps) => {
  const [displayState, setDisplayState] = useState<BankDetailState>(getDefaultBankDetailState(transfer))

  useEffect(() => {
    setDisplayState(getDefaultBankDetailState(transfer))
  }, [transfer])

  return (<div className={`c-transfer-details ${className}`}>
    <div className='c-transfer-details__row-1'>
        <div className='c-transfer-details__icon-date'>
            <TransferTypeIcon icon={getTransferTypeIcon(transfer?.type)} type={transfer?.type}></TransferTypeIcon>
            <FormElement label='Date' className='c-transfer-details__date'>
                <Content>{transfer && transfer.date.toLocaleDateString()}</Content>
            </FormElement>
        </div>
        <FormElement label='Value'className='c-transfer-details__value'>
            <CurrencyDisplay highlightNegative={true} highlightPositive={true} value={transfer?.value}></CurrencyDisplay>
        </FormElement>
    </div>
    <FormElement label='Description' className='c-transfer-details__description'>
      <Content>{transfer?.description}</Content>
    </FormElement>
    <div className='c-transfer-details__accounts'>
        <FormElement label='Account'>
            <Content>{transfer && <AccountModuleMini account={AccountService.getAccountById(transfer.accountId)!}></AccountModuleMini>}</Content>
        </FormElement>
        {(transfer && transfer.transferAccountId !== null) &&
        <FormElement label='Transfer Account'>
            <AccountModuleMini account={AccountService.getAccountById(transfer.transferAccountId!)!}></AccountModuleMini>
        </FormElement>}
    </div>
    <FormElement label='Budget Items' className='c-transfer-details__budget-items'>
        <Content emptyMessage='No Budget Items selected'>{transfer && transfer.budgetItems.map(item => {
          const realItem = BudgetService.getBudgetItemById(item.itemId)
          const label = (item.labelId && BudgetService.getBudgetLabelById(item.labelId)) || null
          return realItem && <BudgetItemModuleMini key={item.id} item={realItem} budgetLabel={label} value={item.value}></BudgetItemModuleMini>
        })
        }</Content>
    </FormElement>
    <FormElement label='Comment' className='c-transfer-details__comment'>
        <Content emptyMessage='No comment entered'>{transfer?.comment}</Content>
    </FormElement>
    {displayState !== BankDetailState.NO_STATE && <TabBar activeTabId={displayState} entries={getBankDetailEntries(transfer)} onActiveChanged={item => setDisplayState(item as BankDetailState)}></TabBar>}
    {(transfer && transfer.bankDetails !== null && displayState === BankDetailState.BANK) && <TransferBankDetails bankDetails={transfer.bankDetails}></TransferBankDetails>}
    {(transfer && transfer.transferBankDetails !== null && displayState === BankDetailState.TRANSFER_BANK) && <TransferBankDetails bankDetails={transfer.transferBankDetails}></TransferBankDetails>}
  </div>)
}
