import { Spinner } from '@benjaminpetry/bepe-design'

import './TransferList.scss'
import { Account, BudgetItem, BudgetLabel, Transfer } from '../../../api/domain'
import { TransferModule } from './TransferModule'
import { formatMonth } from '../../../components/display/DateDisplay'
import { NoContent } from '../../../components/display/NoContent'
import { getTransfersByMonth } from '../../../domain/transfer/TransferLogic'

export interface TransferListProps {
    transfers: Array<Transfer>;
    accountMap: Map<number, Account>,
    budgetItemMap: Map<number, BudgetItem<BudgetLabel>>,
    selectedTransferId: number | null;
    tailerToBudgetItemId?: number;
    busy?: boolean;
    onSelectionChanged: (account: Transfer | null) => void;
}

export const TransferList = ({ transfers, accountMap, budgetItemMap, tailerToBudgetItemId, selectedTransferId, busy, onSelectionChanged } : TransferListProps) => {
  const renderSublist = (month: number, transfers: Array<Transfer>) => {
    return <div key={month} className={'c-transfer-list__sublist'}>
      {<h4 className='c-transfer-list__sublist-title'>{formatMonth(month)}</h4>}
      <div className='c-transfer-list__sublist-content'>
        {transfers.map(transfer => {
          return <TransferModule key={transfer.id} tailerToBudgetItemId={tailerToBudgetItemId} transfer={transfer} accountMap={accountMap} budgetItemMap={budgetItemMap} selected={transfer.id === selectedTransferId} onClick={() => onSelectionChanged(transfer.id === selectedTransferId ? null : transfer)}></TransferModule>
        })}
      </div>
    </div>
  }

  const transfersByMonth = getTransfersByMonth(transfers)

  return (<div className="c-transfer-list">
      <div className="c-transfer-list__content">
        {busy && <Spinner className='c-transfer-list__loading'></Spinner>}
        {!busy && transfersByMonth.length === 0 && <NoContent>You have no transfers to show here.</NoContent>}
        {!busy && transfersByMonth.map(({ month, transfers }) => renderSublist(month, transfers))}
      </div>
    </div>)
}
