import { BankStatementRow } from '../../../domain/import/ImportLogic'
import { Account, AccountBalance, AccountType } from '../../domain'
import { ImportTransfer } from '../../domain/import'
import http, { createHeaders } from '../../http/'
import { apiUrl } from '../interface'
import { TransferSearchResult } from '../transfer/interface'

const serviceUrl = `${apiUrl}/accounts`

const AccountServiceInstance = {
  async create (name: string, type: AccountType): Promise<Account> {
    return http.post(`${serviceUrl}`, await createHeaders(), { name, type })
  },

  async findAll (): Promise<Array<Account>> {
    return http.get(`${serviceUrl}`, await createHeaders())
  },

  async findAllTransfers (accountId: number, from: Date, to: Date, page: number, pageSize: number, query: string | null): Promise<TransferSearchResult> {
    return http.get(`${serviceUrl}/${accountId}/transfers?from=${from.getTime()}&to=${to.getTime()}&page=${page}&pageSize=${pageSize}${query ? `&query=${encodeURIComponent(query)}` : ''}`, await createHeaders())
  },

  async getBalances (accountIds: Array<number>, from: Date, to: Date, timespan: 'month' | 'year'): Promise<Array<{accountId: number, balance: AccountBalance}>> {
    return http.get(`${serviceUrl}/balances?from=${from.getTime()}&to=${to.getTime()}&timespan=${timespan}&accountIds=${accountIds.join(',')}`, await createHeaders())
  },

  async update (account: Account): Promise<Account> {
    return http.put(`${serviceUrl}/${account.id}`, await createHeaders(), { name: account.name, type: account.type })
  },

  async updateStartBalance (accountId: number, year: number, balance: number): Promise<{profileId:number, accountId: number, balance: number, year: number}> {
    return http.put(`${serviceUrl}/${accountId}/start-balance/${year}`, await createHeaders(), { balance })
  },

  async prepareImport (accountId: number, statement: Array<BankStatementRow>): Promise<Array<ImportTransfer>> {
    return http.post(`${serviceUrl}/${accountId}/import/prepare`, await createHeaders(), { statement: statement.map(row => ({ ...row, date: row.date.getTime() })) })
  }
}

export default AccountServiceInstance
