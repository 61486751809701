import { Spinner } from '@benjaminpetry/bepe-design'

import './BudgetList.scss'
import { BudgetCategoryViewElement, BudgetItemViewElement, getAllCategoryTypes } from '../../../domain/budget/BudgetDisplay'
import { BudgetCategoryType } from '../../../api/domain'
import { BudgetCategoryList } from './category/BudgetCategoryList'
import { NoContent } from '../../../components/display/NoContent'

export interface BudgetListProps {
    budgetCategories: Array<BudgetCategoryViewElement>;
    currentYear: number;
    selectedBudgetItemId: number | null;
    busy?: boolean;
    onBudgetItemChange: (budgetItem: BudgetItemViewElement | null) => void;
}

export const BudgetList = ({ budgetCategories, selectedBudgetItemId, currentYear, busy, onBudgetItemChange } : BudgetListProps) => {
  const categories = budgetCategories.filter(cat => cat.relevantFromYear <= currentYear && (cat.relevantToYear === null || cat.relevantToYear >= currentYear))

  const renderCategoriesByType = (type: BudgetCategoryType) => {
    return categories.filter(cat => cat.type === type).map(category => <BudgetCategoryList
    key={category.id}
    category={category}
    currentYear={currentYear}
    onBudgetItemChange={onBudgetItemChange}
    selectedBudgetItemId={selectedBudgetItemId}
    ></BudgetCategoryList>)
  }

  return (<div className="c-budget-list">
      <div className="c-budget-list__content">
        <div className="c-budget-list__scroll-container">
        {busy && <Spinner className='c-budget-list__loading'></Spinner>}
        {!busy && categories.length === 0 && <NoContent>You have no budget categories to display here.</NoContent>}
        {!busy && getAllCategoryTypes().flatMap(renderCategoriesByType)}
        </div>
      </div>
    </div>)
}
