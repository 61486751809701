import { Transfer } from '../../domain'
import http, { createHeaders } from '../../http'
import { apiUrl } from '../interface'
import { TransferSearchResult } from './interface'

const serviceUrl = `${apiUrl}/transfers`

const TransferServiceInstance = {

  async findAll (from: Date, to: Date, page: number, pageSize: number, query: string | null): Promise<TransferSearchResult> {
    return http.get(`${serviceUrl}/?from=${from.getTime()}&to=${to.getTime()}&page=${page}&pageSize=${pageSize}${query ? `&query=${encodeURIComponent(query)}` : ''}`, await createHeaders())
  },

  async create (transfer: Transfer): Promise<Transfer> {
    const copy = {
      date: transfer.date.getTime(),
      description: transfer.description,
      value: transfer.value,
      accountId: transfer.accountId,
      transferAccountId: transfer.transferAccountId,
      comment: transfer.comment,
      bankDetails: transfer.bankDetails,
      transferBankDetails: transfer.transferAccountId === null ? null : transfer.transferBankDetails,
      budgetItems: transfer.budgetItems.map(item => ({ id: item.id, itemId: item.itemId, labelId: item.labelId, value: item.value }))
    }
    return http.post(`${serviceUrl}/`, await createHeaders(), copy)
  },

  async update (transfer: Transfer): Promise<Transfer> {
    const copy = {
      date: transfer.date.getTime(),
      description: transfer.description,
      value: transfer.value,
      accountId: transfer.accountId,
      transferAccountId: transfer.transferAccountId,
      comment: transfer.comment,
      inverted: transfer.inverted,
      bankDetails: transfer.bankDetails,
      transferBankDetails: transfer.transferAccountId === null ? null : transfer.transferBankDetails,
      budgetItems: transfer.budgetItems.map(item => ({ id: item.id, itemId: item.itemId, labelId: item.labelId, value: item.value }))
    }
    return http.put(`${serviceUrl}/${transfer.id}`, await createHeaders(), copy)
  },

  async delete (transfer: Transfer): Promise<void> {
    return http.delete(`${serviceUrl}/${transfer.id}`, await createHeaders())
  }

}

export default TransferServiceInstance
