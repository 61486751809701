import { useState } from 'react'
import api from '../../../api'
import { Transfer } from '../../../api/domain'
import { ImportTransfer } from '../../../api/domain/import'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { NotificationService } from '../../../service/notificationService'
import { parseApiTransfer } from '../../transfer/TransferLogic'
import { parseBankStatement, ParseSettings } from '../ImportLogic'

const NOTIFICATION_SOURCE = 'import'

interface useImportProps {
  accountId: number,
  onAfterSaving?: () => Promise<void>,
  onAfterSaved?: () => void,
}

type ImportReturn = [Array<ImportTransfer> | null, boolean, boolean, (file: File, settings: ParseSettings) => Promise<Array<ImportTransfer> | undefined>, (transfer: Array<Transfer>) => Promise<Array<Transfer>>]

export const useImport = ({ accountId, onAfterSaving, onAfterSaved }: useImportProps): ImportReturn => {
  const [busyLoading, setBusyLoading] = useState<boolean>(false)
  const [busySaving, setBusySaving] = useState<boolean>(false)
  const [preparedTransfers, setPreparedTransfers] = useState<Array<ImportTransfer>|null>(null)
  const isMounted = useIsMounted()

  const prepare = async (file: File, settings: ParseSettings) => {
    NotificationService.clear(NOTIFICATION_SOURCE)
    setBusyLoading(true)
    try {
      const statement = await parseBankStatement(file, settings)
      const transfers = await api.accountService.prepareImport(accountId, statement)
      const correctedTransfers = transfers.map(t => ({ ...t, transfer: parseApiTransfer(t.transfer), collisions: t.collisions.map(c => ({ ...c, transfer: parseApiTransfer(c.transfer) })) }))
      if (isMounted.current) {
        setPreparedTransfers(correctedTransfers)
        setBusyLoading(false)
      }
      return correctedTransfers
    } catch (e) {
      if (isMounted.current) {
        setBusyLoading(false)
      }
      NotificationService.raiseError(NOTIFICATION_SOURCE, 'Import preparation failed. Please check your internet connection.')
    }
  }

  const save = async (transfers: Array<Transfer>): Promise<Array<Transfer>> => {
    NotificationService.clear(NOTIFICATION_SOURCE)
    setBusySaving(true)
    try {
      const updatedTransfers = await Promise.all(transfers.map(t => {
        return (t.id > 0) ? api.transferService.update(t) : api.transferService.create(t)
      }))
      if (onAfterSaving) { await onAfterSaving() }
      NotificationService.raiseSuccess(NOTIFICATION_SOURCE, 'Transfers have been saved')
      if (onAfterSaved) { await onAfterSaved() }
      if (isMounted.current) {
        setBusySaving(false)
      }
      return updatedTransfers
    } catch (e) {
      if (isMounted.current) {
        setBusySaving(false)
      }
      NotificationService.raiseError(NOTIFICATION_SOURCE, 'Transfers could not been saved. Please check your internet connection.')
      throw Error('TODO: Handle Error properly')
    }
  }

  return [preparedTransfers, busyLoading, busySaving, prepare, save]
}
