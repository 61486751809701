import { Button, FormElement } from '@benjaminpetry/bepe-design'
import { MouseEventHandler, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState, store } from '../../../store'
import { DateSelectionLevel, nextMonth, nextYear, previousMonth, previousYear, setSelectionLevel, today } from '../../../store/dateStore'
import { Picker } from '../../input/Picker'
import './DateSelector.scss'

export interface DateSelectorProps {
  className?: string
}

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const DateSelector = ({ className } : DateSelectorProps) => {
  const dateSelection = useSelector((state: RootState) => state.dateSelection)
  const [isOpen, setOpen] = useState<boolean>(false)

  const renderMonthPicker = () => {
    const label = monthNames[dateSelection.month]
    const onNext = () => { store.dispatch(nextMonth()) }
    const onPrevious = () => { store.dispatch(previousMonth()) }
    return <Picker className={'c-date-selector__month'} onPrevious={onPrevious} onNext={onNext}>{label}</Picker>
  }

  const renderYearPicker = () => {
    const label = dateSelection.year + ''
    const onNext = () => { store.dispatch(nextYear()) }
    const onPrevious = () => { store.dispatch(previousYear()) }
    return <Picker className={'c-date-selector__year'} onPrevious={onPrevious} onNext={onNext}>{label}</Picker>
  }

  const onOutsideClick: MouseEventHandler<HTMLDivElement> = evt => {
    setOpen(false)
    evt.preventDefault()
    evt.stopPropagation()
  }

  return <div className={`c-date-selector c-date-selector--${isOpen ? 'open' : 'closed'} ${className ?? ''}`}>
    {<Button className={'c-date-selector__label'} onClick={(evt) => { setOpen(value => !value); evt.preventDefault(); evt.stopPropagation() }}>
      {dateSelection.selectionLevel === DateSelectionLevel.MONTH ? `Month: ${monthNames[dateSelection.month]} '${(dateSelection.year + '').substring(2)}` : `Year: ${dateSelection.year}`}
      </Button>}
      <div className='c-date-selector__container' onClick={evt => { evt.preventDefault(); evt.stopPropagation() }}>
        <FormElement className='c-date-selector__kind' label="Timespan">
          <Button className={`c-date-selector__month-selector c-date-selector__month-selector--${dateSelection.selectionLevel === DateSelectionLevel.MONTH ? 'selected' : 'unselected'}`} onClick={() => store.dispatch(setSelectionLevel(DateSelectionLevel.MONTH))}>Monthly</Button>
          <Button className={`c-date-selector__year-selector c-date-selector__year-selector--${dateSelection.selectionLevel === DateSelectionLevel.YEAR ? 'selected' : 'unselected'}`} onClick={() => store.dispatch(setSelectionLevel(DateSelectionLevel.YEAR))}>Yearly</Button>
        </FormElement>
        <FormElement className='c-date-selector__date' label="Date">
          {dateSelection.selectionLevel === DateSelectionLevel.MONTH && renderMonthPicker()}
          {renderYearPicker()}
          <Button className={`c-date-selector__today c-date-selector__today--${dateSelection.isToday ? 'selected' : 'unselected'}`} onClick={() => { store.dispatch(today()) }}>Today</Button>
        </FormElement>
      </div>
      <div className='c-date-selector__close-layer' onClick={onOutsideClick}></div>
  </div>
}
