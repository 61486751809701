import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { getFromDate, getToDate } from '../../../components/display/DateDisplay'
import { useIsMounted } from '../../../hooks/useIsMounted'
import api from '../../../api'
import { NotificationService } from '../../../service/notificationService'
import { BudgetItem, BudgetItemBalances, BudgetLabel, BudgetLabelBalances } from '../../../api/domain'
import { DateSelectionLevel } from '../../../store/dateStore'

const NOTIFICATION_SOURCE = 'budget-balance-load'

type BudgetBalanceReturn = [BudgetItemBalances, BudgetLabelBalances, boolean, () => Promise<void>]

export const useBudgetBalance = <E extends BudgetLabel, T extends BudgetItem<E>>(items: Array<T>, labels: Array<E>): BudgetBalanceReturn => {
  const [busy, setBusy] = useState<boolean>(false)
  const [itemResult, setItemResult] = useState<BudgetItemBalances>(new Map())
  const [labelResult, setLabelResult] = useState<BudgetLabelBalances>(new Map())
  const dateSelection = useSelector((state: RootState) => state.dateSelection)
  const profileState = useSelector((state: RootState) => state.activeProfile)
  const isMounted = useIsMounted()
  const itemIds = items.map(item => item.id)
  const labelIds = labels.map(label => label.id)

  const load = async () => {
    NotificationService.clear(NOTIFICATION_SOURCE)
    setBusy(true)
    try {
      const result = itemIds.length === 0 ? { items: [], labels: [] } : await api.budgetService.getBalances(itemIds, labelIds, getFromDate(dateSelection), getToDate(dateSelection), dateSelection.selectionLevel === DateSelectionLevel.MONTH ? 'month' : 'year')
      const itemMap = new Map(result.items.map(res => [res.itemId, res.balance]))
      const labelMap = new Map(result.labels.map(res => [res.labelId, res.balance]))
      if (isMounted.current) {
        setItemResult(itemMap)
        setLabelResult(labelMap)
      }
    } catch (e) {
      NotificationService.raiseError(NOTIFICATION_SOURCE, 'Loading Budget balance failed. Please try again later.')
    } finally {
      if (isMounted.current) {
        setBusy(false)
      }
    }
  }

  useEffect(() => {
    if (profileState.active && !profileState.isLoading) {
      load()
    }
  }, [profileState.active, profileState.isLoading, dateSelection, items, labels])

  return [itemResult, labelResult, busy, load]
}
