import { Textfield, Button, Icons, Icon } from '@benjaminpetry/bepe-design'
import { KeyboardEvent, MouseEventHandler, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { IconButton } from '../IconButton'
import { SelectionItem } from './SelectionItem'
import './SelectionDropdown.scss'

export interface SelectionDropdownProps<T extends {id: number}> {
    selectedId: number | null,
    allowDeselect?: boolean,
    list: Array<T>,
    className?: string,
    autoOpen?: boolean,
    filterFun: (item: T, query: string) => boolean,
    renderListItem: (item: T, selected: boolean) => ReactNode | Array<ReactNode>,
    renderDisplayItem: (item: T) => ReactNode | Array<ReactNode>,
    renderNoItemSelected: () => ReactNode | Array<ReactNode>,
    onSelect: (item: T | null) => void,
    onClosed?: () => void
}

export const SelectionDropdown = <T extends {id: number}>({ selectedId, onClosed, autoOpen = false, allowDeselect = false, list, className, filterFun, renderListItem, renderDisplayItem, renderNoItemSelected, onSelect }: SelectionDropdownProps<T>) => {
  const [query, setQuery] = useState<string>('')
  const [items, setItems] = useState<Array<T>>([...list])
  const [itemsToShow, setItemsToShow] = useState<Array<T>>([...list])
  const [open, setOpen] = useState<boolean>(autoOpen)
  const [showAll, setShowAll] = useState<boolean>(false)
  const textfieldRef = useRef<HTMLInputElement>(null)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  useEffect(() => {
    const queryLower = query.toLowerCase()
    const filteredItems = list.filter(item => filterFun(item, queryLower))
    setItems(filteredItems)
  }, [list, query])

  useEffect(() => {
    const itemsToShow = showAll ? items : items.slice(0, 100)
    setItemsToShow(itemsToShow)
  }, [items, showAll])

  const focus = () => {
    window.setTimeout(() => {
      textfieldRef.current?.focus()
    }, 50)
  }

  useEffect(() => {
    if (autoOpen) {
      focus()
    }
  }, [])

  useEffect(() => {
    if (autoOpen && !open) {
      setOpen(true)
      focus()
    }
  }, [autoOpen])

  const onClose = useCallback(() => {
    setOpen(false)
    setQuery('')
    setShowAll(false)
    setSelectedIndex(0)
    if (onClosed) { onClosed() }
  }, [])

  const onOpen = () => {
    setOpen(true)
    window.setTimeout(() => {
      textfieldRef.current?.focus()
    }, 50)
  }

  const onOutsideClick: MouseEventHandler<HTMLDivElement> = evt => {
    onClose()
    evt.preventDefault()
    evt.stopPropagation()
  }

  const toggleState: MouseEventHandler<HTMLButtonElement> = (evt) => {
    if (open) {
      onClose()
    } else {
      onOpen()
    }
    evt.stopPropagation()
  }

  const onSelectionAndClosed = (item: T | null) => {
    onSelect(item)
    onClose()
  }

  const onEnter = () => {
    if (allowDeselect && selectedIndex === -1) {
      onSelectionAndClosed(null)
    } else if (itemsToShow.length > selectedIndex) {
      onSelectionAndClosed(itemsToShow[selectedIndex])
    }
  }

  const onKeyDown = (evt: KeyboardEvent<HTMLDivElement>) => {
    if (open) {
      if (evt.key === 'Escape') {
        onClose()
        evt.stopPropagation()
        evt.preventDefault()
      } else if (evt.key === 'ArrowDown') {
        setSelectedIndex(Math.min(itemsToShow.length - 1, selectedIndex + 1))
      } else if (evt.key === 'ArrowUp') {
        setSelectedIndex(Math.max(allowDeselect ? -1 : 0, selectedIndex - 1))
      }
    }
  }

  const onQueryChanged = (query: string) => {
    setQuery(query)
    setSelectedIndex(0)
  }

  const selectedItem = list.find(item => item.id === selectedId)

  return <div className={`c-selection-dropdown c-selection-dropdown--${open ? 'open' : 'closed'} ${className ?? ''}`}>
        <SelectionItem className='c-selection-list__button' onClick={toggleState}>
          {selectedItem ? renderDisplayItem(selectedItem) : renderNoItemSelected()}
        </SelectionItem>
        <div onClick={(evt) => { if (open) { evt.stopPropagation() } }} className={`c-selection-dropdown__container c-selection-dropdown__container--${open ? 'open' : 'closed'}`}>
            <div className="c-selection-dropdown__search-container">
              <Textfield ref={textfieldRef} label="Search" onEnter={onEnter} value={query} onChange={(evt) => onQueryChanged(evt.target.value)} onKeyDown={onKeyDown}></Textfield>
              <IconButton className='c-mobile-only' icon={Icons.Xmark} onClick={onClose}></IconButton>
            </div>
            <hr></hr>
            <div className="c-selection-dropdown__content">
              {allowDeselect && !!selectedId && <SelectionItem preSelected={selectedIndex === -1} className="c-selection-dropdown__delesect-button textNormal-75" onClick={() => onSelectionAndClosed(null)}><Icon icon={Icons.Eraser}></Icon>Remove Selection</SelectionItem>}
              {
                itemsToShow.map((item, index) => {
                  return <SelectionItem key={item.id} selected={selectedId === item.id} className={'c-selection-list__item'} preSelected={index === selectedIndex} onClick={() => onSelectionAndClosed(item)}>
                    {renderListItem(item, selectedId === item.id)}
                  </SelectionItem>
                })
              }
                {!showAll && items.length > 100 && <Button classtype='secondary' onClick={() => setShowAll(true)}>Show all</Button>}
            </div>
        </div>
        <div className='c-selection-dropdown__close-layer' onClick={onOutsideClick}></div>
    </div>
}
