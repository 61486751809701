const add = (arr1: Array<number>, arr2: Array<number>): Array<number> => {
  const finalLength = Math.max(arr1.length, arr2.length)
  return [...Array(finalLength).keys()].map((_, index) => (index < arr1.length ? arr1[index] : 0) + (index < arr2.length ? arr2[index] : 0))
}

const sum = (arr: Array<number>): number => {
  return arr.reduce((sum, v) => sum + v, 0)
}

const min = (arr: Array<number>): number => {
  return arr.reduce((min, v) => Math.min(min, v), Number.MAX_VALUE)
}

const max = (arr: Array<number>): number => {
  return arr.reduce((max, v) => Math.max(max, v), Number.MIN_VALUE)
}

const negate = (arr: Array<number>): Array<number> => {
  return arr.map(v => v * -1)
}

const sumOfArrays = (arr: Array<Array<number>>) => arr.reduce(add, [])

export const ArrayUtil = {
  add,
  sum,
  min,
  max,
  negate,
  sumOfArrays
}
