import { BudgetCategoryApi, BudgetCategoryType, BudgetItemApi, BudgetItemBalance, BudgetLabelApi, BudgetLabelBalance } from '../../domain'
import { BudgetPlan } from '../../domain/budgetplan'
import http, { createHeaders } from '../../http'
import { apiUrl } from '../interface'
import { TransferSearchResult } from '../transfer/interface'

const serviceUrl = `${apiUrl}/budget`
// const serviceCategoryUrl = `${serviceUrl}/categories`
const serviceItemUrl = `${serviceUrl}/items`
// const serviceLabelUrl = `${serviceUrl}/labels`

// createCategory: (name: string) => Promise<BudgetCategory>
//     createItem: (name: string) => Promise<BudgetItem>
//     createLabel: (name: string) => Promise<BudgetLabelApi>

const transformCategory = (category: BudgetCategoryApi) => ({ ...category, items: [] })
const transformItem = (categoryTypeMap: Map<number, BudgetCategoryType>) => (item: BudgetItemApi) => ({ ...item, labels: [], type: categoryTypeMap.get(item.categoryId) ?? BudgetCategoryType.Income })
const transformLabel = (itemTypeMap: Map<number, BudgetCategoryType>) => (label: BudgetLabelApi) => ({ ...label, type: itemTypeMap.get(label.itemId) ?? BudgetCategoryType.Income })

function linkBudgetEntites (categories: Array<BudgetCategoryApi>, items: Array<BudgetItemApi>, labels: Array<BudgetLabelApi>): [Array<BudgetCategoryApi>, Array<BudgetItemApi>, Array<BudgetLabelApi>] {
  const realCategories = categories.map(transformCategory)
  const categoryTypeMap = new Map<number, BudgetCategoryType>(realCategories.map(cat => ([cat.id, cat.type])))
  const realItems = items.map(transformItem(categoryTypeMap))
  const itemTypeMap = new Map<number, BudgetCategoryType>(realItems.map(item => ([item.id, item.type])))
  const realLabels = labels.map(transformLabel(itemTypeMap))
  const categoryMap = new Map<number, BudgetCategoryApi>(realCategories.map(cat => [cat.id, cat]))
  realItems.forEach(item => {
    const category = categoryMap.get(item.categoryId)
    if (category) {
      category.items.push(item)
    }
  })

  const itemMap = new Map<number, BudgetItemApi>(realItems.map(it => [it.id, it]))
  realLabels.forEach(label => {
    const item = itemMap.get(label.itemId)
    if (item) {
      item.labels.push(label)
    }
  })
  return [realCategories, realItems, realLabels]
}

const BudgetServiceInstance = {
  async findAll (): Promise<[Array<BudgetCategoryApi>, Array<BudgetItemApi>, Array<BudgetLabelApi>]> {
    const result: {categories: Array<BudgetCategoryApi>, items: Array<BudgetItemApi>, labels: Array<BudgetLabelApi>} = await http.get(`${serviceUrl}`, await createHeaders())
    const { categories, items, labels } = result
    return linkBudgetEntites(categories, items, labels)
  },

  async findAllTransfers (budgetItemId: number, from: Date, to: Date, page: number, pageSize: number, query: string | null): Promise<TransferSearchResult> {
    return http.get(`${serviceItemUrl}/${budgetItemId}/transfers?from=${from.getTime()}&to=${to.getTime()}&page=${page}&pageSize=${pageSize}${query ? `&query=${encodeURIComponent(query)}` : ''}`, await createHeaders())
  },

  async getBalances (itemIds: Array<number>, labelIds: Array<number>, from: Date, to: Date, timespan: 'month' | 'year'): Promise<{items: Array<{itemId: number, balance: BudgetItemBalance}>, labels: Array<{labelId: number, balance: BudgetLabelBalance}>}> {
    return http.get(`${serviceUrl}/balances?from=${from.getTime()}&to=${to.getTime()}&timespan=${timespan}&itemIds=${itemIds.join(',')}&labelIds=${labelIds.join(',')}`, await createHeaders())
  },

  async getMonthlyBalances (year: number, itemIds: Array<number>): Promise<Array<{itemId: number, balances: Array<BudgetItemBalance>}>> {
    return http.get(`${serviceUrl}/monthly-balances?year=${year}&itemIds=${itemIds.join(',')}`, await createHeaders())
  },

  async updatePlan (plan: BudgetPlan, year: number): Promise<{updatedPlan: BudgetPlan, otherUpdatedPlans: Array<BudgetPlan>}> {
    return http.put(`${serviceItemUrl}/${plan.itemId}/budgetplan/${year}`, await createHeaders(), {
      savingMode: plan.savingMode,
      savingType: plan.savingType,
      progressGranularity: plan.progressGranularity,
      planned: plan.planned,
      savingsStart: plan.savingsStart,
      savings: plan.savings
    })
  }
}

export default BudgetServiceInstance
