import { Icon, Icons, Signal } from '@benjaminpetry/bepe-design'

import { Notification } from '../../api/domain/notification'
import { NotificationService } from '../../service/notificationService'
import './NotificationTile.scss'

export interface NotificationTileProps {
    notification: Notification
}

const classifyIcon = (signal: Signal) => {
  switch (signal) {
    case Signal.DANGER:
      return Icons.TriangleExclamation
    case Signal.CONFIRM:
      return Icons.Check
    case Signal.INFO:
      return Icons.Info
    case Signal.WARNING:
      return Icons.Exclamation
  }
}

export const NotificationTile = ({ notification }: NotificationTileProps) => {
  return <div className={`c-notification-tile interactive c-notification-tile--${notification.signal} c-notification-tile--${notification.isClosing ? 'closing' : 'open'}`} onClick={() => NotificationService.remove(notification.id)}>
    <Icon className='c-notification-tile__icon' icon={classifyIcon(notification.signal)}></Icon>
    {notification.title && <p className='c-notification-tile__title textBold-100'>{notification.title}</p>}
    <p className='c-notification-tile__message textNormal-100'>{notification.message}</p>
  </div>
}
