import { FormElement, Icons } from '@benjaminpetry/bepe-design'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { BudgetCategoryType, Transfer } from '../../../../api/domain'
import { TransferSearchResult } from '../../../../api/services/transfer/interface'
import { Content } from '../../../../components/display/Content'
import { ColorScheme, ProgressDisplay } from '../../../../components/display/ProgressDisplay'
import { TabBar } from '../../../../components/navigation/TabBar'
import { RootState } from '../../../../store'
import { DateSelectionLevel } from '../../../../store/dateStore'
import { TransferListViewModule } from '../../../../views/Transfers/TransferListViewModule'
import { CurrencyDisplay } from '../../../currency/CurrencyDisplay'
import { SearchOptions } from '../../../transfer/hooks/useTransferSearch'
import { BudgetItemViewElement, createOtherLabel } from '../../BudgetDisplay'
import { BudgetTypeIcon } from '../../BudgetTypeIcon'
import { BudgetLabelModule } from '../label/BudgetLabelModule'
import './BudgetItemDetails.scss'

export interface BudgetItemDetailsProps {
  className?: string,
  item?: BudgetItemViewElement,
  selectedTransferId: number | null,
  onTransferSelectionChanged: (transfer: Transfer | null) => void,
  busy: boolean,
  searchResult: TransferSearchResult;
  searchOptions: SearchOptions;
  onUpdateSearchOptions: (options: SearchOptions) => void;
}

enum DisplayState {
  LABELS = 'Labels',
  TRANSFERS = 'Transfers'
}

export const BudgetItemDetails = ({ className = '', item, selectedTransferId, onTransferSelectionChanged, busy, searchResult, searchOptions, onUpdateSearchOptions }: BudgetItemDetailsProps) => {
  const dateSelection = useSelector((state: RootState) => state.dateSelection)
  const [displayState, setDisplayState] = useState<DisplayState>(DisplayState.LABELS)
  const sumAllLabels = item?.labels.reduce((sum, el) => sum + (el.balance?.total ?? 0), 0) ?? 0
  const otherLabel = item === undefined || item.labels.length === 0 ? undefined : createOtherLabel(item.id, item.type, (item?.balance?.actual ?? 0) - sumAllLabels)

  return (<div className={`c-budget-item-details ${className}`}>
    <div className='c-budget-item-details__container'>
      <div className='c-budget-item-details__header'>
        <BudgetTypeIcon className='c-budget-item-details__icon' icon={item?.icon ?? Icons.Spinner} type={item?.type}></BudgetTypeIcon>
        <div className='c-budget-item-details__progress-transactions'>
          <FormElement className='c-budget-item-details__progress-element' label="Progress">
            <ProgressDisplay progress={item?.balance?.progress.actual} expectedProgress={item?.balance?.progress.target} colorScheme={item?.type === BudgetCategoryType.Income ? ColorScheme.INCOME : ColorScheme.EXPENSES}></ProgressDisplay>
          </FormElement>
          <FormElement className='c-budget-item-details__current-transactions' label="Actual">
            <CurrencyDisplay highlightNegative={true} highlightPositive={true} value={item?.balance?.actual}></CurrencyDisplay>
          </FormElement>
        </div>
      </div>
      <div className='c-budget-item-details__planned'>
        <FormElement label="Target" className='c-budget-item-details__target'>
          <CurrencyDisplay highlightNegative={true} highlightPositive={true} value={item?.balance?.target}></CurrencyDisplay>
        </FormElement>
        {dateSelection.selectionLevel === DateSelectionLevel.MONTH && <FormElement className='c-budget-item-details__actual-target-last-month' label="Last Target/Actual">
          <CurrencyDisplay highlightNegative={true} highlightPositive={true} value={item?.balance?.targetActualPreviousMonth}></CurrencyDisplay>
        </FormElement>}
        <FormElement className='c-budget-item-details__planned-transactions' label="Target/Actual">
          <CurrencyDisplay highlightNegative={true} highlightPositive={true} value={item?.balance?.targetActualComparison}></CurrencyDisplay>
        </FormElement>
      </div>
      <TabBar activeTabId={displayState} entries={[DisplayState.LABELS, DisplayState.TRANSFERS]} onActiveChanged={item => setDisplayState(item as DisplayState)}></TabBar>
      {displayState === DisplayState.LABELS && <Content className='c-budget-item-details__label-content' emptyMessage='This budget item has no labels.'>{item?.labels.map(label => {
        return <BudgetLabelModule className='c-budget-item-details__label' forceShowValue={true} key={label.id} budgetLabel={label} value={label.balance?.total}></BudgetLabelModule>
      })}
      {otherLabel && otherLabel.balance?.total !== 0 && <BudgetLabelModule className='c-budget-item-details__label c-budget-item-details__label--other' forceShowValue={true} key={'other'} budgetLabel={otherLabel} value={otherLabel.balance?.total}></BudgetLabelModule>}
      </Content>
      }
      {displayState === DisplayState.TRANSFERS && <TransferListViewModule
      onSelectionChanged={onTransferSelectionChanged}
      onUpdateSearchOptions={onUpdateSearchOptions}
      tailerToBudgetItemId={item?.id}
      searchOptions={searchOptions}
      busy={busy}
      searchResult={searchResult}
      selectedTransferId={selectedTransferId}
      ></TransferListViewModule>
      }
    </div>
  </div>)
}
