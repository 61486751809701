
import { Content } from '../../components/display/Content'
import { useSecretMode } from '../../hooks/useSecretMode'
import './CurrencyDisplay.scss'
import { CurrencyFormatter } from './CurrencyFormatter'

export interface CurrencyDisplayProps {
  className?: string,
  value: number | undefined,
  prefix?: string,
  suffix?: string,
  highlightNegative?: boolean,
  highlightPositive?: boolean,
}

export const CurrencyDisplay = ({ className, value, prefix, suffix, highlightNegative = false, highlightPositive = false } : CurrencyDisplayProps) => {
  const [secretMode] = useSecretMode()
  return <Content className={`c-currency-display c-currency-display--${secretMode && value !== undefined ? 'secret' : 'no-secret'} ${value !== undefined && highlightNegative && value < 0 ? 'c-currency-display--negative' : ''} ${value !== undefined && highlightPositive && value > 0 ? 'c-currency-display--positive' : ''} ${className ?? ''}`}>
    {value !== undefined ? [prefix, CurrencyFormatter.formatNumber(value, 'EUR'), suffix] : undefined}
    </Content>
}
