import { ButtonHTMLAttributes } from 'react'
import './SelectionItem.scss'

export interface SelectionItemProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    selected?: boolean,
    preSelected?: boolean,
    borderStyle?: 'solid' | 'dashed',
    className?: string,
}

export const SelectionItem = ({ selected, preSelected, className = '', children, borderStyle = 'solid', ...props }: SelectionItemProps) => {
  return <button {...props} className={`c-selection-item interactive c-selection-item--${selected ? 'selected' : (preSelected ? 'preselected' : 'unselected')} c-selection-item--${borderStyle} ${className}`}>
        {children}
    </button>
}
