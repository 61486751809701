import { BudgetPlan } from '../../domain/budgetplan'
import http, { createHeaders } from '../../http'
import { apiUrl } from '../interface'

const serviceUrl = `${apiUrl}/budgetplans`

export const BudgetPlanServiceInstance = {

  async findAll (year: number, itemIds: Array<number>): Promise<Array<BudgetPlan>> {
    return http.get(`${serviceUrl}/${year}/?itemIds=${itemIds.join(',')}`, await createHeaders())
  }

}
