import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Account } from '../api/domain'

const ACCOUNT_LAST_SELECTED_KEY = 'account-last-selected-key'

export interface AccountState {
    isLoading: boolean,
    activeAccounts: Array<Account>,
    lastSelectedAccountId: number | null
}

const emptyState: AccountState = {
  isLoading: false,
  activeAccounts: [],
  lastSelectedAccountId: Number.parseInt(JSON.parse(localStorage.getItem(ACCOUNT_LAST_SELECTED_KEY) || '0')) || null
}

const initialState: AccountState = emptyState

export const accountSlice = createSlice({
  name: 'activeAccounts',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, isLoading: action.payload }
    },
    setAccounts: (state, action: PayloadAction<Array<Account>>) => {
      return { ...state, activeAccounts: action.payload }
    },
    clearAccounts: (state) => {
      return { ...state, activeAccounts: [] }
    },
    setLastSelectedAccountId: (state, action: PayloadAction<number | null>) => {
      localStorage.setItem(ACCOUNT_LAST_SELECTED_KEY, JSON.stringify(action.payload))
      return { ...state, lastSelectedAccountId: action.payload }
    }
  }
})

export const { setAccounts, clearAccounts, setLoading, setLastSelectedAccountId } = accountSlice.actions

export default accountSlice.reducer
