import { HTMLProps, ReactNode } from 'react'
import './Content.scss'
import { NoContent } from './NoContent'

export interface ContentProps extends HTMLProps<HTMLDivElement> {
  className?: string,
  children?: ReactNode | Array<ReactNode>
  emptyMessage?: string
}

const isEmptyNode = (node: ReactNode | Array<ReactNode>): boolean => {
  if (node instanceof Array) {
    return node.length === 0 || node.every(i => isEmptyNode(i))
  }
  return !node
}

export const Content = ({ children, emptyMessage, className = '', ...props } : ContentProps) => {
  const isLoading = children === undefined
  const showEmptyMessage = !isLoading && (isEmptyNode(children))
  return showEmptyMessage
    ? <NoContent className={'c-content'}>{emptyMessage}</NoContent>
    : <div {...props} className={`c-content c-content--${children === undefined ? 'loading' : 'loaded'} ${className}`}>
        {children}
    </div>
}
