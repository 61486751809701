import { Status, Icons } from '@benjaminpetry/bepe-design'
import { TransferType } from '../../api/domain'

export const getTransferTypeIcon = (type: TransferType | undefined): string => {
  switch (type) {
    case TransferType.Income:
      return Icons.ArrowRightToBracket
    case TransferType.Expense:
      return Icons.ArrowRightFromBracket
    case TransferType.Transfer:
      return Icons.ArrowRightArrowLeft
    case undefined:
      return Icons.Spinner
  }
}

export const getTransferTypeClass = (type: TransferType | undefined): Status => {
  switch (type) {
    case TransferType.Income:
      return Status.OK
    case TransferType.Expense:
      return Status.CAUTION
    case TransferType.Transfer:
      return Status.INFO
    case undefined:
      return Status.NEUTRAL
  }
}
