import { DateSelectionLevel, DateState } from '../../store/dateStore'

export const formatMonth = (month: number, short: boolean = false): string => {
  return short
    ? ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][month]
    : ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][month]
}

export const getFromDate = (state: DateState, selectionLevel: DateSelectionLevel | undefined = undefined) => {
  const level = selectionLevel ?? state.selectionLevel
  const month = level === DateSelectionLevel.MONTH ? state.month : 0
  return new Date(state.year, month, 1, 0, 0, 0, 0)
}

export const getToDate = (state: DateState) => {
  const month = state.selectionLevel === DateSelectionLevel.MONTH ? state.month : 11
  return new Date(state.year, month + 1, 0, 23, 59, 59, 999)
}
