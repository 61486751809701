import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { BudgetCategoryApi, BudgetItemApi, BudgetLabelApi } from '../../../api/domain'

type FilteredBudgetElementsReturn = [Array<BudgetCategoryApi>, Array<BudgetItemApi>, Array<BudgetLabelApi>]

export const useFilteredBudgetElements = (): FilteredBudgetElementsReturn => {
  const dateSelection = useSelector((state: RootState) => state.dateSelection)
  const budgetStore = useSelector((state: RootState) => state.budget)
  const profileState = useSelector((state: RootState) => state.activeProfile)
  const [categories, setCategories] = useState<Array<BudgetCategoryApi>>([])
  const [items, setItems] = useState<Array<BudgetItemApi>>([])
  const [labels, setLabels] = useState<Array<BudgetLabelApi>>([])
  const isMounted = useIsMounted()

  const filter = async () => {
    const cats = budgetStore.categories.filter(cat => cat.relevantFromYear <= dateSelection.year && (cat.relevantToYear === null || cat.relevantToYear >= dateSelection.year))
    const its = budgetStore.items.filter(item => item.relevantFromYear <= dateSelection.year && (item.relevantToYear === null || item.relevantToYear >= dateSelection.year))
    const labs = budgetStore.labels.filter(lab => lab.relevantFromYear <= dateSelection.year && (lab.relevantToYear === null || lab.relevantToYear >= dateSelection.year))
    if (isMounted) {
      setCategories(cats)
      setItems(its)
      setLabels(labs)
    }
  }

  useEffect(() => {
    if (profileState.active && !profileState.isLoading) {
      filter()
    }
  }, [budgetStore.categories, budgetStore.items, budgetStore.labels, profileState.active, profileState.isLoading, dateSelection.year])

  return [categories, items, labels]
}
