
import './AccountTypeIcon.scss'
import { AccountType } from '../../api/domain'
import { TypeIcon, TypeIconBasisProps } from '../TypeIcon'
import { getAccountClassName } from './AccountDisplay'

export interface AccountTypeIconProps extends TypeIconBasisProps {
  type: AccountType
}

export const AccountTypeIcon = ({ className = '', type, ...props } : AccountTypeIconProps) => {
  const status = getAccountClassName(type)
  return <TypeIcon {...props} status={status} statusKind='text' className={`c-account-type-icon ${className}`}></TypeIcon>
}
