
function parseString (str: string): number {
  const regex = /^([-]?(0|[1-9])[0-9,.]*?)([,.]([0-9][0-9]?))?$/
  const match = str.match(regex)
  if (!match) {
    throw Error(`'${str}' is not a number`)
  } else {
    const number = Number.parseInt(match[1].replace(/[,.]/g, ''))
    const decimalString = match[4] ?? '00'
    const decimal = Number.parseInt(decimalString.length === 1 ? decimalString + '0' : decimalString) / 100.0 * (number < 0 ? -1 : 1)
    return Math.round((number + decimal) * 100)
  }
}

function formatNumber (num: number, currency = ''): string {
  return Intl.NumberFormat(undefined, currency ? { style: 'currency', currency } : { minimumFractionDigits: 2, useGrouping: false }).format(num / 100)
}

export const CurrencyFormatter = {
  formatNumber,
  parseString
}
