import { ReactNode } from 'react'
import './MainView.scss'
import { View } from './View'

export interface MainViewProps {
    title: string,
    className?: string,
    contextMenu?: ReactNode | Array<ReactNode>
    children: ReactNode | Array<ReactNode>,
    detailViews?: ReactNode | Array<ReactNode>,
    modals?: ReactNode | Array<ReactNode>
}

export const MainView = ({ title, className, contextMenu, children, detailViews, modals }: MainViewProps) => {
  return <View
        title={title}
        className={`c-main-view ${className || ''}`}
        right={contextMenu}
        detailViews={detailViews}
        modals={modals}
        >
        {children}
      </View>
}
