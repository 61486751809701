import { Icon } from '@benjaminpetry/bepe-design'
import { ReactNode } from 'react'
import './ContextMenuItem.scss'

export interface ContextMenuItemProps {
  className?: string,
  icon?: string,
  children: ReactNode | Array<ReactNode>
  isDangerousAction?: boolean,
  onClick: () => void
}

export const ContextMenuItem = ({ className, icon, children, isDangerousAction = false, onClick } : ContextMenuItemProps) => {
  return <button className={`c-contextmenu-item c-contextmenu-item--${isDangerousAction ? 'dangerous' : 'normal'} ${className ?? ''}`} onClick={onClick}>
    {icon && <Icon icon={icon}></Icon>}
    <div className="c-contextmenu-item__content">{children}</div>
  </button>
}
