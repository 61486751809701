import { ReactNode } from 'react'
import { Icons } from '@benjaminpetry/bepe-design'
import { IconButton } from '../../components/input/IconButton'
import { View } from './View'
import './DetailView.scss'

export interface ViewProps {
    title: string,
    backTitle: string,
    isOpen: boolean,
    onClose: () => void,
    className?: string,
    contextMenu?: ReactNode,
    detailViews?: ReactNode | Array<ReactNode>,
    modals?: ReactNode | Array<ReactNode>,
    children: ReactNode | Array<ReactNode>
}

export const DetailView = ({ title, backTitle, className, isOpen, onClose, children, detailViews, modals, contextMenu }: ViewProps) => {
  const renderLeft = () => {
    return [<IconButton key={1} className='c-desktop-only' icon={Icons.Xmark} onClick={onClose}></IconButton>,
      <IconButton key={2} className='c-mobile-only' icon={Icons.AngleLeft} onClick={onClose}>{backTitle}</IconButton>]
  }

  const renderRight = () => {
    return <div className='c-detail-view__right'>
    {contextMenu}
  </div>
  }

  return <View
    className={`c-detail-view c-detail-view--${isOpen ? 'open' : 'closed'} ${className || ''}`}
    title={title}
    left={renderLeft()}
    right={renderRight()}
    detailViews={detailViews}
    modals={modals}
    >
    {children}
  </View>
}
