import { Textfield, Icons } from '@benjaminpetry/bepe-design'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Account, BudgetItemApi, Transfer } from '../../api/domain'
import { TransferSearchResult } from '../../api/services/transfer/interface'
import { IconButton } from '../../components/input/IconButton'
import { TransferList } from '../../domain/transfer/components/TransferList'
import { SearchOptions } from '../../domain/transfer/hooks/useTransferSearch'
import { RootState } from '../../store'
import './TransferListViewModule.scss'

export interface TransferListViewModuleProps {
    busy?: boolean;
    searchResult: TransferSearchResult;
    searchOptions: SearchOptions;
    tailerToBudgetItemId?: number;
    onUpdateSearchOptions: (options: SearchOptions) => void;
    selectedTransferId: number | null;
    onSelectionChanged: (transfer: Transfer | null) => void;
}

export const TransferListViewModule = ({ searchOptions, searchResult, selectedTransferId, tailerToBudgetItemId, busy, onUpdateSearchOptions, onSelectionChanged } : TransferListViewModuleProps) => {
  const accountState = useSelector((state: RootState) => state.activeAccounts)
  const budgetState = useSelector((state: RootState) => state.budget)

  const [accountMap, setAccountMap] = useState<Map<number, Account>>(new Map())
  const [budgetItemMap, setBudgetItemMap] = useState<Map<number, BudgetItemApi>>(new Map())
  const [query, setQuery] = useState<string>(searchOptions.query)
  const textfieldRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setQuery(searchOptions.query)
  }, [searchOptions])

  const onPageChanged = (newPage: number) => {
    if (newPage !== searchOptions.page) {
      onUpdateSearchOptions({ ...searchOptions, page: newPage })
    }
  }

  const onQueryChanged = () => {
    if (query !== searchOptions.query) {
      onUpdateSearchOptions({ ...searchOptions, query })
    }
  }

  const onEmpty = () => {
    setQuery('')
    onUpdateSearchOptions({ ...searchOptions, query: '' })
    window.setTimeout(() => {
      if (textfieldRef.current) {
        textfieldRef.current.focus()
      }
    }, 100)
  }

  useEffect(() => {
    setAccountMap(new Map(accountState.activeAccounts.map(account => ([account.id, account]))))
  }, [accountState.activeAccounts])

  useEffect(() => {
    setBudgetItemMap(new Map(budgetState.items.map(item => ([item.id, item]))))
  }, [budgetState.items])

  const lastPage = Math.ceil(searchResult.count / searchOptions.pageSize)

  return (<div className="c-transfer-list-module">
        <div className="c-transfer-list-module__search">
            <Textfield ref={textfieldRef} disabled={busy} placeholder='Search' value={query} onChange={evt => setQuery(evt.target.value)} onEmpty={onEmpty} onEnter={onQueryChanged}></Textfield>
            <IconButton className="c-transfer-list-module__search-icon" icon={Icons.MagnifyingGlass} onClick={onQueryChanged}></IconButton>
        </div>
        <div className="c-transfer-list-module__list">
            <TransferList
                accountMap={accountMap}
                budgetItemMap={budgetItemMap}
                onSelectionChanged={onSelectionChanged}
                selectedTransferId={selectedTransferId}
                tailerToBudgetItemId={tailerToBudgetItemId}
                transfers={searchResult.rows}
                busy={busy}
            ></TransferList>
        </div>
        <div className="c-transfer-list-module__pagination">
            <IconButton icon={Icons.AnglesLeft} disabled={searchOptions.page <= 1} onClick={() => onPageChanged(1)}></IconButton>
            <IconButton icon={Icons.AngleLeft} disabled={searchOptions.page <= 1} onClick={() => onPageChanged(Math.max(1, searchOptions.page - 1))}></IconButton>
            <p>{searchOptions.page}</p>
            <IconButton icon={Icons.AngleRight} disabled={searchOptions.page >= lastPage} onClick={() => onPageChanged(Math.min(lastPage, searchOptions.page + 1))}></IconButton>
            <IconButton icon={Icons.AnglesRight} disabled={searchOptions.page >= lastPage} onClick={() => onPageChanged(lastPage)}></IconButton>
        </div>
    </div>)
}
