import { FormElement, Icons, Signal, Textfield } from '@benjaminpetry/bepe-design'

import { Account, Transfer, TransferBudgetEntry } from '../../../api/domain'
import { IconButton } from '../../../components/input/IconButton'
import { TransferAccountSelection } from '../../account/components/TransferAccountSelection'
import { BudgetSelectionList } from '../../budget/components/BudgetSelectionList'
import { CurrencyTextfield } from '../../currency/CurrencyTextfield'
import { getTransferTypeIcon } from '../../transfer/TransferDisplay'
import { formatDateForInput, getTransferType, today, TransferError, updateFirstBudgetItemsValue } from '../../transfer/TransferLogic'
import { TransferTypeIcon } from '../../transfer/TransferTypeIcon'
import './ImportTransferRow.scss'

export interface ImportTransferRowProps {
  transfer: Transfer,
  className?: string,
  error: TransferError,
  busy: boolean,
  hasCollisions: boolean,
  onErrorChanged: (partialError: Partial<TransferError>) => void,
  onChange: (transfer: Transfer) => void,
  onSave: () => void,
  onRemove: () => void
}

export const ImportTransferRow = ({ className = '', transfer, busy, hasCollisions, error, onErrorChanged, onChange, onSave, onRemove }: ImportTransferRowProps) => {
  const updateTransfer = (partialTransfer: Partial<Transfer>) => {
    onChange({ ...transfer, ...partialTransfer })
  }

  const updateError = (partialError: Partial<TransferError>) => {
    onErrorChanged(partialError)
  }

  const onDateChanged = (newDate: string) => {
    const date = newDate === '' ? today() : new Date(newDate)
    updateTransfer({ date })
  }

  const onValueChanged = (value: number) => {
    const budgetItems = updateFirstBudgetItemsValue(value, transfer.budgetItems)
    updateTransfer({ value, budgetItems })
  }

  const onDescriptionChanged = (description: string) => {
    updateTransfer({ description })
  }

  const onTransferAccountSelect = (account: Account | null) => {
    updateTransfer({ transferAccountId: account === null ? null : account.id, budgetItems: [] })
  }

  const onBudgetEntriesChanged = (list: Array<TransferBudgetEntry>) => {
    updateTransfer({ budgetItems: updateFirstBudgetItemsValue(transfer.value, list) })
  }

  const onCommentChanged = (comment: string) => {
    updateTransfer({ comment })
  }

  const realType = getTransferType(transfer)

  return (<div className={`c-import-transfer-row c-import-table__row ${className}`}>
    <TransferTypeIcon className='c-import-table__icon' icon={getTransferTypeIcon(realType)} type={realType}></TransferTypeIcon>
      <div className='c-import-table__date'>
        <Textfield enableEmptyButton={false} disabled={busy} className='c-import-table__date-input' type='date' value={formatDateForInput(transfer.date)} onChange={evt => onDateChanged(evt.target.value)}></Textfield>
     </div>
      <div className='c-import-table__description'>
        <Textfield className='c-import-table__description-input' disabled={busy} type='string' value={transfer.description} onChange={evt => onDescriptionChanged(evt.target.value)} hint={error.description} signal={error.description ? Signal.DANGER : undefined}></Textfield>
      </div>
     <div className='c-import-table__value'>
          <CurrencyTextfield className='c-import-table__value-input' disabled={busy} initialValue={transfer.value} onErrorChange={err => updateError({ value: err })} onChange={onValueChanged} error={error.value} ></CurrencyTextfield>
      </div>
      <div className='c-import-table__transfer-account'>
        <TransferAccountSelection
        excludedAccountId={transfer.accountId}
        onSelection={onTransferAccountSelect}
        transferAccountId={transfer.transferAccountId}
        ></TransferAccountSelection>
      </div>
      <div className='c-import-table__budgetItems'>
        <FormElement hint={error.budgetItems} signal={error.budgetItems ? Signal.DANGER : undefined}>
          <BudgetSelectionList disabled={busy} currentErrors={error.budgetItemEntries} onErrorChange={errs => updateError({ budgetItemEntries: errs })} transferType={realType} transferDate={transfer.date} currentValue={transfer.value} transferItems={transfer.budgetItems} onChange={onBudgetEntriesChanged}></BudgetSelectionList>
        </FormElement>
      </div>
      <div className='c-import-table__comment'>
        <Textfield type='string' disabled={busy} className='c-import-table__comment-input' value={transfer.comment} onChange={evt => onCommentChanged(evt.target.value)}></Textfield>
      </div>
      <div className='c-import-table__actions'>
        <IconButton icon={Icons.FloppyDisk} disabled={busy || hasCollisions} onClick={onSave} title='Save'></IconButton>
        <IconButton icon={Icons.Trash} disabled={busy} onClick={onRemove} title='Remove'></IconButton>
      </div>
    </div>)
}
