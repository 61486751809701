import { Icon, Icons } from '@benjaminpetry/bepe-design'
import { HTMLProps } from 'react'
import { BudgetCategoryApi, BudgetCategoryType, BudgetItemApi, BudgetItemBalance } from '../../../api/domain'
import { BudgetPlan, BudgetPlanField, BudgetPlanSavingMode, BudgetPlanSavingType, ProgressGranularity } from '../../../api/domain/budgetplan'
import { IconButton } from '../../../components/input/IconButton'
import { AccountService } from '../../../service/accountService'
import { AccountModuleMini } from '../../account/components/AccountModuleMini'
import { BudgetItemModuleMini } from '../../budget/components/item/BudgetItemModuleMini'
import { CurrencyDisplay } from '../../currency/CurrencyDisplay'
import { CurrencyTextfield } from '../../currency/CurrencyTextfield'
import { ArrayUtil } from '../ArrayUtil'
import './BudgetPlanModule.scss'

export interface BudgetPlanModuleProps extends HTMLProps<HTMLDivElement> {
  category: BudgetCategoryApi,
  item: BudgetItemApi,
  plan: BudgetPlan,
  balances: Array<BudgetItemBalance> | undefined,
  showActual: boolean,
  className?: string,
  editMode: boolean,
  isSaving: (id: number, field: BudgetPlanField | null, month: number | null) => boolean,
  onSavePlan: (plan: BudgetPlan, field: BudgetPlanField | null, month: number | null) => Promise<boolean>
}

export const BudgetPlanModule = ({ category, item, plan, balances, showActual, editMode, isSaving, onSavePlan, className = '', ...props }: BudgetPlanModuleProps) => {
  const plannedSum = ArrayUtil.sum(plan.planned)
  const savingsSum = ArrayUtil.sum(plan.savings)
  const savingsMonthlyTotal = plan.savings.reduce((arr, s, i) => [...arr, (i === 0 ? plan.savingsStart : arr[i - 1]) + s + plan.planned[i]], [] as Array<number>)
  const defaultAccount = AccountService.getAccountById(item.defaultAccountId)
  const transferAccount = item.transferAccountId === null ? undefined : AccountService.getAccountById(item.transferAccountId)

  const onUpdate = async (partialPlan: Partial<BudgetPlan>, field: BudgetPlanField | null = null, month: number | null = null) => {
    const newPlan = { ...plan, ...partialPlan }
    return onSavePlan(newPlan, field, month)
  }

  const updatePlan = (newValue: number, field: BudgetPlanField, month: number | null) => {
    const planned = field === BudgetPlanField.TARGETS ? plan.planned.map((v, i) => i === month ? newValue : v) : plan.planned
    const savings = field === BudgetPlanField.SAVINGS ? plan.savings.map((v, i) => i === month ? newValue : v) : plan.savings
    const savingsStart = field === BudgetPlanField.SAVINGSSTART ? newValue : plan.savingsStart
    return onUpdate({ planned, savings, savingsStart }, field, month)
  }

  const onValueChanged = (oldValue: number, newValue: number, field: BudgetPlanField, month: number | null) => {
    if (oldValue !== newValue) {
      updatePlan(newValue, field, month)
    }
  }

  const displayValue = (value: number, field: BudgetPlanField, month: number | null) => {
    return editMode && (plan.savingMode === 'manual' || field === BudgetPlanField.TARGETS)
      ? <CurrencyTextfield disabled={isSaving(plan.id, field, month)} key={month} className='c-budget-plan-column c-budget-plan__value-field textNormal-75' error='' onErrorChange={() => {}} initialValue={value} onChange={v => onValueChanged(value, v, field, month)}></CurrencyTextfield>
      : <CurrencyDisplay key={month} highlightNegative={true} highlightPositive={true} className='c-budget-plan-column textNormal-75'value={value}></CurrencyDisplay>
  }

  const renderIcon = (className: string, title: string, icon: string, onClick = () => {}, forceIcon: boolean = false) => {
    return editMode && !forceIcon
      ? <IconButton className={`${className}`} title={title} icon={icon} onClick={onClick} disabled={isSaving(plan.id, null, null)}></IconButton>
      : <Icon className={className} title={title} icon={icon}></Icon>
  }

  const onChangePlanSavingType = () => {
    if (editMode && plan.savingMode === BudgetPlanSavingMode.Auto) {
      const savingType = plan.savingType === BudgetPlanSavingType.CONTINUOUS ? BudgetPlanSavingType.YEARLY : BudgetPlanSavingType.CONTINUOUS
      onUpdate({ savingType })
    }
  }
  const onChangePlanSavingMode = () => {
    if (editMode && (plan.savingMode !== BudgetPlanSavingMode.Manual || confirm('You will loose all your manual entered data. Sure to change the manual saving mode?'))) {
      const savingMode = plan.savingMode === BudgetPlanSavingMode.NoSaving ? BudgetPlanSavingMode.Auto : (plan.savingMode === BudgetPlanSavingMode.Auto ? BudgetPlanSavingMode.Manual : BudgetPlanSavingMode.NoSaving)
      onUpdate({ savingMode })
    }
  }
  const onChangeProgressGranularity = () => {
    if (editMode) {
      const progressGranularity = plan.progressGranularity === ProgressGranularity.NONE ? ProgressGranularity.MONTH : (plan.progressGranularity === ProgressGranularity.MONTH ? ProgressGranularity.DAY : ProgressGranularity.NONE)
      onUpdate({ progressGranularity })
    }
  }

  return (<div {...props} className={`c-budget-plan c-budget-plan--${editMode ? 'edit' : 'read'} ${className}`}>
    <div className='c-budget-plan__settings'>
      <div className='c-budget-plan__item-granularity'>
        <BudgetItemModuleMini item={item}></BudgetItemModuleMini>
        <div className='c-budget-plan__granularity-mode'>
          {plan.savingMode === BudgetPlanSavingMode.Auto && renderIcon('c-budget-plan__type', `Saving Type: ${plan.savingType}`, plan.savingType === BudgetPlanSavingType.YEARLY ? Icons.CircleNotch : Icons.Circle, onChangePlanSavingType, category.type === BudgetCategoryType.Income)}
          {(plan.savingMode !== BudgetPlanSavingMode.NoSaving || editMode) && renderIcon('c-budget-plan__mode', `Saving Mode: ${plan.savingMode}`, plan.savingMode === BudgetPlanSavingMode.NoSaving ? Icons.Ban : (plan.savingMode === BudgetPlanSavingMode.Manual ? Icons.Wrench : Icons.WandMagicSparkles), onChangePlanSavingMode, category.type === BudgetCategoryType.Income)}
          {(plan.progressGranularity !== ProgressGranularity.NONE || editMode) && renderIcon('c-budget-plan__granularity', `Granularity: ${plan.progressGranularity}`, plan.progressGranularity === ProgressGranularity.NONE ? Icons.CalendarXmark : (plan.progressGranularity === ProgressGranularity.DAY ? Icons.CalendarDays : Icons.Calendar), onChangeProgressGranularity)}
        </div>
      </div>
      <div className='c-budget-plan__accounts'>
        {defaultAccount && <AccountModuleMini account={defaultAccount}></AccountModuleMini>}
        {defaultAccount && transferAccount && <Icon icon={Icons.ArrowRight}></Icon>}
        {transferAccount && <AccountModuleMini account={transferAccount}></AccountModuleMini>}
      </div>
    </div>
    <div className='c-budget-plan__columns'>
      {showActual && <div className="c-budget-plan__actual">
        <div className='c-budget-plan-column textBold-75'>Actual</div>
        <div className='c-budget-plan-column textBold-75'>-</div>
        {Array.from(Array(12).keys()).map((_, index) => <CurrencyDisplay highlightNegative={true} highlightPositive={true} className='c-budget-plan-column textNormal-75' key={index} value={balances === undefined ? undefined : balances[index].actual}></CurrencyDisplay>)}
        <CurrencyDisplay highlightNegative={true} highlightPositive={true} className='c-budget-plan-column textBold-75' value={balances === undefined ? undefined : ArrayUtil.sum(balances.map(b => b.actual))}></CurrencyDisplay>
      </div>}
      {showActual && <div className="c-budget-plan__target-actual">
        <div className='c-budget-plan-column textBold-75'>Actual-Target</div>
        <div className='c-budget-plan-column textBold-75'>-</div>
        {Array.from(Array(12).keys()).map((_, index) => <CurrencyDisplay highlightNegative={true} highlightPositive={true} className='c-budget-plan-column textNormal-75' key={index} value={balances === undefined ? undefined : balances[index].actual - balances[index].target}></CurrencyDisplay>)}
        <CurrencyDisplay highlightNegative={true} highlightPositive={true} className='c-budget-plan-column textBold-75' value={balances === undefined ? undefined : ArrayUtil.sum(balances.map(b => b.actual - b.target))}></CurrencyDisplay>
      </div>}
      {showActual && <div className="c-budget-plan__target-actual-total">
        <div className='c-budget-plan-column textBold-75'>Total</div>
        <div className='c-budget-plan-column textBold-75'>-</div>
        {Array.from(Array(12).keys()).map((_, index) => <CurrencyDisplay highlightNegative={true} highlightPositive={true} className='c-budget-plan-column textNormal-75' key={index} value={balances === undefined ? undefined : balances[index].targetActualComparison}></CurrencyDisplay>)}
        <CurrencyDisplay highlightNegative={true} highlightPositive={true} className='c-budget-plan-column textBold-75' value={balances === undefined ? undefined : balances[11].targetActualComparison}></CurrencyDisplay>
      </div>}
      <div className="c-budget-plan__target">
        <div className='c-budget-plan-column textBold-75'>Target</div>
        <div className='c-budget-plan-column textBold-75'>-</div>
        {plan.planned.map((value, month) => displayValue(value, BudgetPlanField.TARGETS, month))}
        <CurrencyDisplay highlightNegative={true} highlightPositive={true} className='c-budget-plan-column textBold-75' value={plannedSum}></CurrencyDisplay>
      </div>
      {plan.savingMode !== BudgetPlanSavingMode.NoSaving && <div className="c-budget-plan__savings">
        <div className='c-budget-plan-column textBold-75'>Reserve</div>
        {displayValue(plan.savingsStart, BudgetPlanField.SAVINGSSTART, null)}
        {plan.savings.map((value, month) => displayValue(value, BudgetPlanField.SAVINGS, month))}
        <CurrencyDisplay highlightNegative={true} highlightPositive={true} className='c-budget-plan-column textBold-75' value={savingsSum}></CurrencyDisplay>
      </div>}
      {plan.savingMode !== BudgetPlanSavingMode.NoSaving && <div className="c-budget-plan__savings-total">
        <div className='c-budget-plan-column textBold-75'>Total</div>
        <CurrencyDisplay highlightNegative={true} highlightPositive={true} className='c-budget-plan-column textNormal-75' value={plan.savingsStart}></CurrencyDisplay>
        {savingsMonthlyTotal.map((value, index) => <CurrencyDisplay highlightNegative={true} highlightPositive={true} className='c-budget-plan-column textNormal-75' key={index} value={value}></CurrencyDisplay>)}
        <CurrencyDisplay highlightNegative={true} highlightPositive={true} className='c-budget-plan-column textBold-75' value={savingsMonthlyTotal[11]}></CurrencyDisplay>
      </div>}
    </div>
  </div>)
}
