import { Signal } from '@benjaminpetry/bepe-design'
import { Notification } from '../api/domain/notification'
import { store } from '../store'
import { clearNotifications, closeNotification, popNotification, pushNotification } from '../store/notificationStore'

const SUCCESS_DELAY_DISAPPEARANCE = 2000
const ERROR_DELAY_DISAPPEARANCE = 20000
const WARNING_DELAY_DISAPPEARANCE = 20000
const INFO_DELAY_DISAPPEARANCE = 10000

const remove = (notificationId: number) => {
  store.dispatch(closeNotification(notificationId))
  window.setTimeout(() => store.dispatch(popNotification(notificationId)), 300)
}

const raise = (source: string, message: string, signal: Signal, title?: string) => {
  const notification: Notification = { id: new Date().getTime(), title, message, signal, isClosing: false, source }
  store.dispatch(pushNotification(notification))
  const delay = notification.signal === Signal.CONFIRM ? SUCCESS_DELAY_DISAPPEARANCE : notification.signal === Signal.DANGER ? ERROR_DELAY_DISAPPEARANCE : notification.signal === Signal.WARNING ? WARNING_DELAY_DISAPPEARANCE : INFO_DELAY_DISAPPEARANCE
  window.setTimeout(() => remove(notification.id), delay)
}

const raiseError = (source: string, message: string) => {
  raise(source, message, Signal.DANGER, 'Error')
}

const raiseSuccess = (source: string, message: string, showTitle: boolean = true) => {
  raise(source, message, Signal.CONFIRM, showTitle ? 'Success' : undefined)
}

const clear = (source: string) => {
  store.dispatch(clearNotifications(source))
}

export const NotificationService = {
  raise,
  raiseError,
  raiseSuccess,
  remove,
  clear
}
