import { Icons, Status } from '@benjaminpetry/bepe-design'
import { BudgetCategory, BudgetCategoryType, BudgetItem, BudgetItemBalance, BudgetItemBalances, BudgetLabel, BudgetLabelBalance, BudgetLabelBalances } from '../../api/domain'

export type BudgetLabelViewElement = BudgetLabel & {
  readonly balance: BudgetLabelBalance | undefined
}

export type BudgetItemViewElement = BudgetItem<BudgetLabelViewElement> & {
  readonly balance: BudgetItemBalance | undefined
}

export type BudgetCategoryViewElement = BudgetCategory<BudgetLabelViewElement, BudgetItemViewElement>

const mergeLabelWithBalance = (label: BudgetLabel, labelBalanceMap: Map<number, BudgetLabelBalance>): BudgetLabelViewElement => {
  return {
    ...label,
    balance: labelBalanceMap.get(label.id)
  }
}

const mergeItemWithBalance = <T extends BudgetLabel>(item: BudgetItem<T>, itemBalanceMap: Map<number, BudgetItemBalance>, labelBalanceMap: Map<number, BudgetLabelBalance>): BudgetItemViewElement => {
  return {
    ...item,
    labels: item.labels.map(label => mergeLabelWithBalance(label, labelBalanceMap)),
    balance: itemBalanceMap.get(item.id)
  }
}

export const mergeBalanceAndCategory = <E extends BudgetLabel, T extends BudgetItem<E>>(categories: Array<BudgetCategory<E, T>>, itemBalances: BudgetItemBalances, labelBalances: BudgetLabelBalances): {categories: Array<BudgetCategoryViewElement>, items: Array<BudgetItemViewElement>} => {
  const newItems: Array<BudgetItemViewElement> = []
  const newCategories: Array<BudgetCategoryViewElement> = categories.map(cat => {
    return {
      ...cat,
      items: cat.items.map(item => {
        const newItem: BudgetItemViewElement = mergeItemWithBalance(item, itemBalances, labelBalances)
        newItems.push(newItem)
        return newItem
      })
    }
  })
  return { categories: newCategories, items: newItems }
}

export const getAllCategoryTypes = (): Array<BudgetCategoryType> => ([BudgetCategoryType.Income, BudgetCategoryType.Expense, BudgetCategoryType.Transfer])

export const getBudgetClassName = (type: BudgetCategoryType): Status => {
  switch (type) {
    case BudgetCategoryType.Income:
      return Status.OK
    case BudgetCategoryType.Expense:
      return Status.CAUTION
    case BudgetCategoryType.Transfer:
      return Status.INFO
  }
}

export const createOtherLabel = (itemId: number, type: BudgetCategoryType, total: number): BudgetLabelViewElement => {
  return {
    id: 0,
    balance: { labelId: 0, total },
    icon: Icons.Circle,
    name: 'Others',
    itemId,
    position: -1,
    relevantFromYear: 1970,
    relevantToYear: null,
    type
  }
}
