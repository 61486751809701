export enum AccountType {
    Cash = 'Cash',
    Bank = 'Bank Account',
    Credit = 'Credit Account',
    Savings = 'Savings Account',
    Assets = 'Assets'
}

export type Account = {
    readonly id: number,
    readonly profileId: number,
    readonly name: string,
    readonly type: AccountType
}

export interface AccountBalance {
    actual: number,
    target: number,
    forecast: number,
    openTransactions: number,
    openItems: Array<{itemId: number, value: number}>,
    reserveFundItems: Array<{itemId: number, value: number}>,
    progress: {
        actual: number,
        target: number
    }
}

export type AccountBalances = Map<number, AccountBalance>
