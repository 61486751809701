import { Account, AccountType } from '../../api/domain'

export const createNewAccount = (profileId: number): Account => {
  return {
    id: -1,
    profileId,
    name: '',
    type: AccountType.Bank
  }
}

export const getTypeImportance = (accountType: AccountType): number => {
  switch (accountType) {
    case AccountType.Cash:
      return 0
    case AccountType.Bank:
      return 1
    case AccountType.Credit:
      return 2
    case AccountType.Savings:
      return 3
    case AccountType.Assets:
      return 4
  }
}

export const compareAccounts = (a: Account, b: Account) => {
  if (a.type !== b.type) {
    return getTypeImportance(a.type) - getTypeImportance(b.type)
  }
  return a.name.localeCompare(b.name)
}
