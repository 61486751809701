import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Notification } from '../api/domain/notification'

export interface NotificationState {
    notifications: Array<Notification>
}

const initialState: NotificationState = {
  notifications: []
}

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    pushNotification: (state, action: PayloadAction<Notification>) => {
      return { ...state, notifications: [action.payload, ...state.notifications] }
    },
    closeNotification: (state, action: PayloadAction<number>) => {
      return { ...state, notifications: state.notifications.map(n => n.id === action.payload ? { ...n, isClosing: true } : n) }
    },
    popNotification: (state, action: PayloadAction<number>) => {
      return { ...state, notifications: state.notifications.filter(n => n.id !== action.payload) }
    },
    clearNotifications: (state, action: PayloadAction<string>) => {
      return { ...state, notifications: action.payload === 'all' ? [] : state.notifications.filter(n => n.source !== action.payload) }
    }
  }
})

export const { pushNotification, popNotification, clearNotifications, closeNotification } = notificationSlice.actions

export default notificationSlice.reducer
