
import './TransferTypeIcon.scss'
import { TransferType } from '../../api/domain'
import { TypeIcon, TypeIconBasisProps } from '../TypeIcon'
import { getTransferTypeClass } from './TransferDisplay'

export interface TransferTypeIconProps extends TypeIconBasisProps {
  type?: TransferType
}

export const TransferTypeIcon = ({ className = '', type, ...props } : TransferTypeIconProps) => {
  const status = getTransferTypeClass(type)
  return <TypeIcon {...props} status={status} className={`c-transfer-type-icon c-transfer-type-icon--${props.onClick ? 'clickable' : 'not-clickable'} ${className}`}></TypeIcon>
}
