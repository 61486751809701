import api from '../api'
import { Account } from '../api/domain'
import { AccountType } from '../api/domain/account'
import { store } from '../store'

import { clearAccounts, setAccounts, setLoading as setAccountLoading, setLastSelectedAccountId } from '../store/accountStore'

let accountMap = new Map<number, Account>()

const load = async (): Promise<Array<Account>> => {
  store.dispatch(setAccountLoading(true))
  try {
    const accounts = await api.accountService.findAll()
    accountMap = new Map<number, Account>(accounts.map(a => [a.id, a]))
    store.dispatch(setAccounts(accounts))
    return accounts
  } finally {
    store.dispatch(setAccountLoading(false))
  }
}

const getDefaultAccountId = (): number | null => {
  const accountId = store.getState().activeAccounts.lastSelectedAccountId
  if (accountId !== null) {
    return accountId
  }
  const activeAccounts = store.getState().activeAccounts.activeAccounts
  return activeAccounts.length > 0 ? activeAccounts[0].id : null
}
const setDefaultAccountId = (accountId: number | null) => {
  return store.dispatch(setLastSelectedAccountId(accountId))
}

const getAccountById = (id: number) => {
  return accountMap.get(id)
}

const loadTransfers = async (accountId: number, from: Date, to: Date, page: number, pageSize: number, query: string | null) => {
  return api.accountService.findAllTransfers(accountId, from, to, page, pageSize, query)
}

const create = async (name: string, type: AccountType): Promise<Account> => {
  const account = await api.accountService.create(name, type)
  await load()
  return account
}

const update = async (account: Account): Promise<Account> => {
  const updatedAccount = await api.accountService.update(account)
  await load()
  return updatedAccount
}

const clear = async (): Promise<void> => {
  store.dispatch(clearAccounts())
  accountMap = new Map<number, Account>()
}

export const AccountService = {
  load,
  loadTransfers,
  create,
  clear,
  getAccountById,
  getDefaultAccountId,
  setDefaultAccountId,
  update
}
