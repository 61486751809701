import { ReactNode } from 'react'
import './SelectionListEntry.scss'
import { Icons } from '@benjaminpetry/bepe-design'
import { IconButton } from '../IconButton'

export interface SelectionListEntryProps {
  className?: string,
  children: ReactNode | Array<ReactNode>,
  disabled: boolean,
  onDelete: () => void,
}

export const SelectionListEntry = ({ className = '', disabled, children, onDelete }: SelectionListEntryProps) => {
  return (<div className={`c-selection-list-entry ${className}`}>
              <IconButton disabled={disabled} className='c-selection-list-entry__delete-icon' icon={Icons.Trash} onClick={onDelete}></IconButton>
              {children}
        </div>)
}
