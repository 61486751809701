
import './BudgetTypeIcon.scss'
import { BudgetCategoryType } from '../../api/domain'
import { getBudgetClassName } from './BudgetDisplay'
import { TypeIcon, TypeIconBasisProps } from '../TypeIcon'
import { Status } from '@benjaminpetry/bepe-design'

export interface BudgetTypeIconProps extends TypeIconBasisProps {
  type?: BudgetCategoryType
}

export const BudgetTypeIcon = ({ className = '', type, ...props } : BudgetTypeIconProps) => {
  const status = type === undefined ? Status.NEUTRAL : getBudgetClassName(type)
  return <TypeIcon {...props} status={status} className={`c-budget-type-icon ${className}`}></TypeIcon>
}
