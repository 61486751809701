import { RouteObject } from 'react-router-dom'
import AppLayout from '../layout/AppLayout'
import LoginLayout from '../layout/LoginLayout'
import { AccountsView } from '../views/AccountsView'
import { AddTransferView } from '../views/AddTransferView'
import { BudgetingView } from '../views/BudgetingView'
import BudgetView from '../views/BudgetView'
import DashboardView from '../views/DashboardView'
import { TransfersView } from '../views/TransfersView'

export enum Route {
  Login = 'login',
  Dashboard = 'dashboard',
  Accounts = 'accounts',
  AccountDetail = 'account-detail',
  AccountTransferDetail = 'account-transfer-detail',
  AccountsAddTransfer = 'account-add-transfer',
  Budget = 'budget',
  BudgetItemDetail = 'budget-item-detail',
  BudgetItemTransferDetail = 'budget-item-transfer-detail',
  BudgetTransfers = 'budget-transfers',
  Budgeting = 'budgeting',
  Transfers = 'transfers',
  TransferDetail = 'transfer-detail',
  AddTransfer = 'add-transfer',
}

export const appRoutes = {
  [Route.Login]: () => '/',
  [Route.Dashboard]: () => '/app',
  [Route.Accounts]: () => '/app/accounts',
  [Route.AccountDetail]: (accountId: number | undefined) => `/app/accounts/${accountId ?? ':accountId'}/`,
  [Route.AccountTransferDetail]: (accountId: number | undefined, transferId: number | undefined) => `/app/accounts/${accountId ?? ':accountId'}/transfers/${transferId ?? ':transferId'}`,
  [Route.AccountsAddTransfer]: (accountId: number | undefined) => `/app/accounts/${accountId ?? ':accountId'}/transfers/add`,
  [Route.Budget]: () => '/app/budget',
  [Route.BudgetItemDetail]: (budgetItemId: number | undefined) => `/app/budget/${budgetItemId ?? ':budgetItemId'}/`,
  [Route.BudgetItemTransferDetail]: (budgetItemId: number | undefined, transferId: number | undefined) => `/app/budget/${budgetItemId ?? ':budgetItemId'}/transfers/${transferId ?? ':transferId'}`,
  [Route.BudgetTransfers]: (budgetId: number | undefined) => `/app/budget/${budgetId ?? ':budgetId'}/transfers`,
  [Route.Budgeting]: () => '/app/budgeting',
  [Route.Transfers]: () => '/app/transfers',
  [Route.TransferDetail]: (transferId: number | undefined) => `/app/transfers/${transferId}`,
  [Route.AddTransfer]: () => '/app/transfers/add'
}

const routes: RouteObject[] = [
  {
    path: '/',
    element: <LoginLayout />
  },
  {
    path: '/app',
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <DashboardView />
      },
      {
        path: 'accounts',
        element: <AccountsView />,
        children: [
          {
            path: ':accountId',
            element: <AccountsView />,
            children: [
              {
                path: 'transfers/:transferId',
                element: <AccountsView />
              }
            ]
          }
        ]
      },
      {
        path: 'budget',
        element: <BudgetView />,
        children: [
          {
            path: ':budgetItemId',
            element: <BudgetView />,
            children: [
              {
                path: 'transfers/:transferId',
                element: <BudgetView />
              }
            ]
          }
        ]
      },
      {
        path: 'transfers',
        children: [
          {
            index: true,
            element: <TransfersView />
          },
          {
            path: 'add',
            element: <AddTransferView />
          },
          {
            path: ':transferId',
            element: <TransfersView />
          }
        ]
      },
      {
        path: 'budgeting',
        children: [
          {
            index: true,
            element: <BudgetingView />
          }
        ]
      }
    ]
  }
]

export default routes
