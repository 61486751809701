
import { Account } from '../../../api/domain'
import { NoContent } from '../../../components/display/NoContent'
import { SelectionDropdown } from '../../../components/input/selection/SelectionDropdown'
import { AccountModuleMini } from '../../../domain/account/components/AccountModuleMini'
import './AccountSelection.scss'

export interface AccountSelectionProps {
  className?: string,
  accounts: Array<Account>,
  autoOpen?: boolean,
  selectedAccountId: number | null,
  allowDeselect?: boolean,
  onSelect: (account: Account | null) => void
}

export const AccountSelection = ({ className, accounts, autoOpen = false, allowDeselect = false, selectedAccountId, onSelect } : AccountSelectionProps) => {
  const onFilter = (item: Account, query: string) => item.name.toLowerCase().indexOf(query) >= 0
  const renderDisplayItem = (item: Account) => <AccountModuleMini account={item}></AccountModuleMini>
  const renderListItem = (item: Account) => <AccountModuleMini account={item}></AccountModuleMini>
  const renderNoItemSelected = () => <NoContent className='textNormal-75'>No account selected</NoContent>
  return <SelectionDropdown
    selectedId={selectedAccountId}
    className={className}
    list={accounts}
    autoOpen={autoOpen}
    filterFun={onFilter}
    allowDeselect={allowDeselect}
    onSelect={account => onSelect(account!)}
    renderDisplayItem={renderDisplayItem}
    renderListItem={renderListItem}
    renderNoItemSelected={renderNoItemSelected}></SelectionDropdown>
}
