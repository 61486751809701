
import { useSelector } from 'react-redux'
import { Account } from '../../../api/domain'
import { SelectionList } from '../../../components/input/selection/SelectionList'
import { useAutoOpen } from '../../../hooks/useAutoOpen'
import { RootState } from '../../../store'
import { compareAccounts } from '../AccountLogic'
import { AccountSelection } from './AccountSelection'

export interface TransferAccountListProps {
  className?: string,
  transferAccountId: number | null,
  excludedAccountId: number,
  onSelection: (account: null | Account) => void
}

export const TransferAccountSelection = ({ className = '', transferAccountId, excludedAccountId, onSelection }: TransferAccountListProps) => {
  const accountStore = useSelector((state: RootState) => state.activeAccounts)
  const selectableAccounts = [...accountStore.activeAccounts].sort(compareAccounts)
  const [isDropdownOpen, openDropdown] = useAutoOpen()

  const onAddAccount = () => {
    const newAccount = selectableAccounts.filter(a => a.id !== excludedAccountId)[0]
    onSelection(newAccount)
    openDropdown()
  }

  return <SelectionList
    addButtonLabel='Add Transfer Account'
    items={transferAccountId === null ? [] : [transferAccountId]}
    onAdd={onAddAccount}
    disableAddButton={selectableAccounts.length < 2}
    onDelete={() => onSelection(null)}
    maxEntries={1}
    className={className}
    renderItem={(item) => <AccountSelection autoOpen={isDropdownOpen} accounts={selectableAccounts.filter(a => a.id !== excludedAccountId)} onSelect={onSelection} selectedAccountId={item}></AccountSelection> }
  ></SelectionList>
}
