import { ReactNode } from 'react'
import { DateSelector } from '../../components/navigation/dateselector/DateSelector'
import './View.scss'

export interface ViewProps {
    title: string,
    className?: string,
    left?: ReactNode | Array<ReactNode>,
    right?: ReactNode | Array<ReactNode>,
    detailViews?: ReactNode | Array<ReactNode>,
    modals?: ReactNode | Array<ReactNode>,
    children: ReactNode | Array<ReactNode>,
}

export const View = ({ title, className, left, right, children, detailViews, modals }: ViewProps) => {
  const renderHeader = () => {
    return <header className="c-view__header">
      <div className='c-view__main-header'>
        <div className='c-view__left'>{left}</div>
        <h1>{title}</h1>
        <div className='c-view__right'>{right}</div>
      </div>
      <DateSelector className='c-mobile-only'></DateSelector>
    </header>
  }

  const renderBody = () => {
    return <main className="c-view__main">
          {children}
        </main>
  }

  return <div className={`c-view ${className || ''}`}>
      {detailViews}
      <div className="c-view__content">
        {renderHeader()}
        {renderBody()}
      </div>
      {modals}
    </div>
}
