import { Icons } from '@benjaminpetry/bepe-design'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Transfer } from '../../api/domain'
import { ContextMenu } from '../../components/navigation/contextmenu/ContextMenu'
import { ContextMenuItem } from '../../components/navigation/contextmenu/ContextMenuItem'
import { BudgetItemViewElement } from '../../domain/budget/BudgetDisplay'
import { BudgetItemDetails } from '../../domain/budget/components/item/BudgetItemDetails'
import { useTransferSearch } from '../../domain/transfer/hooks/useTransferSearch'
import { appRoutes, Route } from '../../router'
import { DetailView } from '../components/DetailView'
import { TransferDetailsView } from '../Transfers/TransferDetailsView'
import { TransferFormModal } from '../Transfers/TransferFormModal'
import './BudgetItemDetailsView.scss'

export interface BudgetItemDetailsViewProps {
  className?: string,
  originTitle: string,
  item?: BudgetItemViewElement,
  onEdit: () => void,
  onClose: () => Promise<void>,
  onLoadBalances: () => Promise<void>
}

export const BudgetItemDetailsView = ({ className, item, originTitle, onEdit, onClose, onLoadBalances }: BudgetItemDetailsViewProps) => {
  const { transferId } = useParams()
  const navigate = useNavigate()
  const [lastItem, setLastItem] = useState<BudgetItemViewElement | undefined>(item)
  const [transferToEdit, setTransferToEdit] = useState<Transfer | undefined>(undefined)
  const [searchResult, busy, searchOptions, setSearchOptions, search] = useTransferSearch(item !== undefined, { budgetItemId: item?.id })

  const selectedTransferId = Number.parseInt(transferId || '0')
  const selectedTransfer = transferId === undefined ? undefined : searchResult.rows.find(a => a.id === selectedTransferId)

  useEffect(() => {
    if (item) {
      setLastItem(item)
      search()
    }
  }, [item])

  const onDelete = () => {
    alert(`Deleting BudgetItem ${item?.name}`)
  }

  const selectTransfer = (transfer: Transfer | null) => {
    if (lastItem) {
      if (transfer === null || selectedTransferId === transfer.id) {
        navigate(appRoutes[Route.BudgetItemDetail](lastItem.id))
      } else {
        navigate(appRoutes[Route.BudgetItemTransferDetail](lastItem.id, transfer.id))
      }
    }
    return Promise.resolve()
  }

  const renderContextMenu = () => {
    return <ContextMenu>
      <ContextMenuItem icon={Icons.PenToSquare} onClick={onEdit}>Edit Budget Item</ContextMenuItem>
      <ContextMenuItem icon={Icons.Trash} onClick={onDelete} isDangerousAction={true}>Delete Budget Item</ContextMenuItem>
      <ContextMenuItem icon={Icons.ArrowsRotate} onClick={search}>Reload Transfers</ContextMenuItem>
    </ContextMenu>
  }

  const onAfterSaving = async () => {
    await Promise.all([search(), onLoadBalances()])
  }

  const onAfterSaved = () => {
    setTransferToEdit(undefined)
  }

  return (<DetailView
    isOpen={!!item}
    className={`c-budget-item-details-view ${className}`}
    title={lastItem?.name ?? 'Budget Item'}
    onClose={onClose}
    contextMenu={renderContextMenu()}
    backTitle={originTitle}
    detailViews={[<TransferDetailsView onDeleting={onAfterSaving} key='account-transfer-details' originTitle={item?.name ?? 'Budget Item'} className="c-budget-teim-transfer-details-view" transfer={selectedTransfer} onClose={() => selectTransfer(null)} onEdit={() => setTransferToEdit(selectedTransfer)}></TransferDetailsView>]}
    modals={transferToEdit && <TransferFormModal transfer={transferToEdit} onClose={() => setTransferToEdit(undefined)} onAfterSaving={onAfterSaving} onAfterSaved={onAfterSaved}></TransferFormModal>}
    >
      <BudgetItemDetails
        item={lastItem}
        busy={busy}
        onTransferSelectionChanged={selectTransfer}
        onUpdateSearchOptions={setSearchOptions}
        searchOptions={searchOptions}
        searchResult={searchResult}
        selectedTransferId={selectedTransferId}
        ></BudgetItemDetails>
    </DetailView>)
}
