
import './Tab.scss'

export interface TabProps {
  className?: string,
  label: string,
  isActive: boolean,
  onClick: () => void,
}

export const Tab = ({ className = '', label, isActive, onClick } : TabProps) => {
  return <div className={`c-tab c-tab--${isActive ? 'active' : 'inactive'} ${className}`} onClick={onClick}>{label}</div>
}
