
import { Transfer } from '../../../api/domain'
import { ImportCollisionType, ImportTransfer } from '../../../api/domain/import'
import { TransferError } from '../../transfer/TransferLogic'
import { ImportCollisionRow } from './ImportCollisionRow'
import './ImportTransferModule.scss'
import { ImportTransferRow } from './ImportTransferRow'

export interface ImportTransferProps {
  importTransfer: ImportTransfer,
  className?: string,
  error: TransferError,
  busy: boolean,
  onErrorChanged: (partialError: Partial<TransferError>) => void,
  onChange: (transfer: ImportTransfer) => void,
  onSave: () => void,
  onRemove: () => void
}

export const ImportTransferModule = ({ className = '', importTransfer, busy, error, onErrorChanged, onChange, onSave, onRemove }: ImportTransferProps) => {
  const updateTransfer = (transfer: Transfer) => {
    onChange({ ...importTransfer, transfer })
  }

  const updateCollision = (collisionIndex: number, existingTransfer: Transfer, action: ImportCollisionType | 'ignore') => {
    let transfer = { ...importTransfer.transfer }
    if (action === ImportCollisionType.Update || action === ImportCollisionType.Merge) {
      transfer = { ...existingTransfer, description: transfer.description, bankDetails: transfer.bankDetails }
    } else if (action === ImportCollisionType.UpdateInverted || action === ImportCollisionType.MergeInverted) {
      transfer = { ...existingTransfer, transferBankDetails: transfer.bankDetails }
    } else if (action === ImportCollisionType.Replace) {
      transfer = { ...transfer, id: existingTransfer.id }
    }
    const collisions = importTransfer.collisions.filter((_, i) => i !== collisionIndex)
    onChange({ transfer, collisions })
  }

  return (<div className={`c-import-transfer ${className}`}>
      <ImportTransferRow busy={busy} error={error} hasCollisions={importTransfer.collisions.length > 0} onChange={updateTransfer} onErrorChanged={onErrorChanged} onRemove={onRemove} onSave={onSave} transfer={importTransfer.transfer}></ImportTransferRow>
      {importTransfer.collisions.map((collision, i) => {
        return <ImportCollisionRow key={i} busy={busy} collision={collision} onAction={updateCollision} collisionIndex={i}></ImportCollisionRow>
      })}
    </div>)
}
