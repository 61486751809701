import { Icon } from '@benjaminpetry/bepe-design'
import { HTMLProps } from 'react'
import { BudgetLabel } from '../../../../api/domain'
import { getBudgetClassName } from '../../BudgetDisplay'
import './BudgetLabelModuleMini.scss'

export interface BudgetLabelModuleMiniProps extends HTMLProps<HTMLDivElement> {
  budgetLabel: BudgetLabel,
  className?: string
}

export const BudgetLabelModuleMini = ({ budgetLabel, className = '', ...props }: BudgetLabelModuleMiniProps) => {
  return (<div {...props} className={`textNormal-75 c-budget-label-module-mini status-light--${getBudgetClassName(budgetLabel.type)} ${className}`}>
    <Icon className={'c-budget-label-module-mini__icon'} icon={budgetLabel.icon}></Icon>
    <div className='c-budget-label-module-mini__name'>{budgetLabel.name}</div>
  </div>)
}
