import { ProgressGranularity } from './budgetplan'

export enum BudgetCategoryType {
  Income = 'income',
  Expense = 'expense',
  Transfer = 'transfer'
}

type BudgetBase = {
  readonly id: number,
  readonly name: string,
  readonly icon: string,
  readonly position: number
}

export type BudgetItemBalance = {
  readonly itemId: number,
  readonly target: number,
  readonly actual: number,
  readonly targetActualComparison: number,
  readonly targetActualPreviousMonth: number,
  readonly progress: {
    readonly actual: number,
    readonly target: number,
    readonly granularity: ProgressGranularity
  }
}

export type BudgetItemBalances = Map<number, BudgetItemBalance>

export type BudgetLabelBalance = {
  readonly labelId: number,
  readonly total: number
}

export type BudgetLabelBalances = Map<number, BudgetLabelBalance>

export type BudgetCategory<E extends BudgetLabel, T extends BudgetItem<E>> = BudgetBase & {
  readonly type: BudgetCategoryType,
  readonly items: Array<T>,
  readonly relevantFromYear: number,
  readonly relevantToYear: number | null
}

export type BudgetItem<T extends BudgetLabel> = BudgetBase & {
  readonly labels: Array<T>
  readonly categoryId: number,
  readonly defaultAccountId: number,
  readonly transferAccountId: number | null,
  readonly isRolling: boolean,
  readonly relevantFromYear: number,
  readonly relevantToYear: number | null,
  readonly type: BudgetCategoryType,
}

export type BudgetLabel = BudgetBase & {
  readonly itemId: number,
  readonly relevantFromYear: number,
  readonly relevantToYear: number | null,
  readonly type: BudgetCategoryType,
}

export type BudgetCategoryApi = BudgetCategory<BudgetLabel, BudgetItem<BudgetLabel>>
export type BudgetItemApi = BudgetItem<BudgetLabel>
export type BudgetLabelApi = BudgetLabel
