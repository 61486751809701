import { BudgetItemBalance } from '../../../../api/domain'
import { ProgressGranularity } from '../../../../api/domain/budgetplan'
import { NoContent } from '../../../../components/display/NoContent'
import { CurrencyDisplay } from '../../../currency/CurrencyDisplay'
import { BudgetCategoryViewElement, BudgetItemViewElement, getBudgetClassName } from '../../BudgetDisplay'
import { BudgetItemModule } from '../item/BudgetItemModule'
import { BudgetCategoryHeader } from './BudgetCategoryHeader'
import './BudgetCategoryList.scss'

export interface BudgetCategoryListProps {
    category: BudgetCategoryViewElement;
    currentYear: number;
    selectedBudgetItemId: number | null;
    onBudgetItemChange: (budgetItem: BudgetItemViewElement | null) => void;
}

export type BudgetCategoryBalance = {
  readonly target: number,
  readonly actual: number,
  readonly targetActualComparison: number,
  readonly progress: {
    readonly actual: number,
    readonly target: number,
    readonly granularity: ProgressGranularity
  }
}

const createEmptyCategoryBalance = (): BudgetCategoryBalance | undefined => {
  return { target: 0, actual: 0, targetActualComparison: 0, progress: { actual: 0, target: 0, granularity: ProgressGranularity.NONE } }
}

const addBalances = (categoryBalance: BudgetCategoryBalance | undefined, itemBalance: BudgetItemBalance | undefined): BudgetCategoryBalance | undefined => {
  return categoryBalance === undefined || itemBalance === undefined
    ? undefined
    : {
        target: categoryBalance.target + itemBalance.target,
        actual: categoryBalance.actual + itemBalance.actual,
        targetActualComparison: categoryBalance.targetActualComparison + itemBalance.targetActualComparison,
        progress: {
          actual: 0,
          target: 0,
          granularity: ProgressGranularity.NONE
        }
      }
}

const calculateCategoryBalance = (items: Array<BudgetItemViewElement>): BudgetCategoryBalance | undefined => {
  const balance = items.reduce((res, item) => addBalances(res, item.balance), createEmptyCategoryBalance())
  return balance === undefined ? undefined : { ...balance, progress: { ...balance.progress, actual: balance.target === 0 ? 1 : balance.actual / balance.target } }
}

export const BudgetCategoryList = ({ category, currentYear, selectedBudgetItemId, onBudgetItemChange } : BudgetCategoryListProps) => {
  const items = category.items.filter(item => item.relevantFromYear <= currentYear && (item.relevantToYear === null || item.relevantToYear >= currentYear))
  const categoryBalance = calculateCategoryBalance(items)

  return <div className={`c-budget-category-list c-budget-category-list--${getBudgetClassName(category.type)}`}>
    <BudgetCategoryHeader category={category}>
      <CurrencyDisplay className='textNormal-75' highlightNegative={true} highlightPositive={true} value={categoryBalance ? categoryBalance.targetActualComparison : undefined}></CurrencyDisplay>
      <CurrencyDisplay className='c-budget-category-list__actual' highlightNegative={true} highlightPositive={true} value={categoryBalance ? categoryBalance.actual : undefined}></CurrencyDisplay>
    </BudgetCategoryHeader>
      <div className={'c-budget-category-list__content c-budget-category-list__content--expanded'}>
      {items.length === 0 && <NoContent className='c-budget-category-list__no_items'>You have no budget items to display here.</NoContent>}
      {items.map(item => {
        return <BudgetItemModule key={item.id} item={item} selected={item.id === selectedBudgetItemId} onClick={() => onBudgetItemChange(item.id === selectedBudgetItemId ? null : item)}></BudgetItemModule>
      })}
      </div>
    </div>
}
