import { Profile } from '../../domain'
import { delayedResult } from '../mock'

const ProfileServiceMock = {
  async get (id: number): Promise<Profile> {
    return delayedResult({ id, name: `profile${id}` })
  },
  async login (profileKey: string): Promise<Profile> {
    return delayedResult({ id: 1, name: `profile${profileKey}` })
  },
  async logout (): Promise<void> {}
}

export default ProfileServiceMock
