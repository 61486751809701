
import { Tab } from './Tab'
import './TabBar.scss'

export interface TabBarProps {
  className?: string,
  entries: Array<string>,
  activeTabId: string,
  onActiveChanged: (item: string) => void
}

export const TabBar = ({ className = '', entries, activeTabId, onActiveChanged } : TabBarProps) => {
  return <div className={`c-tab-bar ${className}`}>
    {entries.map(item => {
      return <Tab key={item} label={item} onClick={() => onActiveChanged(item)} isActive={activeTabId === item}></Tab>
    })}
  </div>
}
