import { Spinner } from '@benjaminpetry/bepe-design'

import './AccountList.scss'
import { Account, AccountBalances, AccountType } from '../../../api/domain'
import { AccountModule } from './AccountModule'
import { getAccountClassName, getAllAccountTypesSorted } from '../../../domain/account/AccountDisplay'
import { NoContent } from '../../../components/display/NoContent'

export interface AccountListProps {
    accounts: Array<Account>;
    accountBalances: AccountBalances;
    selectedAccountId: number | null;
    busy?: boolean;
    onAccountSelectionChanged: (account: Account | null) => void;
}

export const AccountList = ({ accounts, selectedAccountId, busy, accountBalances, onAccountSelectionChanged } : AccountListProps) => {
  const renderSublist = (type: AccountType) => {
    const accountsToRender = accounts.filter(a => a.type === type)
    return <div key={type} className={`c-account-list__sublist c-account-list__sublist--${getAccountClassName(type)}`}>
      {<h4 className='c-account-list__sublist-title'>{type}</h4>}
      <div className='c-account-list__sublist-content'>
      {accountsToRender.length === 0 && <NoContent className='c-account-list__no-content'>You have no accounts of this type yet.</NoContent>}
        {accountsToRender.map(account => {
          return <AccountModule key={account.id} account={account} balance={accountBalances.get(account.id)} selected={account.id === selectedAccountId} onClick={() => onAccountSelectionChanged(account.id === selectedAccountId ? null : account)}></AccountModule>
        })}
      </div>
    </div>
  }

  return (<div className="c-account-list">
      <div className="c-account-list__content">
        {busy && <Spinner className='c-account-list__loading'></Spinner>}
        {!busy && getAllAccountTypesSorted().map(type => renderSublist(type))}
      </div>
    </div>)
}
