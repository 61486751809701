
import { Account, AccountBalance, AccountBalances, AccountType } from '../../../api/domain'
import { ArrayUtil } from '../../budgetplan/ArrayUtil'
import { CurrencyDisplay } from '../../currency/CurrencyDisplay'
import './AccountSummary.scss'

export interface AccountSummaryProps {
  accounts: Array<Account>;
  accountBalances: AccountBalances;
  onClick: () => void
}

export const AccountSummary = ({ accounts, accountBalances, onClick } : AccountSummaryProps) => {
  const getAccountSumByType = (type: AccountType): number | undefined => {
    const relevantAccounts = accounts.filter(a => a.type === type)
    const balances = relevantAccounts.map(account => accountBalances.get(account.id)).filter(value => value !== undefined) as Array<AccountBalance>
    return balances.length === 0 && relevantAccounts.length !== 0 ? undefined : ArrayUtil.sum(balances.map(b => b.actual))
  }
  const cash = getAccountSumByType(AccountType.Cash)
  const bank = getAccountSumByType(AccountType.Bank)
  const liquidity = cash === undefined || bank === undefined ? undefined : cash + bank
  const credit = getAccountSumByType(AccountType.Credit)
  const savings = getAccountSumByType(AccountType.Savings)
  const assets = getAccountSumByType(AccountType.Assets)
  const total = liquidity === undefined || credit === undefined || savings === undefined || assets === undefined ? undefined : liquidity + credit + savings + assets

  return (<div className="c-account-summary interactive" onClick={onClick}>
      <div className="c-account-summary__liquidity"><p>Liquidity</p><CurrencyDisplay highlightNegative={true} highlightPositive={true} value={liquidity}></CurrencyDisplay></div>
      <div className="c-account-summary__credits"><p>Credits</p><CurrencyDisplay highlightNegative={true} highlightPositive={true} value={credit}></CurrencyDisplay></div>
      <div className="c-account-summary__savings"><p>Savings</p><CurrencyDisplay highlightNegative={true} highlightPositive={true} value={savings}></CurrencyDisplay></div>
      <div className="c-account-summary__assets"><p>Assets</p><CurrencyDisplay highlightNegative={true} highlightPositive={true} value={assets}></CurrencyDisplay></div>
      <div className="c-account-summary__total textBold-100"><p>Total</p><CurrencyDisplay highlightNegative={true} highlightPositive={true} value={total}></CurrencyDisplay></div>
    </div>)
}
