import { Transfer } from './transfer'

export enum ImportCollisionType {
    Update = 'update',
    UpdateInverted = 'update-inverted',
    Merge = 'merge',
    MergeInverted = 'merge-inverted',
    Replace = 'replace',
    Remove = 'remove'
}

export type ImportCollision = {
    types: Array<ImportCollisionType>
    transfer: Transfer
}

export type ImportTransfer = {
    transfer: Transfer,
    collisions: Array<ImportCollision>
}
