
import { Icons } from '@benjaminpetry/bepe-design'
import { Transfer } from '../../../api/domain'
import { ImportCollision, ImportCollisionType } from '../../../api/domain/import'
import { NoContent } from '../../../components/display/NoContent'
import { IconButton } from '../../../components/input/IconButton'
import { AccountService } from '../../../service/accountService'
import { BudgetService } from '../../../service/budgetService'
import { AccountModuleMini } from '../../account/components/AccountModuleMini'
import { BudgetItemModuleMini } from '../../budget/components/item/BudgetItemModuleMini'
import { CurrencyDisplay } from '../../currency/CurrencyDisplay'
import { getTransferTypeIcon } from '../../transfer/TransferDisplay'
import { TransferTypeIcon } from '../../transfer/TransferTypeIcon'
import './ImportCollisionRow.scss'

export interface ImportCollisionRowProps {
  collisionIndex: number,
  collision: ImportCollision,
  className?: string,
  busy: boolean,
  onAction: (collisionIndex: number, transfer: Transfer, action: ImportCollisionType | 'ignore') => void,
}

export const ImportCollisionRow = ({ className = '', collision, collisionIndex, busy, onAction }: ImportCollisionRowProps) => {
  const transfer = collision.transfer
  const transferAccount = transfer.transferAccountId === null ? undefined : AccountService.getAccountById(transfer.transferAccountId)
  const typeSet = new Set(collision.types)

  const renderActionButton = (type: ImportCollisionType | 'ignore', icon: string, title: string) => {
    return (type === 'ignore' || typeSet.has(type)) && <IconButton icon={icon} title={title} disabled={busy} onClick={() => onAction(collisionIndex, transfer, type)}></IconButton>
  }

  return (<div className={`c-import-collision-row c-import-table__row ${className}`}>
    <TransferTypeIcon className='c-import-table__icon' icon={getTransferTypeIcon(transfer.type)} type={transfer.type}></TransferTypeIcon>
      <div className='c-import-table__date'>{transfer.date.toLocaleDateString()}</div>
      <div className='c-import-table__description' title={transfer.description}>{transfer.description}</div>
     <div className='c-import-table__value'><CurrencyDisplay value={transfer.value}></CurrencyDisplay></div>
      <div className='c-import-table__transfer-account textNormal-75'>{transferAccount === undefined ? <NoContent>No transfer account</NoContent> : <AccountModuleMini account={transferAccount}></AccountModuleMini>}</div>
      <div className='c-import-table__budgetItems'>{transfer.budgetItems.map(entry => {
        const item = BudgetService.getBudgetItemById(entry.itemId)!
        const label = entry.labelId === null ? undefined : BudgetService.getBudgetLabelById(entry.labelId)
        return <BudgetItemModuleMini key={entry.id} item={item} label={label?.name}></BudgetItemModuleMini>
      })}</div>
      <div className='c-import-table__comment'>{transfer.comment}</div>
      <div className='c-import-table__actions'>
        {renderActionButton(ImportCollisionType.Merge, Icons.CodeFork, 'Merge')}
        {renderActionButton(ImportCollisionType.MergeInverted, Icons.CodeFork, 'Merge')}
        {renderActionButton(ImportCollisionType.Update, Icons.CodeFork, 'Update')}
        {renderActionButton(ImportCollisionType.UpdateInverted, Icons.CodeFork, 'Update')}
        {renderActionButton(ImportCollisionType.Replace, Icons.ArrowsTurnToDots, 'Replace')}
        {renderActionButton('ignore', Icons.EyeSlash, 'Ignore')}
      </div>
    </div>)
}
