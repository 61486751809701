import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { today as todayDate } from '../domain/transfer/TransferLogic'

const DATE_STATE_KEY = 'date-state-key'

export enum DateSelectionLevel {
  YEAR = 'year',
  MONTH = 'month'
}

export interface DateState {
    selectionLevel: DateSelectionLevel,
    month: number,
    year: number,
    isToday: boolean
}

const todayState: DateState = {
  selectionLevel: DateSelectionLevel.MONTH,
  month: todayDate().getMonth(),
  year: todayDate().getFullYear(),
  isToday: true
}

const parsedState: DateState = JSON.parse(localStorage.getItem(DATE_STATE_KEY) || 'null') || todayState
const initialState: DateState = parsedState.isToday ? { ...todayState, selectionLevel: parsedState.selectionLevel } : parsedState

const updateLocalStore = (state: DateState): DateState => {
  localStorage.setItem(DATE_STATE_KEY, JSON.stringify(state))
  return state
}

export const dateSlice = createSlice({
  name: 'dateSelection',
  initialState,
  reducers: {
    nextYear: (state) => {
      return updateLocalStore({ ...state, year: state.year + 1, isToday: false })
    },
    previousYear: (state) => {
      return updateLocalStore({ ...state, year: state.year - 1, isToday: false })
    },
    nextMonth: (state) => {
      const month = (state.month + 1) % 12
      const year = state.year + (month === 0 ? 1 : 0)
      return updateLocalStore({ ...state, year, month, isToday: false })
    },
    previousMonth: (state) => {
      const month = (state.month + 11) % 12
      const year = state.year - (month === 11 ? 1 : 0)
      return updateLocalStore({ ...state, year, month, isToday: false })
    },
    today: (state) => {
      return updateLocalStore({
        ...state,
        month: todayDate().getMonth(),
        year: todayDate().getFullYear(),
        isToday: true
      })
    },
    setSelectionLevel: (state, action: PayloadAction<DateSelectionLevel>) => {
      return updateLocalStore({ ...state, selectionLevel: action.payload })
    }
  }
})

export const { nextYear, previousMonth, nextMonth, previousYear, today, setSelectionLevel } = dateSlice.actions

export default dateSlice.reducer
