import { Icon, Icons } from '@benjaminpetry/bepe-design'
import { ReactNode } from 'react'
import { SelectionItem } from './SelectionItem'
import './SelectionList.scss'
import { SelectionListEntry } from './SelectionListEntry'

export interface SelectionListProps<T> {
    maxEntries?: number,
    items: Array<T>,
    className?: string,
    addButtonLabel: string,
    disableAddButton?: boolean,
    disabled?: boolean,
    renderItem: (item: T, index: number) => ReactNode | Array<ReactNode>
    onAdd: () => void,
    onDelete: (item: T, index: number) => void
}

export const SelectionList = <T extends {}>({ className = '', maxEntries, items, disabled = false, disableAddButton = false, addButtonLabel, renderItem, onAdd, onDelete }: SelectionListProps<T>) => {
  return <div className={`c-selection-list ${className}`}>
        {items.map((item, index) => <SelectionListEntry
            onDelete={() => onDelete(item, index)}
            disabled={disabled}
            key={index}>
                {renderItem(item, index)}
            </SelectionListEntry>)}
        {(maxEntries === undefined || items.length < maxEntries) &&
            <SelectionItem borderStyle='dashed' disabled={disableAddButton || disabled} className="c-selection-list__add-button textNormal-75" onClick={() => onAdd()}>
                <Icon icon={Icons.Plus}></Icon>
                {addButtonLabel}
            </SelectionItem>}
    </div>
}
