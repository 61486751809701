import { HTMLProps } from 'react'
import { Account } from '../../../api/domain'
import { AccountBalance } from '../../../api/domain/account'
import { getAccountIcon } from '../../../domain/account/AccountDisplay'
import './AccountModule.scss'
import { AccountTypeIcon } from '../../../domain/account/AccountTypeIcon'
import { CurrencyDisplay } from '../../currency/CurrencyDisplay'
import { ColorScheme, ProgressDisplay } from '../../../components/display/ProgressDisplay'

export interface AccountModuleProps extends HTMLProps<HTMLDivElement> {
  account: Account,
  balance: AccountBalance | undefined,
  className?: string,
  selected: boolean
}

export const AccountModule = ({ account, balance, selected, className = '', ...props }: AccountModuleProps) => {
  return (<div {...props} className={`c-account-tile interactive c-account-tile--${selected ? 'selected' : 'unselected'} ${className ?? ''}`}>
    <AccountTypeIcon icon={getAccountIcon(account.type)} type={account.type}></AccountTypeIcon>
    <div className='c-account-tile__content'>
      <div>
        <h5 className='c-account-tile__name'>{account.name}</h5>
        <div className='c-account-tile__balance'>
          <CurrencyDisplay highlightNegative={true} highlightPositive={true} value={balance?.actual}></CurrencyDisplay>
        </div>
      </div>
      <div>
        <ProgressDisplay colorScheme={ColorScheme.SAVINGS} progress={balance?.progress.actual} expectedProgress={balance?.progress.target}></ProgressDisplay>
        <CurrencyDisplay className="textNormal-75" highlightNegative={true} highlightPositive={true} value={balance?.target}></CurrencyDisplay>
      </div>
    </div>
  </div>)
}
