import { configureStore } from '@reduxjs/toolkit'
import profileReducer from './profileStore'
import accountReducer from './accountStore'
import dateReducer from './dateStore'
import budgetReducer from './budgetStore'
import notificationReducer from './notificationStore'
import { SecretRuducer } from './secretStore'

export const store = configureStore({
  reducer: {
    activeProfile: profileReducer,
    activeAccounts: accountReducer,
    dateSelection: dateReducer,
    budget: budgetReducer,
    notifications: notificationReducer,
    secret: SecretRuducer
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
