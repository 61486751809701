import { copyToClipboard, Icons } from '@benjaminpetry/bepe-design'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { BudgetItemApi, BudgetLabelApi } from '../api/domain'
import api from '../api/services/instance'
import { getFromDate, getToDate } from '../components/display/DateDisplay'
import { ContextMenu } from '../components/navigation/contextmenu/ContextMenu'
import { ContextMenuItem } from '../components/navigation/contextmenu/ContextMenuItem'
import { BudgetCategoryViewElement, BudgetItemViewElement, mergeBalanceAndCategory } from '../domain/budget/BudgetDisplay'
import { BudgetList } from '../domain/budget/components/BudgetList'
import { useBudgetBalance } from '../domain/budget/hooks/useBudgetBalance'
import { useFilteredBudgetElements } from '../domain/budget/hooks/useFilteredBudgetElements'
import { useIsMounted } from '../hooks/useIsMounted'
import { appRoutes, Route } from '../router'
import { BudgetService } from '../service/budgetService'
import { NotificationService } from '../service/notificationService'
import { RootState } from '../store'
import { DateSelectionLevel } from '../store/dateStore'
import { BudgetItemDetailsView } from './Budget/BudgetItemDetailsView'
import { MainView } from './components/MainView'

function BudgetView () {
  const { budgetItemId } = useParams()
  const navigate = useNavigate()
  // const profileStore = useSelector((state: RootState) => state.activeProfile)
  const budgetStore = useSelector((state: RootState) => state.budget)
  const dateSelection = useSelector((state: RootState) => state.dateSelection)
  const [budgetItemToEdit, setBudgetItemToEdit] = useState<BudgetItemViewElement | undefined>(undefined)
  const [items, setItems] = useState<Array<BudgetItemViewElement>>([])
  const [categories, setCategories] = useState<Array<BudgetCategoryViewElement>>([])
  const [filteredCategories, filteredItems, filteredLabels] = useFilteredBudgetElements()
  const [itemBalances, labelBalances, balancesLoading, balanceLoad] = useBudgetBalance<BudgetLabelApi, BudgetItemApi>(filteredItems, filteredLabels)
  const isMounted = useIsMounted()
  if (budgetItemToEdit) {
    console.log('todo: Budget Item Edit')
  }

  useEffect(() => {
    if (isMounted.current) {
      const result = mergeBalanceAndCategory(filteredCategories, balancesLoading ? new Map() : itemBalances, balancesLoading ? new Map() : labelBalances)
      setItems(result.items)
      setCategories(result.categories)
    }
  }, [filteredCategories, itemBalances, labelBalances, balancesLoading])

  const onCreateNewBudgetCategory = () => alert('onCreateNewBudgetCategory not implemented')
  const onCreateNewBudgetItem = () => alert('onCreateNewBudgetItem not implemented')
  const onCreateNewBudgetLabel = () => alert('onCreateNewBudgetLabel not implemented')

  const onExport = async () => {
    const itemIds = items.map(item => item.id)

    const load = async (month: number) => {
      const dateSel = { month, year: dateSelection.year, selectionLevel: DateSelectionLevel.MONTH, isToday: false }
      try {
        const result = itemIds.length === 0 ? { items: [], labels: [] } : await api.budgetService.getBalances(itemIds, [], getFromDate(dateSel), getToDate(dateSel), dateSel.selectionLevel)
        return new Map(result.items.map(res => [res.itemId, res.balance]))
      } catch (e) {}
    }
    const balances = await Promise.all([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(load))
    const data = items.map(item => {
      const base = [
        item.id,
        item.categoryId,
        item.name,
        item.type,
        item.defaultAccountId,
        item.transferAccountId || '',
        item.isRolling
      ]
      const balance = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => (balances[month]?.get(item.id)!.actual!) / 100)
      return [...base, ...balance]
    })
    copyToClipboard(data.map(itemData => itemData.join('\t')).join('\n'))
    NotificationService.raiseSuccess('all', 'Data copied to clipboard')
  }

  const renderContextMenu = () => {
    return <ContextMenu>
      <ContextMenuItem icon={Icons.Plus} onClick={onCreateNewBudgetCategory}>Add New Category</ContextMenuItem>
      <ContextMenuItem icon={Icons.Plus} onClick={onCreateNewBudgetItem}>Add New Budget Item</ContextMenuItem>
      <ContextMenuItem icon={Icons.Plus} onClick={onCreateNewBudgetLabel}>Add New Budget Label</ContextMenuItem>
      <ContextMenuItem className='desktop-only' icon={Icons.FileExport} onClick={onExport}>Export Data</ContextMenuItem>
      <ContextMenuItem icon={Icons.ArrowsRotate} onClick={() => BudgetService.load()}>Reload Data</ContextMenuItem>
    </ContextMenu>
  }

  const onSelectionChanged = (budgetItem: BudgetItemViewElement | null): Promise<void> => {
    if (budgetItem === null) {
      navigate(appRoutes[Route.Budget]())
    } else {
      navigate(appRoutes[Route.BudgetItemDetail](budgetItem.id))
    }
    return Promise.resolve()
  }

  const selectedBudgetItemId = Number.parseInt(budgetItemId || '0')
  const selectedBudgetItem = budgetItemId === undefined ? undefined : items.find(a => a.id === selectedBudgetItemId)

  return (<MainView
    className={`c-budget c-budget--${selectedBudgetItem ? 'details-open' : 'details-closed'}`}
    title="Budget"
    contextMenu={renderContextMenu()}
    detailViews={[<BudgetItemDetailsView onLoadBalances={balanceLoad} key='budget-item-details' originTitle="Budget" className="c-budget-item__details" item={selectedBudgetItem} onClose={() => onSelectionChanged(null)} onEdit={() => setBudgetItemToEdit(selectedBudgetItem)}></BudgetItemDetailsView>]}
    modals={[]}
    >
      <BudgetList
      budgetCategories={categories}
      currentYear={dateSelection.year}
      onBudgetItemChange={onSelectionChanged}
      selectedBudgetItemId={selectedBudgetItemId}
      busy={budgetStore.isLoading}
        ></BudgetList>
  </MainView>)
}

export default BudgetView
