import { HTMLProps } from 'react'
import { Account } from '../../../api/domain'
import { getAccountIcon } from '../../../domain/account/AccountDisplay'
import { AccountTypeIcon } from '../AccountTypeIcon'
import './AccountModuleMini.scss'

export interface AccountModuleMiniProps extends HTMLProps<HTMLDivElement> {
  account: Account,
  className?: string
}

export const AccountModuleMini = ({ account, className = '', ...props }: AccountModuleMiniProps) => {
  return (<div {...props} className={`textNormal-75 c-account-tile-mini  ${className}`}>
    <AccountTypeIcon className='c-account-tile-mini__icon' icon={getAccountIcon(account.type)} iconSize='small' type={account.type}></AccountTypeIcon>
    <div className='c-account-tile-mini__name'>{account.name}</div>
  </div>)
}
