import { FormElement, Signal, Textfield } from '@benjaminpetry/bepe-design'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Account, Transfer, TransferBudgetEntry } from '../../api/domain'
import { compareAccounts } from '../../domain/account/AccountLogic'
import { AccountSelection } from '../../domain/account/components/AccountSelection'
import { TransferAccountSelection } from '../../domain/account/components/TransferAccountSelection'
import { BudgetSelectionList } from '../../domain/budget/components/BudgetSelectionList'
import { CurrencyTextfield } from '../../domain/currency/CurrencyTextfield'
import { useTransferSave } from '../../domain/transfer/hooks/useTransferSave'
import { getTransferTypeIcon } from '../../domain/transfer/TransferDisplay'
import { createTransferError, formatDateForInput, getTransferType, today, TransferError, updateFirstBudgetItemsValue, updateTransferError, validateTransfer } from '../../domain/transfer/TransferLogic'
import { TransferTypeIcon } from '../../domain/transfer/TransferTypeIcon'
import { Modal } from '../../layout/modal/Modal'
import { AccountService } from '../../service/accountService'
import { RootState } from '../../store'
import './TransferFormModal.scss'

export interface TransferFormModalProps {
  className?: string,
  transfer: Transfer,
  onAfterSaving?: () => Promise<void>,
  onAfterSaved?: () => void,
  onClose: () => void
}

export const TransferFormModal = ({ className, transfer, onClose, onAfterSaving, onAfterSaved }: TransferFormModalProps) => {
  const accountStore = useSelector((state: RootState) => state.activeAccounts)

  const [error, setError] = useState<TransferError>(createTransferError(transfer.budgetItems))
  const [currentTransfer, setCurrentTransfer] = useState<Transfer>(transfer)
  const selectableAccounts = [...accountStore.activeAccounts].sort(compareAccounts)
  const [busy, save] = useTransferSave({ onAfterSaving, onAfterSaved })

  useEffect(() => {
    setCurrentTransfer(transfer)
    setError(createTransferError(transfer.budgetItems))
  }, [transfer])

  const onSave = async () => {
    const newError = validateTransfer(currentTransfer)
    setError(newError.errors)
    if (!newError.valid) {
      return
    }

    await save(currentTransfer)
  }

  const onCancel = () => {
    if (!busy) {
      onClose()
    }
  }

  const updateError = (partialError: Partial<TransferError>) => {
    setError(e => updateTransferError(e, partialError))
  }

  const updateTransfer = (partialTransfer: Partial<Transfer>) => {
    setCurrentTransfer(t => ({ ...t, ...partialTransfer }))
  }

  const onDateChanged = (newDate: string) => {
    const date = newDate === '' ? today() : new Date(newDate)
    updateTransfer({ date })
  }

  const onValueChanged = (value: number) => {
    const budgetItems = updateFirstBudgetItemsValue(value, currentTransfer.budgetItems)
    updateTransfer({ value, budgetItems })
  }

  const onInvertValue = () => {
    updateTransfer({ value: -1 * currentTransfer.value, budgetItems: currentTransfer.budgetItems.map(item => ({ ...item, value: -1 * item.value })) })
  }

  const onDescriptionChanged = (description: string) => {
    updateTransfer({ description })
  }

  const onAccountSelect = (account: Account) => {
    if (account.id === currentTransfer.transferAccountId) {
      updateTransfer({ accountId: account.id, transferAccountId: currentTransfer.accountId, bankDetails: currentTransfer.transferBankDetails, transferBankDetails: currentTransfer.bankDetails })
    } else {
      updateTransfer({ accountId: account.id })
    }
    AccountService.setDefaultAccountId(account.id)
  }

  const onTransferAccountSelect = (account: Account | null) => {
    updateTransfer({ transferAccountId: account === null ? null : account.id, budgetItems: [] })
  }

  const onBudgetEntriesChanged = (list: Array<TransferBudgetEntry>) => {
    updateTransfer({ budgetItems: updateFirstBudgetItemsValue(currentTransfer.value, list) })
  }

  const onCommentChanged = (comment: string) => {
    updateTransfer({ comment })
  }

  const realType = getTransferType(currentTransfer)
  const isNewTransfer = currentTransfer.id <= 0

  return (<Modal className={`c-transfer-edit-view ${className ?? ''}`} busy={busy} title={isNewTransfer ? 'Add Transfer' : 'Edit Transfer'} confirmButtonTitle={isNewTransfer ? 'Add Transfer' : 'Save Transfer'} onCancel={onCancel} onConfirm={onSave}>
    <div className='c-transfer-edit-view__container'>
      <div className='c-transfer-edit-view__row-1'>
          <div className='c-transfer-edit-view__icon-date'>
              <TransferTypeIcon icon={getTransferTypeIcon(realType)} type={realType} onClick={onInvertValue}></TransferTypeIcon>
              <Textfield label='Date' enableEmptyButton={false} className='c-transfer-edit-view__date' type='date' value={formatDateForInput(currentTransfer.date)} onChange={evt => onDateChanged(evt.target.value)}></Textfield>
          </div>
          <CurrencyTextfield label='Value' className='c-transfer-edit-view__value' autofocus={currentTransfer.value === 0} initialValue={currentTransfer.value} onErrorChange={err => updateError({ value: err })} onChange={onValueChanged} error={error.value} ></CurrencyTextfield>
      </div>
      <Textfield label='Description' className='c-transfer-edit-view__description' type='string' value={currentTransfer.description} onChange={evt => onDescriptionChanged(evt.target.value)} hint={error.description} signal={error.description ? Signal.DANGER : undefined}></Textfield>
      <div className='c-transfer-edit-view__accounts'>
        <FormElement label='Account' className='c-transfer-edit-view__account'>
            {currentTransfer.accountId > 0 && <AccountSelection accounts={selectableAccounts} onSelect={account => onAccountSelect(account!)} selectedAccountId={currentTransfer.accountId}></AccountSelection>}
        </FormElement>
        <FormElement label='Transfer Account' className='c-transfer-edit-view__transfer-accounts'>
        <TransferAccountSelection
          excludedAccountId={currentTransfer.accountId}
          onSelection={onTransferAccountSelect}
          transferAccountId={currentTransfer.transferAccountId}
        ></TransferAccountSelection>
        </FormElement>
      </div>
      <FormElement label='Budget Items' className='c-transfer-edit-view__budgetItems' hint={error.budgetItems} signal={error.budgetItems ? Signal.DANGER : undefined}>
          <BudgetSelectionList currentErrors={error.budgetItemEntries} onErrorChange={errs => updateError({ budgetItemEntries: errs })} transferType={realType} transferDate={currentTransfer.date} currentValue={currentTransfer.value} transferItems={currentTransfer.budgetItems} onChange={onBudgetEntriesChanged}></BudgetSelectionList>
      </FormElement>
      <Textfield type='string' label='Comment' className='c-transfer-edit-view__comment' value={currentTransfer.comment} onChange={evt => onCommentChanged(evt.target.value)}></Textfield>
    </div>
  </Modal>)
}
