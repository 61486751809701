import { HTMLProps } from 'react'
import { BudgetCategoryApi } from '../../../api/domain'
import { BudgetPlan, BudgetPlanField } from '../../../api/domain/budgetplan'
import { formatMonth } from '../../../components/display/DateDisplay'
import { NoContent } from '../../../components/display/NoContent'
import { BudgetCategoryHeader } from '../../budget/components/category/BudgetCategoryHeader'
import { BudgetItemMonthlyYearBalances } from '../../budget/hooks/useBudgetMonthlyYearBalance'
import { useFilteredBudgetElements } from '../../budget/hooks/useFilteredBudgetElements'
import { CurrencyDisplay } from '../../currency/CurrencyDisplay'
import { ArrayUtil } from '../ArrayUtil'
import './BudgetPlanCategoryList.scss'
import { BudgetPlanModule } from './BudgetPlanModule'

export interface BudgetPlanCategoryListProps extends HTMLProps<HTMLDivElement> {
  category: BudgetCategoryApi,
  budgetPlans: Map<number, BudgetPlan>,
  className?: string,
  actualBalances: BudgetItemMonthlyYearBalances,
  showActual: boolean,
  editMode: boolean,
  filterAccountId: number | undefined,
  isSaving: (id: number, field: BudgetPlanField | null, month: number | null) => boolean,
  onSavePlan: (plan: BudgetPlan, field: BudgetPlanField | null, month: number | null) => Promise<boolean>
}

export const BudgetPlanCategoryList = ({ category, budgetPlans, actualBalances, showActual, editMode, filterAccountId, isSaving, onSavePlan, className = '', ...props }: BudgetPlanCategoryListProps) => {
  const [, allItems] = useFilteredBudgetElements()
  const items = allItems.filter(item => item.categoryId === category.id && (filterAccountId === undefined || filterAccountId === item.defaultAccountId || filterAccountId === item.transferAccountId))

  const plans = items.map(item => budgetPlans.get(item.id)).filter(plan => plan !== undefined) as Array<BudgetPlan>
  const categorySums = plans.length === 0 ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : ArrayUtil.sumOfArrays(plans.map(plan => plan.planned))
  const categorySum = ArrayUtil.sum(categorySums)

  return (<div {...props} className={`c-budget-plan-category-list ${className}`}>
    <BudgetCategoryHeader className="c-budget-plan-category-list__header" category={category}>
      <div className='c-budget-plan-column'>Start<br/><p className='textNormal-75'>-</p></div>
      {[...Array(12).keys()].map((_, i) => <div key={i} className='c-budget-plan-column'>{formatMonth(i, true)}<br/><CurrencyDisplay className='textNormal-75' highlightNegative={true} highlightPositive={true} value={categorySums[i]}></CurrencyDisplay></div>)}
      <div className='c-budget-plan-column textBold-100'>Sum<br/><CurrencyDisplay className='textNormal-75' highlightNegative={true} highlightPositive={true} value={categorySum}></CurrencyDisplay></div>
      </BudgetCategoryHeader>
    {items.length === 0 && <NoContent className='c-budgeting-category-list__no_items'>You have no budget items to display here.</NoContent>}
    {items.map(item => {
      const plan = budgetPlans.get(item.id)
      const balances = actualBalances.get(item.id)
      return plan && <BudgetPlanModule key={item.id} category={category} item={item} plan={plan} balances={balances} showActual={showActual} editMode={editMode} onSavePlan={onSavePlan} isSaving={isSaving}></BudgetPlanModule>
    })}
  </div>)
}
