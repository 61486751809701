import { FormElement } from '@benjaminpetry/bepe-design'
import { useState } from 'react'
import { Account, AccountBalance, Transfer } from '../../../api/domain'
import { TransferSearchResult } from '../../../api/services/transfer/interface'
import { ColorScheme, ProgressDisplay } from '../../../components/display/ProgressDisplay'
import { TabBar } from '../../../components/navigation/TabBar'
import { BudgetService } from '../../../service/budgetService'
import { TransferListViewModule } from '../../../views/Transfers/TransferListViewModule'
import { BudgetItemModuleMini } from '../../budget/components/item/BudgetItemModuleMini'
import { CurrencyDisplay } from '../../currency/CurrencyDisplay'
import { SearchOptions } from '../../transfer/hooks/useTransferSearch'
import { getAccountIcon } from '../AccountDisplay'
import { AccountTypeIcon } from '../AccountTypeIcon'
import './AccountDetails.scss'

export interface AccountDetailsProps {
  className?: string,
  account: Account,
  balance?: AccountBalance,
  selectedTransferId: number | null,
  onTransferSelectionChanged: (transfer: Transfer | null) => void,
  busy: boolean,
  searchResult: TransferSearchResult;
  searchOptions: SearchOptions;
  onUpdateSearchOptions: (options: SearchOptions) => void;
}

enum DisplayState {
  TRANSFERS = 'Transfers',
  OPEN_ITEMS = 'Open Details',
  RESERVE_FUNDS = 'Reserve'
}

const compareBudgetItemValuePairs = (a: {itemId: number, value: number}, b: {itemId: number, value: number}) => {
  if (a.value > 0 && b.value <= 0) {
    return -1
  } if (b.value > 0 && a.value <= 0) {
    return 1
  }
  return Math.abs(b.value) - Math.abs(a.value)
}

export const AccountDetails = ({ className, account, balance, selectedTransferId, onTransferSelectionChanged, busy, searchResult, searchOptions, onUpdateSearchOptions }: AccountDetailsProps) => {
  const [displayState, setDisplayState] = useState<DisplayState>(DisplayState.TRANSFERS)

  const renderBudgetItemPairs = (items: Array<{itemId: number, value: number}>) => {
    items.sort(compareBudgetItemValuePairs)
    return items.map(({ itemId, value }) => {
      const item = BudgetService.getBudgetItemById(itemId)
      return item && <BudgetItemModuleMini key={itemId} item={item} value={value} ></BudgetItemModuleMini>
    })
  }

  const difference = balance ? balance.forecast - balance.target : undefined

  return (<div className={`c-account-details ${className ?? ''}`}>
    <div className='c-account-details__header'>
      <AccountTypeIcon className='c-account-details__icon' icon={getAccountIcon(account.type)} type={account.type}></AccountTypeIcon>
      <div className='c-account-details__progress-actual'>
        <FormElement className='c-account-details__progress-element' label="Progress">
          <ProgressDisplay colorScheme={ColorScheme.SAVINGS} progress={balance?.progress.actual} expectedProgress={balance?.progress.target}></ProgressDisplay>
        </FormElement>
        <FormElement label="Actual">
          <CurrencyDisplay highlightNegative={true} highlightPositive={true} value={balance?.actual}></CurrencyDisplay>
        </FormElement>
      </div>
    </div>
    <div className='c-account-details__target-forecast'>
      <FormElement label="Target" className='c-account-details__target'>
        <CurrencyDisplay highlightNegative={true} highlightPositive={true} value={balance?.target}></CurrencyDisplay>
      </FormElement>
      <FormElement label="Target/Forecast" className='c-account-details__difference'>
        <CurrencyDisplay highlightNegative={true} highlightPositive={true} value={difference}></CurrencyDisplay>
      </FormElement>
      <FormElement label="Forecast">
        <CurrencyDisplay highlightNegative={true} highlightPositive={true} value={balance?.forecast}></CurrencyDisplay>
      </FormElement>
    </div>
    <TabBar activeTabId={displayState} entries={[DisplayState.TRANSFERS, DisplayState.OPEN_ITEMS, DisplayState.RESERVE_FUNDS]} onActiveChanged={item => setDisplayState(item as DisplayState)}></TabBar>
      {displayState === DisplayState.TRANSFERS && <TransferListViewModule
      onSelectionChanged={onTransferSelectionChanged}
      onUpdateSearchOptions={onUpdateSearchOptions}
      searchOptions={searchOptions}
      busy={busy}
      searchResult={searchResult}
      selectedTransferId={selectedTransferId}
      ></TransferListViewModule>}
      {displayState === DisplayState.OPEN_ITEMS && <div className="c-account-details__open-items">
        <div className="c-account-details__open-items-scroll">
          <FormElement label="Total Open" className='c-account-details__open-transactions'>
            <CurrencyDisplay highlightNegative={true} highlightPositive={true} value={balance?.openTransactions}></CurrencyDisplay>
          </FormElement>
          {balance !== undefined && renderBudgetItemPairs(balance.openItems)}
        </div>
      </div>}
      {displayState === DisplayState.RESERVE_FUNDS && <div className="c-account-details__reserve-funds">
        <div className="c-account-details__reserve-funds-scroll">
          {balance !== undefined && renderBudgetItemPairs(balance.reserveFundItems)}
        </div>
      </div>}
  </div>)
}
