import { ButtonHTMLAttributes, MouseEventHandler } from 'react'
import { Icon, IconStyle } from '@benjaminpetry/bepe-design'
import './IconButton.scss'

export interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string,
  icon: string,
  iconStyle?: IconStyle,
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const IconButton = ({ className, icon, iconStyle = IconStyle.Solid, onClick, children, ...props } : IconButtonProps) => {
  return <button {...props} className={`${className ?? ''} c-icon-button`} onClick={onClick}>
            <Icon icon={icon} iconStyle={iconStyle}></Icon>{children && <div className='c-icon-button__children'>{children}</div>}
        </button>
}
