import { useEffect, useState } from 'react'
import './App.scss'
import { useLocation, useNavigate, useRoutes } from 'react-router-dom'
import routes, { appRoutes, Route } from './router'
import { RootState } from './store'
import { Spinner } from '@benjaminpetry/bepe-design'
import { useSelector } from 'react-redux'
import { ProfileService } from './service/profileService'
import { NotificationBar } from './layout/notifications/NotificationBar'

function App () {
  const [isBusy, setBusy] = useState<boolean>(false)
  const routesElement = useRoutes(routes)
  const navigate = useNavigate()
  const profileStore = useSelector((state: RootState) => state.activeProfile)
  const location = useLocation()

  useEffect(() => {
    const login = async (profileKey: string) => {
      setBusy(true)
      try {
        await ProfileService.login(profileKey)
        if (location.pathname === appRoutes[Route.Login]()) {
          navigate(appRoutes[Route.Dashboard]())
        }
      } catch (e) {
        navigate(appRoutes[Route.Login]())
      } finally {
        setBusy(false)
      }
    }

    if (profileStore.activeKey !== '') {
      login(profileStore.activeKey)
    } else {
      navigate(appRoutes[Route.Login]())
    }
  }, [])

  return (
    <div className={`c-app c-app--${isBusy ? 'loading' : 'loaded'} bepe-theme--dark`}>
      {isBusy && <div className="c-app__loader"><Spinner></Spinner>Loading your data...</div>}
      {!isBusy && routesElement}
      <NotificationBar></NotificationBar>
    </div>
  )
}

export default App
