import { Icon } from '@benjaminpetry/bepe-design'
import { ButtonHTMLAttributes } from 'react'
import './MenuItem.scss'

export interface MenuItemProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    isSelected: boolean,
    icon: string
}

export const MenuItem = ({ isSelected, icon, className, onClick, children, ...props }: MenuItemProps) => {
  return <button {...props} className={`${className || ''} c-menu-item c-menu-item--${isSelected ? 'selected' : 'unselected'}`} onClick={onClick}>
    <div className={'c-menu-item__container'}>
      <Icon className='c-menu-item__icon' icon={icon}></Icon>
      <div className='c-menu-item__label'>{children}</div>
    </div>
  </button>
}
