import { Backdrop, Button, Icons } from '@benjaminpetry/bepe-design'
import { HTMLProps } from 'react'
import { IconButton } from '../../components/input/IconButton'
import './Modal.scss'

export interface ModalProps extends HTMLProps<HTMLDivElement> {
    title: string,
    confirmButtonTitle?: string,
    confirmButtonDisabled?: boolean,
    showInPlace?: boolean,
    busy: boolean,
    onCancel?: () => void,
    onConfirm: () => void,
    className?: string
}

export const Modal = ({ title, children, confirmButtonTitle, confirmButtonDisabled = false, showInPlace, busy, onCancel, onConfirm, className, ...rest } : ModalProps) => {
  return (
    <Backdrop className={`center c-modal__backdrop c-modal__backdrop--${showInPlace ? 'in-place' : 'overlay'} ${className ?? ''}`} onClose={onCancel}>
        <div {...rest} className={`c-modal c-modal--${showInPlace ? 'in-place' : 'overlay'}`} onClick={(evt) => { evt.stopPropagation() }}>
          <div className="c-modal__title">
            <h2>{title}</h2>
            {onCancel && <IconButton className="c-modal__close-button" icon={Icons.Xmark} onClick={onCancel} disabled={busy}></IconButton>}
          </div>
          <div className="c-modal__main">{children}</div>
          <div className="c-modal__bar">
            {onCancel && <Button classtype='secondary' onClick={onCancel} disabled={busy}>Cancel</Button>}
            {confirmButtonTitle && <Button classtype='primary' onClick={onConfirm} busy={busy} disabled={confirmButtonDisabled}>{confirmButtonTitle}</Button>}
          </div>
        </div>
    </Backdrop>
  )
}
