import { Transfer, TransferBudgetEntry, TransferType } from '../../api/domain'

export const getTransfersByMonth = (transfers: Array<Transfer>): Array<{month: number, transfers: Array<Transfer>}> => {
  return [11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map(month => ({ month, transfers: transfers.filter(transfer => transfer.date.getMonth() === month) })).filter(monthList => monthList.transfers.length > 0)
}

export const getTransferType = (transfer: Transfer) => {
  return transfer.transferAccountId ? TransferType.Transfer : (transfer.value < 0 ? TransferType.Expense : TransferType.Income)
}

export interface TransferError {
    value: string | undefined,
    budgetItems: string | undefined,
    budgetItemEntries: Array<string | undefined>,
    description: string | undefined
}

export const createTransferError = (budgetItems: Array<TransferBudgetEntry>) => {
  return {
    value: undefined,
    budgetItems: undefined,
    budgetItemEntries: budgetItems.map(() => undefined),
    description: undefined
  }
}

export const hasError = (errors: TransferError): boolean => {
  return !!errors.value || !!errors.description || !!errors.budgetItems || errors.budgetItemEntries.some(e => !!e)
}

export const updateTransferError = (errors: TransferError, update: Partial<TransferError>) => {
  return { ...errors, ...update }
}

export const validateTransfer = (transfer: Transfer): {valid: boolean, errors: TransferError} => {
  let errors: TransferError = createTransferError(transfer.budgetItems)
  if (transfer.description === '') {
    errors = updateTransferError(errors, { description: 'Please enter a description.' })
  }
  const transferType = getTransferType(transfer)
  if (transferType !== TransferType.Transfer) {
    const sum = transfer.budgetItems.reduce((sum, v) => sum + v.value, 0)
    if (sum !== transfer.value) {
      errors = updateTransferError(errors, { budgetItems: 'The transfer amount must equals the amount of all budget items.' })
    }
  }
  return { valid: !hasError(errors), errors }
}

export const today = () => {
  const date = new Date()
  const dateStr = date.toISOString().substring(0, 10)
  return new Date(dateStr)
}

export const createNewTransfer = (profileId: number, accountId: number | undefined, budgetItemId: number | undefined): Transfer => {
  return {
    id: -1,
    profileId,
    type: TransferType.Income,
    date: today(),
    description: '',
    value: 0,
    accountId: accountId ?? -1,
    transferAccountId: null,
    comment: '',
    inverted: false,
    dateHash: '',
    bankHash: null,
    transferBankHash: null,
    bankDetails: null,
    transferBankDetails: null,
    budgetItems: budgetItemId
      ? [{
          id: -1,
          itemId: budgetItemId,
          labelId: -1,
          value: 0
        }]
      : []
  }
}

export const formatDateForInput = (date: Date) => {
  return date.toISOString().substring(0, 10)
}

export const updateFirstBudgetItemsValue = (newTransferValue: number, newBudgetEntries: Array<TransferBudgetEntry>) => {
  return newBudgetEntries.map((item, index, arr) => {
    if (index !== 0) {
      return item
    }
    const newItemValue = newTransferValue + item.value - arr.reduce((sum, item) => sum + item.value, 0)
    return { ...item, value: newItemValue }
  })
}

export const parseApiTransfer = (transfer: Transfer): Transfer => ({ ...transfer, date: new Date(transfer.date) })
