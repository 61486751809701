let authToken: string | null = null

const setAuthtoken = (token: string | null) => {
  authToken = token
}

const getAuthToken = () => {
  return authToken
}

export default {
  getAuthToken,
  setAuthtoken
}
