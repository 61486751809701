import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Profile } from '../api/domain'

const PROFILE_STATE_KEY = 'profile-state-key'

export interface LoginProfile {
  profile: Profile,
  profileKey: string
}

export interface ProfileState {
    isLoading: boolean,
    active: Profile | null,
    activeKey: string,
    lastProfiles: Array<LoginProfile>
}

const emptyState: ProfileState = {
  isLoading: true,
  active: null,
  activeKey: '',
  lastProfiles: []
}

const initialState: ProfileState = JSON.parse(localStorage.getItem(PROFILE_STATE_KEY) || 'null') || emptyState

export const profileSlice = createSlice({
  name: 'activeProfile',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, isLoading: action.payload }
    },
    setActiveProfile: (state, action: PayloadAction<LoginProfile>) => {
      const { profile, profileKey } = action.payload
      const lastProfiles = [action.payload, ...state.lastProfiles.filter(p => p.profile.id !== profile.id).slice(0, 5)]
      const newState = { ...state, active: profile, activeKey: profileKey, lastProfiles }
      localStorage.setItem(PROFILE_STATE_KEY, JSON.stringify(newState))
      return newState
    },
    clearActiveProfile: (state) => {
      const newState = { ...state, active: null, activeKey: '' }
      localStorage.setItem(PROFILE_STATE_KEY, JSON.stringify(newState))
      return newState
    }
  }
})

export const { setActiveProfile, clearActiveProfile, setLoading } = profileSlice.actions

export default profileSlice.reducer
