
import { BudgetLabel } from '../../../../api/domain'
import { NoContent } from '../../../../components/display/NoContent'
import { SelectionDropdown } from '../../../../components/input/selection/SelectionDropdown'
import { BudgetLabelModuleMini } from './BudgetLabelModuleMini'
import './BudgetLabelSelection.scss'

export interface BudgetLabelSelectionProps {
  className?: string,
  labels: Array<BudgetLabel>,
  selectedLabelId: number | null,
  autoOpen: boolean,
  allowDeselect?: boolean,
  onSelect: (label: BudgetLabel | null) => void
}

export const BudgetLabelSelection = ({ className, allowDeselect = false, autoOpen = false, labels, selectedLabelId, onSelect } : BudgetLabelSelectionProps) => {
  const onFilter = (label: BudgetLabel, query: string) => label.name.toLowerCase().indexOf(query) >= 0
  const renderDisplayItem = (label: BudgetLabel) => <BudgetLabelModuleMini budgetLabel={label}></BudgetLabelModuleMini>
  const renderListItem = renderDisplayItem
  const renderNoSelection = () => <NoContent className='textNormal-75'>No label selected</NoContent>
  return <SelectionDropdown
    className={`c-budget-label-selection ${className ?? ''}`}
    selectedId={selectedLabelId} list={labels}
    filterFun={onFilter}
    onSelect={onSelect}
    autoOpen={autoOpen}
    allowDeselect={allowDeselect}
    renderDisplayItem={renderDisplayItem}
    renderListItem={renderListItem}
    renderNoItemSelected={renderNoSelection}></SelectionDropdown>
}
