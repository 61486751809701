import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AccountSummary } from '../domain/account/components/AccountSummary'
import { useAccountBalance } from '../domain/account/hooks/useAccountBalance'
import { appRoutes, Route } from '../router'
import { RootState } from '../store'
import { MainView } from './components/MainView'

function DashboardView () {
  const navigate = useNavigate()
  const profileStore = useSelector((state: RootState) => state.activeProfile)
  const accountStore = useSelector((state: RootState) => state.activeAccounts)
  const [balances] = useAccountBalance(accountStore.activeAccounts)

  return (<MainView title="Dashboard">
    <h2 style={{ marginBottom: 'var(--margin-md)' }}>Welcome {profileStore.active?.name}!</h2>
    <AccountSummary accountBalances={balances} accounts={accountStore.activeAccounts} onClick={() => navigate(appRoutes[Route.Accounts]())}></AccountSummary>
  </MainView>)
}

export default DashboardView
