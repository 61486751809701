import { RadioGroup, Signal, Textfield } from '@benjaminpetry/bepe-design'
import { HTMLProps, useState } from 'react'
import { Account, AccountType } from '../../api/domain'
import { getAccountIcon } from '../../domain/account/AccountDisplay'
import { Modal } from '../../layout/modal/Modal'
import './AccountFormModal.scss'

export interface AccountFormModalProps extends HTMLProps<HTMLDivElement> {
    account: Account
    busy?: boolean,
    onCancel: () => void,
    onSaveAccount: (account: Account) => void
}

const accountTypes = Object.values(AccountType).map(type => ({
  id: type,
  label: type,
  icon: getAccountIcon(type),
  data: type
}))

export const AccountFormModal = ({ account, busy = false, onCancel, onSaveAccount }: AccountFormModalProps) => {
  const [name, setName] = useState<string>(account.name)
  const [accountType, setAccountType] = useState<AccountType | null>(account.type || null)
  const [nameError, setNameError] = useState<string>('')
  const [accountTypeError, setAccountTypeError] = useState<string>('')

  const submitAccountData = () => {
    setNameError('')
    setAccountTypeError('')
    if (name === '') {
      setNameError('Please enter a name for this account')
      return
    }
    if (!accountType) {
      setAccountTypeError('Pleace select the type of this account')
      return
    }
    const newAccountData = { ...account, name, type: accountType }
    onSaveAccount(newAccountData)
  }

  const onCancelClicked = () => {
    if (!busy) {
      onCancel()
    }
  }

  const isNewAccount = account.id === -1

  return (<Modal className="c-account-edit-view" busy={busy} title={isNewAccount ? 'Add Account' : 'Edit Account'} confirmButtonTitle={isNewAccount ? 'Create Account' : 'Save Account'} onCancel={onCancelClicked} onConfirm={submitAccountData}>
    <Textfield label="Account Name" disabled={busy} hint={nameError} signal={nameError ? Signal.DANGER : null} value={name} onChange={evt => setName(evt.target.value)}></Textfield>
    <RadioGroup id='account-type' disabled={busy} hint={accountTypeError} signal={accountTypeError ? Signal.DANGER : null} label="Account Type" selectedId={accountType} onSelectionChanged={(_, data) => { setAccountType(data as AccountType); setAccountTypeError('') }} data={accountTypes}></RadioGroup>
  </Modal>)
}
