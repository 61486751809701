import { Button, Icons } from '@benjaminpetry/bepe-design'
import { ReactNode } from 'react'
import { IconButton } from '../input/IconButton'
import './Picker.scss'

export interface PickerProps {
  className?: string,
  onNext: () => void,
  onPrevious: () => void,
  onButton?: () => void,
  children: ReactNode | Array<ReactNode>
}

export const Picker = ({ className, onPrevious, onNext, onButton, children } : PickerProps) => {
  return <div className={`c-picker ${className || ''}`}>
  <IconButton className='c-picker__previous' icon={Icons.AngleLeft} onClick={onPrevious}></IconButton>
  <Button className={'c-picker__button'} disabled={!onButton} onClick={onButton}>{children}</Button>
  <IconButton className='c-picker__next' icon={Icons.AngleRight} onClick={onNext}></IconButton>
</div>
}
