import { useState } from 'react'
import { MenuItem } from './MenuItem'
import './Menu.scss'
import { Icons } from '@benjaminpetry/bepe-design'
import { appRoutes, Route } from '../../router'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { MenuLink } from './MenuLink'
import { SubMenu } from './Submenu'
import { RootState } from '../../store'
import { ProfileService } from '../../service/profileService'
import { DateSelector } from '../../components/navigation/dateselector/DateSelector'
import { NotificationService } from '../../service/notificationService'
import { useSecretMode } from '../../hooks/useSecretMode'

export const Menu = () => {
  const navigate = useNavigate()
  const [showSubMenu, setShowSubMenu] = useState(false)
  const profileStore = useSelector((state: RootState) => state.activeProfile)
  const [secretMode, setSecretMode] = useSecretMode()

  const logout = async () => {
    const name = profileStore.active?.name ?? 'Unknown user'
    await ProfileService.logout()
    NotificationService.raiseSuccess('logout', `Goodbye ${name}!`, false)
    navigate(appRoutes[Route.Login]())
  }

  const closeSubMenu = () => {
    setShowSubMenu(false)
  }

  const toggleSubMenuVisibility = () => {
    setShowSubMenu((value) => !value)
  }

  return <nav className={`c-menu c-menu--${showSubMenu ? 'sub-menu-open' : 'sub-menu-closed'}`}>
      <h2 className='c-desktop-only'>MoneyExperience</h2>
      <DateSelector className="c-desktop-only"></DateSelector>
      <MenuLink icon={Icons.ChartColumn} url={appRoutes[Route.Dashboard]()} exactUrl={true} onClick={closeSubMenu}>Dashboard</MenuLink>
      <MenuLink icon={Icons.CreditCard} url={appRoutes[Route.Accounts]()} onClick={closeSubMenu}>Accounts</MenuLink>
      <div className="c-mobile-only c-menu__add-button"><MenuLink icon={Icons.Plus} url={appRoutes[Route.AddTransfer]()} onClick={closeSubMenu}>Add</MenuLink></div>
      <MenuLink icon={Icons.Coins} url={appRoutes[Route.Budget]()} onClick={closeSubMenu}>Budget</MenuLink>
      <MenuItem className='c-mobile-only' icon={Icons.Ellipsis} onClick={toggleSubMenuVisibility} isSelected={false}>More</MenuItem>
      <MenuLink className='c-desktop-only' icon={Icons.ArrowRightArrowLeft} url={appRoutes[Route.Transfers]()}>Transfers</MenuLink>
      <MenuLink className='c-desktop-only' icon={Icons.Calculator} url={appRoutes[Route.Budgeting]()}>Budgeting</MenuLink>
      <div className="c-desktop-only c-menu__spacer"></div>
      {profileStore.active && <p className="c-desktop-only c-menu__profile">Profile: {profileStore.active.name}</p>}
      <MenuItem className='c-desktop-only c-menu__logout' icon={Icons.RightFromBracket} onClick={logout} isSelected={false}>Logout</MenuItem>
      {showSubMenu && <SubMenu onClick={() => setShowSubMenu(false)}>
        <MenuLink icon={Icons.ArrowRightArrowLeft} url={appRoutes[Route.Transfers]()} onClick={closeSubMenu}>Transfers</MenuLink>
        <MenuItem isSelected={false} icon={secretMode ? Icons.Eye : Icons.EyeSlash} onClick={() => setSecretMode(!secretMode)}>{secretMode ? 'Turn Secret Mode off' : 'Turn Secret Mode on'}</MenuItem>
        <MenuItem isSelected={false} icon={Icons.ArrowRotateRight} onClick={() => window.location.reload()}>Reload App</MenuItem>
        <MenuItem className='' icon={Icons.RightFromBracket} onClick={logout} isSelected={false}>Logout</MenuItem>
        </SubMenu>}
    </nav>
}
