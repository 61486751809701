
import './BudgetSelectionListEntry.scss'
import { useSelector } from 'react-redux'
import { BudgetItemApi, BudgetLabel, TransferBudgetEntry } from '../../../api/domain'
import { RootState } from '../../../store'
import { BudgetItemSelection } from './item/BudgetItemSelection'
import { BudgetLabelSelection } from './label/BudgetLabelSelection'
import { CurrencyTextfield } from '../../currency/CurrencyTextfield'
import { useAutoOpen } from '../../../hooks/useAutoOpen'

export interface BudgetSelectionListEntryProps {
  className?: string,
  budgetEntry: TransferBudgetEntry,
  selectableItems: Array<BudgetItemApi>,
  currentYear: number,
  error: string | undefined,
  showValue: boolean,
  disableValue: boolean,
  autoOpen?: boolean,
  onChange: (entry: TransferBudgetEntry) => void,
  onErrorChange: (error: string | undefined) => void
}

const getValidLabels = (items: Array<BudgetItemApi>, itemId: number, dateYear: number): Array<BudgetLabel> => {
  const realItem = items.find(i => i.id === itemId)
  return (realItem?.labels ?? []).filter(l => dateYear >= l.relevantFromYear && (l.relevantToYear === null || dateYear <= l.relevantToYear))
}

export const BudgetSelectionListEntry = ({ autoOpen = false, className = '', budgetEntry, selectableItems, currentYear, error, showValue, disableValue, onChange, onErrorChange }: BudgetSelectionListEntryProps) => {
  const [isLabelDropdownOpen, openLabelsDropdown] = useAutoOpen()

  const budgetStore = useSelector((state: RootState) => state.budget)
  const labels = getValidLabels(budgetStore.items, budgetEntry.itemId, currentYear)

  const onBudgetItemChanged = (newItemId: number) => {
    onChange({ ...budgetEntry, itemId: newItemId, labelId: null })
    openLabelsDropdown()
  }

  const onBudgetLabelChanged = (newLabelId: number | null) => {
    onChange({ ...budgetEntry, labelId: newLabelId })
  }

  const onValueChanged = (newValue: number) => {
    onChange({ ...budgetEntry, value: newValue })
  }

  return (<div className={`c-budget-selection-list-entry ${className}`}>
            <div className='c-budget-selection-list-entry__selections'>
              <BudgetItemSelection autoOpen={autoOpen} items={selectableItems} selectedItemId={budgetEntry.itemId} onSelect={item => onBudgetItemChanged(item.id)}></BudgetItemSelection>
              {labels.length > 0 && <BudgetLabelSelection autoOpen={isLabelDropdownOpen} allowDeselect={true} labels={labels} selectedLabelId={budgetEntry.labelId} onSelect={label => onBudgetLabelChanged(label?.id ?? null)}></BudgetLabelSelection>}
            </div>
            {showValue && <CurrencyTextfield className='c-budget-selection-list-entry__value' disabled={disableValue} initialValue={budgetEntry.value} error={error} onErrorChange={onErrorChange} onChange={onValueChanged}></CurrencyTextfield>}
        </div>)
}
