import api from '../api'
import { BudgetCategory, BudgetCategoryApi, BudgetItem, BudgetItemApi, BudgetLabel } from '../api/domain'
import { store } from '../store'

import { clearData, setData, setLoading as setBudgetLoading } from '../store/budgetStore'

let categoryMap = new Map<number, BudgetCategory<BudgetLabel, BudgetItem<BudgetLabel>>>()
let itemMap = new Map<number, BudgetItem<BudgetLabel>>()
let labelMap = new Map<number, BudgetLabel>()

const load = async (): Promise<{categories: Array<BudgetCategoryApi>, items: Array<BudgetItemApi>}> => {
  store.dispatch(setBudgetLoading(true))
  try {
    const [categories, items, labels] = await api.budgetService.findAll()
    categoryMap = new Map<number, BudgetCategory<BudgetLabel, BudgetItem<BudgetLabel>>>(categories.map(c => [c.id, c]))
    itemMap = new Map<number, BudgetItem<BudgetLabel>>(items.map(i => [i.id, i]))
    labelMap = new Map<number, BudgetLabel>(labels.map(l => [l.id, l]))
    store.dispatch(setData({ categories, items, labels }))
    return { categories, items }
  } finally {
    store.dispatch(setBudgetLoading(false))
  }
}

const getBudgetItemById = (id: number) => {
  return itemMap.get(id)
}

const getBudgetCategoryById = (id: number) => {
  return categoryMap.get(id)
}

const getBudgetLabelById = (id: number) => {
  return labelMap.get(id)
}

// const create = async (name: string, type: AccountType): Promise<Account> => {
//   const account = await api.accountService.create(name, type)
//   await load()
//   return account
// }

// const update = async (account: Account): Promise<Account> => {
//   const updatedAccount = await api.accountService.update(account)
//   await load()
//   return updatedAccount
// }

const clear = async (): Promise<void> => {
  store.dispatch(clearData())
  categoryMap = new Map<number, BudgetCategory<BudgetLabel, BudgetItem<BudgetLabel>>>()
  itemMap = new Map<number, BudgetItem<BudgetLabel>>()
  labelMap = new Map<number, BudgetLabel>()
}

export const BudgetService = {
  load,
  getBudgetCategoryById,
  getBudgetItemById,
  getBudgetLabelById,
  // create,
  clear
  // update
}
