import { Icons } from '@benjaminpetry/bepe-design'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Account } from '../api/domain'
import { ContextMenu } from '../components/navigation/contextmenu/ContextMenu'
import { ContextMenuItem } from '../components/navigation/contextmenu/ContextMenuItem'
import { createNewAccount } from '../domain/account/AccountLogic'
import { AccountList } from '../domain/account/components/AccountList'
import { useAccountBalance } from '../domain/account/hooks/useAccountBalance'
import { appRoutes, Route } from '../router'
import { AccountService } from '../service/accountService'
import { NotificationService } from '../service/notificationService'
import { RootState } from '../store'
import { AccountDetailsView } from './Accounts/AccountDetailsView'
import { AccountFormModal } from './Accounts/AccountFormModal'
import { MainView } from './components/MainView'

const NOTIFICATION_SOURCE = 'account-view'

export const AccountsView = () => {
  const { accountId } = useParams()
  const navigate = useNavigate()
  const profileStore = useSelector((state: RootState) => state.activeProfile)
  const accountStore = useSelector((state: RootState) => state.activeAccounts)

  const [accountToEdit, setAccountToEdit] = useState<Account | undefined>(undefined)
  const [accountSaving, setAccountSaving] = useState<boolean>(false)
  const [balances, , load] = useAccountBalance(accountStore.activeAccounts)

  const saveAccount = async (account: Account) => {
    NotificationService.clear(NOTIFICATION_SOURCE)
    setAccountSaving(true)
    try {
      if (account.id === -1) {
        await AccountService.create(account.name, account.type)
        NotificationService.raiseSuccess(NOTIFICATION_SOURCE, 'Account has been created')
      } else {
        await AccountService.update(account)
        NotificationService.raiseSuccess(NOTIFICATION_SOURCE, 'Account has been saved')
      }
      setAccountToEdit(undefined)
    } catch (e) {
      NotificationService.raiseError(NOTIFICATION_SOURCE, 'Account could not been created. Check your internet connection.')
    } finally {
      setAccountSaving(false)
    }
  }

  const onCreateNewAccount = () => {
    setAccountToEdit(createNewAccount(profileStore.active!.id))
  }

  const onSelectionChanged = (account: Account | null): Promise<void> => {
    if (account === null) {
      navigate(appRoutes[Route.Accounts]())
    } else {
      navigate(appRoutes[Route.AccountDetail](account.id))
    }
    return Promise.resolve()
  }

  const renderContextMenu = () => {
    return <ContextMenu>
      <ContextMenuItem icon={Icons.Plus} onClick={onCreateNewAccount}>Add New Account</ContextMenuItem>
      <ContextMenuItem icon={Icons.ArrowsRotate} onClick={() => AccountService.load()}>Reload Data</ContextMenuItem>
    </ContextMenu>
  }

  const selectedAccountId = Number.parseInt(accountId || '0')
  const selectedAccount = accountId === undefined ? undefined : accountStore.activeAccounts.find(a => a.id === selectedAccountId)
  const accountBalance = accountId === undefined ? undefined : balances.get(selectedAccountId)

  return (<MainView
    className={'c-accounts'}
    title="Accounts"
    contextMenu={renderContextMenu()}
    detailViews={<AccountDetailsView onLoadBalances={load} originTitle="Accounts" className="c-accounts__details" account={selectedAccount} balance={accountBalance} onClose={() => onSelectionChanged(null)} onEdit={() => setAccountToEdit(selectedAccount)}></AccountDetailsView>}
    modals={accountToEdit && <AccountFormModal account={accountToEdit} busy={accountSaving} onCancel={() => setAccountToEdit(undefined)} onSaveAccount={saveAccount}></AccountFormModal>}
    >
      <AccountList
          accounts={accountStore.activeAccounts}
          accountBalances={balances}
          selectedAccountId={selectedAccountId}
          busy={accountSaving || accountStore.isLoading}
          onAccountSelectionChanged={onSelectionChanged}
        ></AccountList>
  </MainView>)
}
