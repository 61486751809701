import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { getFromDate, getToDate } from '../../../components/display/DateDisplay'
import { useIsMounted } from '../../../hooks/useIsMounted'
import api from '../../../api'
import { NotificationService } from '../../../service/notificationService'
import { Account, AccountBalances } from '../../../api/domain'
import { DateSelectionLevel } from '../../../store/dateStore'

const NOTIFICATION_SOURCE = 'account-balance-load'

type AccountBalanceReturn = [AccountBalances, boolean, () => Promise<void>]

export const useAccountBalance = (accounts: Array<Account>): AccountBalanceReturn => {
  const [busy, setBusy] = useState<boolean>(false)
  const [result, setResult] = useState<AccountBalances>(new Map())
  const dateSelection = useSelector((state: RootState) => state.dateSelection)
  const profileState = useSelector((state: RootState) => state.activeProfile)
  const isMounted = useIsMounted()
  const accountIds = accounts.map(account => account.id)

  const load = async () => {
    NotificationService.clear(NOTIFICATION_SOURCE)
    setBusy(true)
    try {
      const searchResult = accountIds.length === 0 ? [] : await api.accountService.getBalances(accountIds, getFromDate(dateSelection, DateSelectionLevel.YEAR), getToDate(dateSelection), dateSelection.selectionLevel === DateSelectionLevel.MONTH ? 'month' : 'year')
      const newMap = new Map(searchResult.map(res => [res.accountId, res.balance]))
      if (isMounted.current) {
        setResult(newMap)
      }
    } catch (e) {
      NotificationService.raiseError(NOTIFICATION_SOURCE, 'Loading Account balance failed. Please try again later.')
    } finally {
      if (isMounted.current) {
        setBusy(false)
      }
    }
  }

  useEffect(() => {
    if (profileState.active && !profileState.isLoading) {
      load()
    }
  }, [profileState.active, profileState.isLoading, dateSelection, accounts])

  return [result, busy, load]
}
