import { useState } from 'react'

export const useAutoOpen = (): [boolean, () => void, () => void] => {
  const [isOpen, setOpen] = useState<boolean>(false)

  const open = () => {
    setOpen(false)
    setTimeout(() => {
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 50)
    }, 50)
  }

  const close = () => setOpen(false)

  return [isOpen, open, close]
}
