import { useState } from 'react'
import api from '../../../api'
import { Transfer } from '../../../api/domain'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { NotificationService } from '../../../service/notificationService'

const NOTIFICATION_SOURCE = 'transfer-save'

interface useTransferSaveProps {
  onAfterSaving?: () => Promise<void>,
  onAfterSaved?: () => void,
  }

export const useTransferSave = ({ onAfterSaving, onAfterSaved }: useTransferSaveProps = {}): [boolean, (transfer: Transfer) => Promise<Transfer | undefined>] => {
  const [busy, setBusy] = useState<boolean>(false)
  const isMounted = useIsMounted()

  const save = async (transfer: Transfer): Promise<Transfer | undefined> => {
    NotificationService.clear(NOTIFICATION_SOURCE)
    setBusy(true)
    try {
      const updatedTransfer = (transfer.id > 0) ? await api.transferService.update(transfer) : await api.transferService.create(transfer)
      if (onAfterSaving) { await onAfterSaving() }
      NotificationService.raiseSuccess(NOTIFICATION_SOURCE, 'Transfer has been saved')
      if (onAfterSaved) { await onAfterSaved() }
      if (isMounted.current) {
        setBusy(false)
      }
      return updatedTransfer
    } catch (e) {
      if (isMounted.current) {
        setBusy(false)
      }
      NotificationService.raiseError(NOTIFICATION_SOURCE, 'Transfer could not been saved. Please check your internet connection.')
    }
  }

  return [busy, save]
}
